<h2 mat-dialog-title>Descrição</h2>
<div mat-dialog-content>
  <p>Como você deseja tirar sua foto?</p>
  <div class="container-actions">
    <div class="action" (click)="onSelectCameraType('boroscopio')">
      <mat-icon class="btn-action" color="primary">speaker_phone</mat-icon>
      <span> Boroscópio </span>
    </div>
    <div class="action" (click)="onSelectCameraType('camera')">
    <mat-icon class="btn-action "  color="primary">photo_camera</mat-icon>
    <span> Camera </span>
  </div>
  </div>
</div>

