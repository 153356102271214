import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vistoria-pwa';

  private initialPageUrl!: string;
  private canLeavePage: boolean;


  constructor(private location: Location) {
    this.canLeavePage = false;
    this.initialPageUrl = location.path();
  }

  ngOnInit() {
    this.initialPageUrl = this.location.path();
    this.canLeavePage = false;
    // console.log(this.initialPageUrl)
  }


  // @HostListener('window:popstate', ['$event'])
  // unloadNotification($event: any) {
  //   if (!this.canLeavePage) {
  //     const confirmationMessage = 'Deseja realmente sair do aplicativo? Você pode perder seus dados.';
  //     const confirmation = confirm(confirmationMessage);
  //     if (confirmation) {
  //       $event.preventDefault();
  //       console.log('confirm foi true');
  //     } else {
  //       console.log('confirm foi false');
  //       history.pushState(null, '', location.href); // adiciona uma nova entrada no histórico do navegador para manter o usuário na mesma rota
  //     }
  //   }
  //   return null;
  // }


  // @HostListener('window:popstate', ['$event'])
  // popStateNotification($event: any) {
  //   if (this.location.path() !== this.initialPageUrl) {
  //     const confirmExit = confirm('Deseja realmente sair do aplicativo? Você pode perder seus dados.');
  //     if (!confirmExit) {
  //       this.canLeavePage = true;
  //       window.history.pushState(null, '');
  //     }
  //   }
  // }
}

