
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogConfirmResponseProps {
  confirm: boolean;
};

@Component({
  selector: 'app-return-home',
  templateUrl: './return-home.component.html',
  styleUrls: ['./return-home.component.css']
})

export class ReturnHomeComponent implements OnInit {


  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReturnHomeComponent>,
    private router: Router) { }

  ngOnInit(): void {

  }

  returnHome() {
    const dialogRef = this.dialog.open(ReturnHomeComponent, {
      width: '80%',
      maxWidth: '400px'
      
     });

  }

  onNoClick(): void {
    this.dialogRef.close();

  }

  onOkClick(){
    this.dialogRef.close();
    this.router.navigate(['/home'])

  }

}
