import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Cor } from 'src/models/cor-model';
import { Modelo } from 'src/models/modelo-model';

@Injectable({
  providedIn: 'root'
})
export class ModeloService {
  private readonly RESOURCE = '/modelos';

  constructor(private http: HttpClient) { }

  modelosVeiculo(): Observable<Modelo[]> {
    return this.http.get<Modelo[]>(this.RESOURCE).pipe(take(1));
  }

}
