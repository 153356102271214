<div className="input-file-container">
  <input #inputFileRef type="file" capture="camera" (change)="fileChangeEvent($event)"
    accept="{{ isVideo === true ? 'video' : 'image'}}/*" />
  <div *ngIf="selected && deleteButton">
    <button type="button" class="full-width" mat-raised-button color="warn" (click)="onClickDelete()">
      <span class="material-icons">
        delete
      </span>
      Deletar
    </button>
  </div>
  <div *ngIf="!selected || !deleteButton">
    <button type="button" mat-raised-button class="btn-input-file" (click)="openPopUpCamera()">
      <span *ngIf="hasIcon" class="material-icons">
        {{icon}}
      </span>
      {{ isVideo ? 'Novo Vídeo 360' : 'Nova Imagem' }}
    </button>
  </div>
</div>
