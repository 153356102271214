import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import InputFileEventEmitterProps from 'src/types/InputFileEventEmitterProps';
import getCurrentLocation, { Coords } from 'src/utils/getCurrentLocation';
import {
  DecisaoCameraComponent,
  DialogResponseCameraType
} from '../decisao-camera/decisao-camera.component';
import { PopUpBoroscopioComponent } from '../pop-up-boroscopio/pop-up-boroscopio.component';
import { PopUpCameraComponent } from '../pop-up-camera/pop-up-camera.component';
import {
  DialogConfirmResponseProps,
  GenericConfirmComponent,
} from './../../generic-confirm/generic-confirm.component';
import { PopUpCropperComponent } from './../pop-up-cropper/pop-up-cropper.component';
import { PopUpVideoComponent } from './../pop-up-video/pop-up-video.component';

//
export interface DialogPopUpResponseProps {
  arquivo: '';
  confirm: boolean;
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input-card.component.html',
  styleUrls: ['./file-input-card.component.css'],
})
export class FileInputCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() midia?: string | ArrayBuffer | null | SafeUrl;
  @Input() isVideo: boolean;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() hasError: boolean;
  @Input() ativoFoto: boolean;
  @Input() ativoVideo: boolean;

  dataUrl: any;
  location: Coords;

  @Output() newFile = new EventEmitter<InputFileEventEmitterProps>();
  @Output() deleteFile = new EventEmitter<number>();

  @ViewChild('croppedImage') croppedImage?: ElementRef;

  @ViewChild('inputFileRef') inputFileRef?: ElementRef;
  largura = window.innerWidth;
  altura = window.innerHeight;

  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {
    this.id = 0;
    this.isVideo = false;
    this.title = '';
    this.ativoFoto = false;
    this.ativoVideo = false;
    this.subtitle = '';
    this.hasError = false;
    this.location = { latitude: 0, longitude: 0 };
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy - FileInputCardComponent' + this.title);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges - FileInputCardComponent');
  }

  ngOnInit(): void {
    // console.log('ngOnInit - FileInputCardComponent');
  }

  public async add(event: any): Promise<void> {
    if (event.target.files && event.target.files[0]) {
      const eventFile: File = event.target.files[0];
      const minType = eventFile.type.split('/')[1];
      const location = await getCurrentLocation();
      const completeFileName = `${this.id}.${minType}`;
      const file = new File(event.target.files, completeFileName, {
        type: eventFile.type,
      });
      const date = new Date();

      if (eventFile.type.includes('image')) {
        this.openPopUpCropper(event);
      } else {
        this.asVideo(file, date, location);
      }

      if (this.inputFileRef) {
        this.inputFileRef.nativeElement.value = '';
      }
    }
  }

  async asImage(file: File, date: Date, location: Coords): Promise<void> {
    const url = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(file)
    );
    this.midia = url;
    this.subtitle = date.toISOString();
    this.newFile.emit({ file, url, date, location });
  }

  async asVideo(file: File, date: Date, location: Coords): Promise<void> {
    this.subtitle = date.toISOString();
    this.midia = 'video';
    this.newFile.emit({ file, date, location, url: 'video'});
  }

  remove(): void {
    const dialogRef = this.dialog.open<
      GenericConfirmComponent,
      any,
      DialogConfirmResponseProps
    >(GenericConfirmComponent, {
      width: '80%',
      maxWidth: '400px',
    });

    const subs = dialogRef.beforeClosed().subscribe((result) => {
      if (result?.confirm == true) {
        this.midia = null;
        this.subtitle = '';
        this.deleteFile.emit(this.id);
      } else {
        result?.confirm == false;
      }

      dialogRef.close();
      subs.unsubscribe();
    });
  }

  async checkLocationAvailability(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log('Latitude:', position.coords.latitude);
            console.log('Longitude:', position.coords.longitude);

            resolve(true);
          },
          (error) => {
            console.error('Erro ao obter a localização:', error);
            resolve(false);
          }
        );
      } else {
        console.error('Geolocalização não suportada pelo navegador.');
        resolve(false);
      }
    });
  }

  openPopUp() {
    const dialogDecisao = this.dialog.open<
      DecisaoCameraComponent,
      any,
      DialogResponseCameraType
    >(DecisaoCameraComponent, {
      hasBackdrop: true,
    });
    const subsCamera = dialogDecisao.beforeClosed().subscribe((result) => {
      if (result?.cameraType == undefined) return;

      if (result.cameraType == 'boroscopio') {
        this.openPopUpBoroscopio();
      } else {
        this.openPopUpCamera();
      }
    });
  }

  async openPopUpBoroscopio() {
    const dialogCameraRef = this.dialog.open<
      PopUpBoroscopioComponent,
      any,
      any
    >(PopUpBoroscopioComponent, {
      width: '100vw',
      height: '100vh',
      hasBackdrop: false,
      data: {},
      panelClass: 'dialog-camera-custom',
    });

    const subsCamera = dialogCameraRef
      .beforeClosed()
      .subscribe(async (result) => {
        if (result.confirm) {
          const dialogRefCropper = this.dialog.open<
            PopUpCropperComponent,
            any,
            any
          >(PopUpCropperComponent, {
            width: '90%',
            maxWidth: '400px',
            height: '90%',

            data: {
              photo: result.photo,
            },
            panelClass: 'custom-dialog-container',
          });

          const subsCropper = dialogRefCropper
            .beforeClosed()
            .subscribe(async (result) => {
              if (result?.confirm === true) {
                const isLocationAvailable =
                  await this.checkLocationAvailability();
                if (!isLocationAvailable) {
                  this.toastr.error(
                    'Não foi possível gravar a localização.',
                    'Erro',
                    {
                      positionClass: 'toast-top-center',
                    }
                  );
                  return;
                }

                const base64 = result.arquivo;
                const location = await getCurrentLocation();

                const mime = 'image/png';
                const res: Response = await fetch(base64);
                const blob: Blob = await res.blob();
                const completeFileName = `${this.id}.${mime.split('/')[1]}`;
                const file = new File([blob], completeFileName, { type: mime });

                const date = new Date();
                this.subtitle = date.toISOString();
                const url = this.sanitizer.bypassSecurityTrustUrl(
                  URL.createObjectURL(file)
                );
                this.midia = url;
                this.newFile.emit({ file, url, date, location });
              }

              subsCropper.unsubscribe();
            });
        }

        subsCamera.unsubscribe();
      });
  }

  async openPopUpCamera() {
    const dialogCameraRef = this.dialog.open<PopUpCameraComponent, any, any>(
      PopUpCameraComponent,
      {
        width: '100vw',
        height: '100vh',
        hasBackdrop: false,
        data: {},
        panelClass: 'dialog-camera-custom',
      }
    );

    const subsCamera = dialogCameraRef
      .beforeClosed()
      .subscribe(async (result) => {
        if (result.confirm) {
          const dialogRefCropper = this.dialog.open<
            PopUpCropperComponent,
            any,
            any
          >(PopUpCropperComponent, {
            width: '90%',
            maxWidth: '400px',
            height: '90%',

            data: {
              photo: result.photo,
            },
            panelClass: 'custom-dialog-container',
          });

          const subsCropper = dialogRefCropper
            .beforeClosed()
            .subscribe(async (result) => {
              if (result?.confirm === true) {
                const isLocationAvailable =
                  await this.checkLocationAvailability();
                if (!isLocationAvailable) {
                  this.toastr.error(
                    'Não foi possível gravar a localização.',
                    'Erro',
                    {
                      positionClass: 'toast-top-center',
                    }
                  );
                  return;
                }

                const base64 = result.arquivo;
                const location = await getCurrentLocation();

                const mime = 'image/png';
                const res: Response = await fetch(base64);
                const blob: Blob = await res.blob();
                const completeFileName = `${this.id}.${mime.split('/')[1]}`;
                const file = new File([blob], completeFileName, { type: mime });

                const date = new Date();
                this.subtitle = date.toISOString();
                const url = this.sanitizer.bypassSecurityTrustUrl(
                  URL.createObjectURL(file)
                );
                this.midia = url;
                this.newFile.emit({ file, url, date, location });
              }

              subsCropper.unsubscribe();
            });
        }

        subsCamera.unsubscribe();
      });
  }

  async openPopUpCropper(event: any): Promise<void> {
    if (this.largura > 770 || this.altura > 1024) {
      this.toastr.error(
        'Entre em um dispositivo móvel para realizar a vistoria!.',
        'Erro',
        {
          positionClass: 'toast-top-center',
        }
      );
      this.dialog.closeAll();
    } else {
      const dialogRef = this.dialog.open<PopUpCropperComponent, any, any>(
        PopUpCropperComponent,
        {
          width: '90%',
          maxWidth: '400px',
          height: '90%',

          data: {
            fileEvent: event,
          },
          panelClass: 'custom-dialog-container',
        }
      );

      const subs = dialogRef.beforeClosed().subscribe(async (result) => {
        if (result?.confirm === true) {
          const isLocationAvailable = await this.checkLocationAvailability();
          if (!isLocationAvailable) {
            this.toastr.error(
              'Não foi possível gravar a localização.',
              'Erro',
              {
                positionClass: 'toast-top-center',
              }
            );
            return;
          }

          const base64 = result.arquivo;
          const location = await getCurrentLocation();

          const mime = 'image/png';
          const res: Response = await fetch(base64);
          const blob: Blob = await res.blob();
          const completeFileName = `${this.id}.${mime.split('/')[1]}`;
          const file = new File([blob], completeFileName, { type: mime });

          const date = new Date();
          this.subtitle = date.toISOString();
          const url = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(file)
          );
          this.midia = url;
          this.newFile.emit({ file, url, date, location });
        } else {
          result?.confirm == false;
        }
        {
          dialogRef.close();
        }

        subs.unsubscribe();
      });
    }
  }

  openPopUpVideo(): void {
    const dialogRef = this.dialog.open<PopUpVideoComponent, any, any>(
      PopUpVideoComponent,
      {
        width: '100vw',
        height: '100vh',
        panelClass: 'dialog-video-custom',
      }
    );
    const subs = dialogRef.beforeClosed().subscribe(async (result) => {
      if (result?.confirm == true) {
        const blob = result.arquivo;
        const mime = 'video/mp4';
        const location = await getCurrentLocation();
        const completeFileName = `${this.id}.${mime.split('/')[1]}`;
        this.midia = blob;
        const file = new File([blob], completeFileName, { type: 'video/mp4' });
        const date = new Date();
        this.subtitle = date.toISOString();
        this.newFile.emit({ file, date, location, url: 'video', duration: result.duration });

      } else {
        result?.confirm == false;
      }

      dialogRef.close();
      subs.unsubscribe();
    });
  }
}
