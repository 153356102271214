import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DominioService } from 'src/app/services/dominio.service';
import Dominio from 'src/models/dominio.model';

@Component({
  selector: 'app-tipo-vistoria',
  templateUrl: './vistoria-tipo-vistoria.component.html',
  styleUrls: ['./vistoria-tipo-vistoria.component.css']
})
export class VistoriaTipoVistoriaComponent implements OnInit {
  @Input() visualizacao: boolean;
  @Input() control: FormControl;

  tiposVistoria: Dominio[];

  constructor(private dominioService: DominioService) {
    this.visualizacao = false;
    this.control = new FormControl();
    this.tiposVistoria = [];
  }

  ngOnInit(): void {
    this.dominioService.tiposVistoria().subscribe(data => {
      this.tiposVistoria = data;
    });
  }

  compareTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 && tipo1 == tipo2;
  }
}
