import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ApontamentoVistoriadorArvoreComponent } from 'src/app/apontamento-vistoriador-arvore/apontamento-vistoriador-arvore.component';
import { CustomInputFileComponent } from 'src/app/custom-input-file/custom-input-file.component';
import { SharedModule } from 'src/app/template/shared.module';
import { VistoriaCriarComponent } from './criar/vistoria-criar.component';
import { VistoriaDemaisInformacoesComponent } from './demais-informacoes/vistoria-demais-informacoes.component';
import { VistoriaDemaisInformacoesViewComponent } from './demais-informacoes-view/vistoria-demais-informacoes-view.component'; 
import { VistoriaInformacoesProprietarioComponent } from './informacoes-proprietario/vistoria-informacoes-proprietario.component';
import { VistoriaInformacoesVeiculoComponent } from './informacoes-veiculo/vistoria-informacoes-veiculo.component';
// import { VistoriaItensVistoriadosComponent } from './itens-vistoriados/vistoria-itens-vistoriados.component';
import { VistoriaListFilterComponent } from './list-filter/vistoria-list-filter.component';
import { VistoriaListComponent } from './list/vistoria-list.component';
import { VistoriaMidiaViewComponent } from './midia-view/vistoria-midia-view.component';
import { VistoriaMidiaComponent } from './midia/vistoria-midia.component';
import { VistoriaTipoVistoriaComponent } from './tipo-vistoria/vistoria-tipo-vistoria.component';
import { VistoriaViewComponent } from './view/vistoria-view.component';
import { VistoriaRoutingModule } from './vistoria-routing.module';
import { MatProgressBarModule} from '@angular/material/progress-bar'
import { ReturnHomeComponent } from 'src/app/return-home/return-home.component';
import { EmplacamentoComponent } from './emplacamento/emplacamento.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



@NgModule({
  declarations: [
    VistoriaListComponent,
    VistoriaListFilterComponent,
    VistoriaViewComponent,
    VistoriaTipoVistoriaComponent,
    VistoriaInformacoesVeiculoComponent,
    VistoriaInformacoesProprietarioComponent,
    VistoriaMidiaViewComponent,
    VistoriaMidiaComponent,
    // VistoriaItensVistoriadosComponent,
    VistoriaDemaisInformacoesComponent,
    VistoriaDemaisInformacoesViewComponent,
    VistoriaCriarComponent,
    ApontamentoVistoriadorArvoreComponent,
    CustomInputFileComponent,
    EmplacamentoComponent,
    
  ],
  imports: [
    SharedModule,
    VistoriaRoutingModule,
    InfiniteScrollModule,
    MatListModule,
    MatBottomSheetModule,
    MatTreeModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSlideToggleModule,

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class VistoriaModule { }
