<mat-toolbar class="filter-toolbar" color="primary">
  <h1>Filtro de Pesquisa</h1>
</mat-toolbar>
<form [formGroup]="form" (ngSubmit)="pesquisar()">
  <mat-form-field class="s-42 mr-20 mt-5" appearance="outline">
    <mat-label>Data</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="dataHora" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="s-42" appearance="outline">
    <mat-label>Placa</mat-label>
    <input matInput type="text" formControlName="placa" />
  </mat-form-field>
  <mat-form-field class="s-90" appearance="outline">
    <mat-label>Situação</mat-label>
    <mat-select formControlName="idSituacao">
      <mat-option value=""></mat-option>
      <mat-option value=1>Aprovado</mat-option>
      <mat-option value=2>Aprovado com Restrição</mat-option>
      <mat-option value=3>Reprovado</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="s-90" appearance="outline">
    <mat-label>Ativa/Inativa</mat-label>
    <mat-select formControlName="inativarVistoria">
      <mat-option value=""></mat-option>
      <mat-option value="false">Ativa</mat-option>
      <mat-option value="true">Inativa</mat-option>
    </mat-select>
  </mat-form-field>
  

  <div class="mt-5 align-center">
    <button type="submit" mat-flat-button class="mr-10" color="primary">Pesquisar</button>
    <button type="reset" mat-stroked-button>Limpar</button>
  </div>
</form>
