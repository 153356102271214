<input  #inputFileRef [id]="id" [style.display]="'none'" type="file" capture="camera"  (change)="add($event)" accept="{{isVideo? 'video' : 'image'}}/*" />

<mat-card [class]="'mt-10 mb-10 ' + (hasError? 'ng-invalid' : '')">
  <mat-card-header>
    <div class="image" mat-card-avatar>
      <div *ngIf="!isVideo">
        <img *ngIf="midia" [src]="midia" />
        <svg *ngIf="!midia" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="60px" viewBox="0 0 24 24" width="60px" fill="#ABABAB">
          <rect fill="none" height="24" width="24"/>
          <path d="M21,5c0-1.1-0.9-2-2-2H5.83L21,18.17V5z"/>
          <path d="M2.81,2.81L1.39,4.22L3,5.83V19c0,1.1,0.9,2,2,2h13.17l1.61,1.61l1.41-1.41L2.81,2.81z M6,17l3-4l2.25,3l0.82-1.1l2.1,2.1 H6z" />
        </svg>
      </div>
      <div *ngIf="isVideo">
        <svg *ngIf="!midia" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="60px" viewBox="0 0 24 24" width="60px" fill="#ABABAB">
          <rect fill="none" height="24" width="24" y="0"/>
          <path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M9.5,16.5v-9l7,4.5L9.5,16.5z"/>
        </svg>
        <svg *ngIf="midia" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="60px" viewBox="0 0 24 24" width="60px" fill="#35845E">
          <rect fill="none" height="24" width="24" y="0"/>
          <path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M9.5,16.5v-9l7,4.5L9.5,16.5z"/>
        </svg>
      </div>
    </div>
    <mat-card-title class="mt-10">{{title}}</mat-card-title>
    <span class="asterisco-vermelho" *ngIf="ativoFoto == false && !midia && !isVideo"> * </span>

    <mat-card-subtitle>{{subtitle | date: 'dd/MM/yyyy HH:mm:ss'}}</mat-card-subtitle>

    <button type="button" (click)="openPopUpVideo()"  *ngIf="!midia && isVideo" mat-icon-button>
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#1e90ff">
        <rect fill="none" height="24" width="24"/>
        <path d="M3,4V1h2v3h3v2H5v3H3V6H0V4H3z M6,10V7h3V4h7l1.83,2H21c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V10H6z M13,19c2.76,0,5-2.24,5-5s-2.24-5-5-5s-5,2.24-5,5S10.24,19,13,19z M9.8,14c0,1.77,1.43,3.2,3.2,3.2s3.2-1.43,3.2-3.2 s-1.43-3.2-3.2-3.2S9.8,12.23,9.8,14z"/>
      </svg>
    </button>

    <button  type="button" (click)="openPopUpCamera()" *ngIf="!midia && !isVideo" mat-icon-button>
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#1e90ff">
        <rect fill="none" height="24" width="24"/>
        <path d="M3,4V1h2v3h3v2H5v3H3V6H0V4H3z M6,10V7h3V4h7l1.83,2H21c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V10H6z M13,19c2.76,0,5-2.24,5-5s-2.24-5-5-5s-5,2.24-5,5S10.24,19,13,19z M9.8,14c0,1.77,1.43,3.2,3.2,3.2s3.2-1.43,3.2-3.2 s-1.43-3.2-3.2-3.2S9.8,12.23,9.8,14z"/>
      </svg>
    </button>


    <button type="button" (click)="remove()" *ngIf="midia" mat-icon-button>
      <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#ef5350">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
      </svg>
    </button>
  </mat-card-header>
</mat-card>

<script>
  type="module"
  src="https://unpkg.com/@ionic/pwa-elements@latest/dist/ionicpwaelements/ionicpwaelements.esm.js"
</script>
