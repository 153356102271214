import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import Arquivo from 'src/models/arquivo.model';
import InputFileEventEmitterProps from 'src/types/InputFileEventEmitterProps';
import getCurrentLocation from 'src/utils/getCurrentLocation';
import { GenericConfirmComponent } from './../generic-confirm/generic-confirm.component';
import { DialogConfirmResponseProps } from './../return-home/return-home.component';
import { PopUpCropperComponent } from './../template/pop-up-cropper/pop-up-cropper.component';
import { PopUpCameraComponent } from '../template/pop-up-camera/pop-up-camera.component';
import { DecisaoCameraComponent, DialogResponseCameraType } from '../template/decisao-camera/decisao-camera.component';

export interface DialogPopUpResponseProps {
  arquivo: '';
  confirm: boolean;
}
@Component({
  selector: 'app-custom-input-file',
  templateUrl: './custom-input-file.component.html',
  styleUrls: ['./custom-input-file.component.css'],
})
export class CustomInputFileComponent implements OnInit {
  @ViewChild('inputFileRef') inputFileRef?: ElementRef;
  largura = window.innerWidth;
  altura = window.innerHeight;

  @Input() isVideo = false;
  @Input() hasIcon = true;
  @Input() icon = 'add_a_photo';
  @Input() deleteButton = true;
  @Input() arquivos!: Arquivo[];

  @Input() midia?: string | ArrayBuffer | null | SafeUrl;

  @Output() newFile = new EventEmitter<InputFileEventEmitterProps>();
  @Output() deleteFile = new EventEmitter();

  selected = false;
  completeFileName: any;
  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  async fileChangeEvent(event: any): Promise<void> {
    console.log('stop1');
    if (event.target.files && event.target.files[0]) {
      const oldFIle: File = event.target.files[0];
      const minType = oldFIle.type.split('/')[1];
      const existeArquivos = this.arquivos.length > 0;
      const ultimoArquivo = this.arquivos[this.arquivos.length - 1];
      this.completeFileName = `${existeArquivos ? ultimoArquivo.id + 1 : 1}.${minType}`;

      this.openPopUpCropper(event);
    }
  }

  onClickDelete(): void {
    if (this.inputFileRef) {
      this.inputFileRef.nativeElement.value = '';
      this.selected = false;
      this.deleteFile.emit();
    }
  }

  onClickSelectFile(): void {
    // this.openPopUpCropper(event);
    if (this.inputFileRef) {
      this.inputFileRef.nativeElement.click();
    }
  }

  async openPopUpCamera() {
    const dialogCameraRef = this.dialog.open<PopUpCameraComponent, any, any>(
      PopUpCameraComponent,
      {
        width: '100vw',
        height: '100vh',
        hasBackdrop: false,
        data: { cameraType: 'camera'},
        panelClass: 'dialog-camera-custom',
      }
    );

    const subsCamera = dialogCameraRef.beforeClosed().subscribe(async (result) => {
      if (result.confirm) {
        const dialogRefCropper = this.dialog.open<
          PopUpCropperComponent,
          any,
          any
        >(PopUpCropperComponent, {
          width: '90%',
          maxWidth: '400px',
          height: '90%',

          data: {
            photo: result.photo,
          },
          panelClass: 'custom-dialog-container',
        });

        const subsCropper = dialogRefCropper
          .beforeClosed()
          .subscribe(async (result) => {
            if (result?.confirm === true) {
              const isLocationAvailable =
                await this.checkLocationAvailability();
              if (!isLocationAvailable) {
                this.toastr.error(
                  'Não foi possível gravar a localização.',
                  'Erro',
                  {
                    positionClass: 'toast-top-center',
                  }
                );
                return;
              }

              const base64 = result.arquivo;
              const location = await getCurrentLocation();

              const mime = 'image/png';
              const res: Response = await fetch(base64);
              const blob: Blob = await res.blob();
              const file = new File([blob], this.completeFileName, { type: mime });

              const date = new Date();
              const url = this.sanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(file)
              );
              this.midia = url;
              this.newFile.emit({ file, url, date, location });
            }

            subsCropper.unsubscribe();
          });
      }

      subsCamera.unsubscribe();
    });
  }

    async checkLocationAvailability(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log('Latitude:', position.coords.latitude);
            console.log('Longitude:', position.coords.longitude);

            resolve(true);
          },
          (error) => {
            console.error('Erro ao obter a localização:', error);
            resolve(false);
          }
        );
      } else {
        console.error('Geolocalização não suportada pelo navegador.');
        resolve(false);
      }
    });
  }

  openPopUpCropper(event: any): void {
    console.log('openPopUpCropper', event);

    if (this.largura > 770 || this.altura > 1024) {
      console.log('tela muito grande');
      this.toastr.error(
        'Entre em um dispositivo móvel para realizar a vistoria!.',
        'Erro',
        {
          positionClass: 'toast-top-center',
        }
      );
      this.dialog.closeAll();
    } else {
      const dialogRef = this.dialog.open<PopUpCropperComponent, any, any>(
        PopUpCropperComponent,
        {
          width: '90%',
          maxWidth: '400px',
          height: '90%',

          data: {
            fileEvent: event,
          },
          panelClass: 'custom-dialog-container',
        }
      );

      const subs = dialogRef.beforeClosed().subscribe(async (result) => {
        if (result?.confirm == true) {
          console.log('crop do mal', event);

          const base64 = result.arquivo;
          const location = await getCurrentLocation();

          const mime = 'image/png';
          const res: Response = await fetch(base64);
          const blob: Blob = await res.blob();
          const file = new File([blob], this.completeFileName, { type: mime });

          const date = new Date();
          const url = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(file)
          );
          this.midia = url;
          this.newFile.emit({ file, url, date, location });
        } else {
          result?.confirm == false;
        }

        dialogRef.close();
        subs.unsubscribe();

      });
    }
  }

  openBoroscopio(){
    console.log('abriu o boroscopio')
  }

  openPopUp(){
    const dialogDecisao = this.dialog.open<DecisaoCameraComponent, any, DialogResponseCameraType>(
      DecisaoCameraComponent,
      {
        hasBackdrop: true,
      }
    )
    const subsCamera = dialogDecisao.beforeClosed().subscribe(async (result) => {
      if (result?.cameraType == 'camera') {
        this.openPopUpCamera()
      }else{
        this.openBoroscopio();
      }
    });
  }

  remove(): void {
    const dialogRef = this.dialog.open<
      GenericConfirmComponent,
      any,
      DialogConfirmResponseProps
    >(GenericConfirmComponent, {
      width: '80%',
      maxWidth: '400px',
    });

    const subs = dialogRef.beforeClosed().subscribe((result) => {
      if (result?.confirm == true) {
        this.midia = null;
      } else {
        result?.confirm == false;
      }

      dialogRef.close();
      subs.unsubscribe();
    });
  }
}
