import { Component, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogResponseProps {
  value?: string,
  confirm: boolean;
};

@Component({
  selector: 'app-dialog-set-description',
  templateUrl: './dialog-set-description.component.html',
  styleUrls: ['./dialog-set-description.component.css']
})
export class DialogSetDescriptionComponent implements OnInit {

  ngOnInit(): void {
  }

  fieldValue: string = '';

  constructor(
    private dialogRef: MatDialogRef<DialogSetDescriptionComponent, DialogResponseProps>
  ) { }

  onNoClick(): void {
    this.dialogRef.close({ confirm: false });
  }

  onOkClick(): void {
    if (this.fieldValue) {
      this.dialogRef.close({ value: this.fieldValue, confirm: true });
    }
  }
}
