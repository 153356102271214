<h2 mat-dialog-title>Confirmação</h2>
<div mat-dialog-content>
  <p>Deseja voltar para a tela inicial? Todo o conteúdo será perdido.</p>
  <mat-form-field appearance="outline">
  </mat-form-field>
</div>

<div>
  <button type="button" mat-raised-button (click)="onNoClick() "color="warn">Não</button>
  <button  class="sim" type="button" mat-raised-button (click)="onOkClick() "color="primary">Sim</button>
</div>
