import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { VISTORIA_FORM_NAMES } from 'src/models/enums/vistoria-form-names.enum';
import validaCpfCnpj from 'src/utils/validaCpfCnpj';

@Component({
  selector: 'app-informacoes-proprietario',
  templateUrl: './vistoria-informacoes-proprietario.component.html',
  styleUrls: ['./vistoria-informacoes-proprietario.component.css']
})
export class VistoriaInformacoesProprietarioComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() visualizacao: boolean;

  FormNames = VISTORIA_FORM_NAMES;
  mask = MASKS;

  isCnpj = true;
  cpfCnpjValue = '';
  cpfCnpjCharCount = 0;

  constructor() {
    this.visualizacao = false;
  }

  ngOnInit(): void { }





  isCpf(): boolean{
    const length = this.cpfCnpjValue.replace(/\D/g, '').length;
    return length === 11;
  }

  // changeCpfCnpj(value: any, ref: HTMLInputElement): void {
  //   const length = this.cpfCnpjValue.replace(/\D/g, '').length;
  //   console.log('oq está chegando no começo', length )
  //   this.isCnpj = true;    if(length === 11){
  //     this.isCnpj = false
  //     ref.value = validaCpfCnpj(this.cpfCnpjValue + value.data)
  //     this.cpfCnpjValue = ref.value;
  //   }
  //   else if(length === 14){
  //     this.isCnpj = true
  //     ref.value = validaCpfCnpj(this.cpfCnpjValue + value.data)
  //     this.cpfCnpjValue = ref.value

  //   }

  //   if ((this.cpfCnpjCharCount === 11 && length === 11) || this.cpfCnpjCharCount > 11) {
  //     if (!this.isCnpj) {
  //       console.log('oq está chegando dentro do !this.isCnpj', 'this.isCnpj', this.isCnpj, 'ref.value', ref.value, 'this.cpfCnpjCharCount', this.cpfCnpjCharCount )
  //       this.isCnpj = true;
  //       ref.value = this.cpfCnpjValue + value.data;
  //       this.cpfCnpjCharCount = length + value.data.length;
  //       this.form.controls['cpfCnpj'].setValidators([Validators.required, NgBrazilValidators.cnpj]);

  //       console.log('oq está saindo,  no final do if' )

  //     } else {
  //       this.cpfCnpjCharCount = length;
  //       console.log('entrou no else', this.cpfCnpjCharCount)
  //     }

  //   } else {
  //     this.cpfCnpjCharCount = length;
  //     console.log('entrou no else em baixo', this.cpfCnpjCharCount)
  //   }

  //   if (this.cpfCnpjCharCount <= 11 && this.isCnpj) {
  //     this.isCnpj = false;
  //     this.form.controls['cpfCnpj'].setValidators([Validators.required, NgBrazilValidators.cpf]);
  //   }
  // }

  changeCpfCnpj(value: any, ref: HTMLInputElement): void {

    if (value.data == undefined) return;

    var transformedInput = this.cpfCnpjValue + value.data;
    transformedInput = this.cpfCnpjValue.replace(/\D/g,""); //Remove tudo o que não é dígito
    if(transformedInput.length > 14){
        transformedInput = transformedInput.substr(0, 14);
    }

    if(transformedInput.length <= 11){
        transformedInput=transformedInput.replace(/(\d{3})(\d)/,"$1.$2");       //Coloca um ponto entre o terceiro e o quarto dígitos
        transformedInput=transformedInput.replace(/(\d{3})(\d)/,"$1.$2");       //Coloca um ponto entre o terceiro e o quarto dígitos de novo (para o segundo bloco de números)
        transformedInput=transformedInput.replace(/(\d{3})(\d{1,2})$/,"$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos

    }else if (transformedInput.length > 11 ){

        transformedInput=transformedInput.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        transformedInput=transformedInput.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        transformedInput=transformedInput.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        transformedInput=transformedInput.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    }

    ref.value = transformedInput;
}

  getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }
}
