import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { take } from "rxjs/operators";
import { RetornoConVis } from "src/models/dto/retorno-convis.dto";

@Injectable({
  providedIn: 'root'
})
export class IntegracaoService {
  private readonly RESOURCE = '/integration';

  constructor(private http: HttpClient) { }

  veiculo(placa: string, renavam: string): Observable<RetornoConVis> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('placa', placa);
    httpParams = httpParams.append('renavam', renavam);

    return this.http.get<RetornoConVis>(`${this.RESOURCE}/veiculo`, { params: httpParams }).pipe(take(1));
  }
}
