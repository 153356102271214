import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import InputFileEventEmitterProps from 'src/types/InputFileEventEmitterProps';

export interface DialogPopUpResponseProps {
  confirm: boolean;
}

@Component({
  selector: 'app-pop-up-cropper',
  templateUrl: './pop-up-cropper.component.html',
  styleUrls: ['./pop-up-cropper.component.css'],
})
export class PopUpCropperComponent implements OnInit {
  @ViewChild('inputFileRef') inputFileRef?: ElementRef;
  @Output() newFile = new EventEmitter<InputFileEventEmitterProps>();
  @Input() subtitle: string;
  importedFile: any;
  isLoading = false;
  showImage = false;
  showButton = false;
  imageBase64: any;
  imageFile: any;
  imageChangedEvent: any = '';
  croppedImage: any;
  canvasRotation = 0;
  rotation?: number;
  translateH = 0;
  translateV = 0;
  scale = 1;
  aspectRatio = 16 / 9;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageURL?: string;
  loading = false;
  hideResizeSquares = true;
  disabled = true;
  defaultWidth = 1024;
  defaultHeigth = 768;

  constructor(private dialogRef: MatDialogRef<PopUpCropperComponent>, @Inject(MAT_DIALOG_DATA) public data: any){

   if(data.photo){
    this.importedFile = data.photo;
    this.imageChangedEvent = null
   }else{
    this.imageChangedEvent = data.fileEvent;
  }
    this.subtitle = '';
  }

  ngOnInit(): void {}

  

  onNoClick(): void {
    this.dialogRef.close({ confirm: false });
  }

  onOkClick(): void {
    var url = this.croppedImage.base64;
    this.dialogRef.close({ confirm: true, arquivo: url });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.loading = false;
  }

  loadImageFailed() {
    console.error('Load image failed');
  }

  showCutter() {
    this.hideResizeSquares = !this.hideResizeSquares;
    this.disabled = !this.disabled;
  }

  rotateRight() {
    this.isLoading = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.isLoading = false;
    }, 500); //setTimeout é para disparar apenas depois do evento do botão.
  }
}
