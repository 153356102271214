import { Component, OnInit } from '@angular/core';
import { SnackBarServiceService } from 'src/app/messages/snack-bar-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ChaveDispositivoService } from 'src/app/services/chave-dispositivo.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  perfilUsuario: number = NaN;
  validarPermissao: boolean = true;

  constructor(
    private authService: AuthService,
    private chaveService: ChaveDispositivoService,
    private snackBarService: SnackBarServiceService,
    public permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.perfilUsuario = parseInt(localStorage.getItem('codigoPerfil') || '');
    console.log(this.perfilUsuario);
    this.nomeUsuario;
    this.authService.loadProfile();
    // if (!this.containsDeviceCode()) {
    //   this.openPopUp();
    // }
    this.validarPermissao = this.validarPermissaoUser();
    console.log(this.validarPermissao +  "permissao")
  }

  validarPermissaoUser(): boolean {
    const permissionUser = this.permissionService.hasPermission(
      this.perfilUsuario
    );
    if (permissionUser) return true;

    return false;
  }

  get nomeUsuario() {
    let nomeUsuario = localStorage.getItem('nomeUsuario');
    return nomeUsuario;
  }

  closeDialog() {
    const modal = document.getElementById('dialog') as HTMLDialogElement;
    modal.close();
  }

  openPopUp() {
    const modal = document.getElementById('dialog') as HTMLDialogElement;
    // console.log(modal);
    modal.showModal();
    /*const buttonClose = document.getElementById("botaoFechar")
    buttonClose!.onclick = function () {
      modal!.close();
    }*/
  }

  ativarChaveDispositivo() {
    let inputDispositivo = document.getElementById('dispositivo') as HTMLInputElement;
    let inputChave = document.getElementById('chave') as HTMLInputElement;
    const dispositivoValue = inputDispositivo.value;
    const chaveValue = inputChave.value;

    if (dispositivoValue == null || dispositivoValue == '' || chaveValue == null || chaveValue == '') {
      this.snackBarService.campoVazio('Campo dispositivo vazio!');
    } else {
      try {
        this.chaveService.ativarChaveDispositivo(dispositivoValue, chaveValue).subscribe(
          (response) => {
            console.log(response);
            if (response == true) {
              this.snackBarService.ativa('Dispositivo ativado com sucesso!');
              localStorage.setItem('device-code', dispositivoValue);
              localStorage.setItem('key-code', chaveValue);
              this.closeDialog();
            } else {
              inputDispositivo.value = '';
              inputChave.value = '';
            }
          },
          (error) => {
            console.log(error);
            this.snackBarService.erro(`Erro ao ativar dispositivo.`);
            inputDispositivo.value = '';
            inputChave.value = '';
          }
        );
      } catch (error) {
        console.log(error);
        inputDispositivo.value = '';
        inputChave.value = '';
        this.authService.logout();
      }
    }
  }

  containsDeviceCode() {
    if (localStorage.getItem('device-code') != null && localStorage.getItem('key-code') != null) {
      return true;
    }
    return false;
  }
}
