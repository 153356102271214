<mat-toolbar color="primary">
<div  class="material-icons">
  <mat-icon (click)="returnHome()" matRipple>keyboard_backspace</mat-icon>
</div>
  <h1>Nova Vistoria</h1>
</mat-toolbar>
<form (ngSubmit)="submit()" [formGroup]="form">

  <div class="form-wrapper">

    <div class="container">
      <!-- <button (click)="unloadNotificationBack($event)"> aq </button> -->
        <div [hidden]=" step != 2" [formGroup]="form">
          <mat-card class="mb-10"  >
            <app-informacoes-proprietario [visualizacao]="false" [form]="form"></app-informacoes-proprietario>
            <app-tipo-vistoria [visualizacao]="false" [control]="getControl(FormNames.TIPO_VISTORIA)"></app-tipo-vistoria>
            <!-- <app-emplacamento [visualizacao]="false" [control]="getControl(FormNames.EMPLACAMENTO)"> </app-emplacamento> -->
          </mat-card>
        </div>

        <div [hidden]=" step != 1" [formGroup]="form">
            <mat-card class="mb-10">
                <app-informacoes-veiculo #informacoesVeiculo [visualizacao]="false" (integracao)="consultaVeiculo()"></app-informacoes-veiculo>
            </mat-card>

          </div>


        <div [hidden]=" step != 3" >
          <mat-card class="mb-10">
            <app-vistoria-midia #midia [fotosObrigatorias]="fotosObrigatorias" [videosObrigatorios]="videosObrigatorios" [isSubmitting]="false"></app-vistoria-midia>
          </mat-card>
        </div>

          <!-- <div [hidden]=" step != 4">
              <mat-toolbar class="white">
                <h2>Itens Vistoriados</h2>
              </mat-toolbar>
              <mat-card class="mt-10 mb-10">
                <app-itens-vistoriados #vistoriados [visualizacao]="false" [itensVistoriados]="itensVistoriados"></app-itens-vistoriados>
              </mat-card>
          </div> -->


          <div [hidden]=" step != 4">
             <mat-toolbar class="white">
                <h2>Apontamentos do Vistoriador</h2>
              </mat-toolbar>
              <div class="container">
                <app-apontamento-vistoriador-arvore #arvoreApontamentos (hasChanged)="arvoreHasChanged()"></app-apontamento-vistoriador-arvore>
              </div>
          </div>

          <div [hidden]=" step != 5" >
            <mat-toolbar class="white">
              <h2>Apontamentos e Observação</h2>
            </mat-toolbar>
            <mat-card class="mb-10 mt-10">
              <app-emplacamento [visualizacao]="false" [control]="getControl(FormNames.EMPLACAMENTO)"> </app-emplacamento>
              <app-demais-informacoes [visualizacao]="false" [control]="getControl(FormNames.OBSERVACAO)" [apontamentos]="arvoreApontamentos.data"></app-demais-informacoes>
              <!-- <mat-form-field class="mt-5 s-100" appearance="outline">
                <mat-label>Situação da Vistoria</mat-label>
                <mat-select [(value)]="situacaoSelecionada" (selectionChange)="atualizarBotaoFinalizar()">
                  <mat-option [value]="SituacaoVistoria.APROVADA"><a class="circle circle-aprovado"></a> Aprovada</mat-option>
                  <mat-option [value]="SituacaoVistoria.REPROVADA"><a class="circle reprovado"></a>  Reprovada</mat-option>
                  <mat-option [value]="SituacaoVistoria.APROVADA_COM_RESTRICAO"><a class="circle apontamentos"></a> Aprovada com Restrição</mat-option>
                </mat-select>
              </mat-form-field> -->
            </mat-card>
          </div>
          <div [hidden]=" step != 6" >
            <mat-card class="mb-10 mt-10">
              <mat-card class="linha-descricao" id="validaInformacoes" (click)="voltarVeiculo()"> <mat-icon>directions_car</mat-icon> Informações do Veículo <mat-icon class='seta'>keyboard_arrow_right</mat-icon> </mat-card>
              <mat-card class="linha-descricao" id="validaInformacoesProprietario"(click)="voltarProprietario()"> <mat-icon>perm_identity</mat-icon> Proprietário Atual <mat-icon class='seta'>keyboard_arrow_right</mat-icon> </mat-card>
                <mat-card class="linha-descricao" id="validaMidia"(click)="voltarMidia()"> <mat-icon>perm_media</mat-icon> Mídias <mat-icon class='seta'>keyboard_arrow_right</mat-icon> </mat-card>
                <!-- <mat-card class="linha-descricao" (click)="voltarItensVistoriados()"> <mat-icon>view_headline</mat-icon> Itens Vistoriados <mat-icon class='seta'>keyboard_arrow_right</mat-icon> </mat-card> -->
                <mat-card class="linha-descricao" (click)="voltarApVistoriador()"> <mat-icon>view_headline</mat-icon> Apontamentos Vistoriador <mat-icon class='seta'>keyboard_arrow_right</mat-icon> </mat-card>
                <mat-card class="linha-descricao" (click)="voltarApObservacao()"> <mat-icon>description</mat-icon>  Apontamentos e Observação <mat-icon class='seta'>keyboard_arrow_right</mat-icon> </mat-card>
            </mat-card>
          </div>
    </div>
  </div>
    <div class="container">
      <div class="row">
        <input class='previous-button' type="button" value='Anterior' name='anterior' *ngIf="(step != 1 && step < 6)" (click)="previous()"  >
        <input class='favorite next-button' type="button" value='Próximo' name='proximo' *ngIf="(step != 5 && step < 6 && step != 4)" (click)="next()" >
        <input class='favorite next-button' type="button" value='Próximo' name='proximo' *ngIf="(step == 4)" (click)="next()" >
        <input class='favorite next-button' type="button" value='Resumo' name='resumo' *ngIf="step == 5" (click)="nextResume()" >
      </div>
      <br>
        <div [hidden]=" step == 6 " > <mat-progress-bar [mode]="'determinate'" [value]="percent" > </mat-progress-bar> </div>
      <br>
        <span [hidden]=" step == 6 "  class="content-step"> {{step}} de 5 </span>
        <button [showRoundedCorner]="true" timeInterval="200" [disabled]="isLoading" [repeatButton]="true"  type="submit" *ngIf="step == 6" [class]="'submit-button ' + finalizarBtnColor" mat-raised-button> {{finalizarBtnName}}
          <img *ngIf="isLoading" class="spinner"  src="./assets/img/spinner.gif"  />
        </button>
      <br>
      <div [hidden]=" stepOk != true">
        <input [hidden]="step == 6" class='favorite next-button-return' type="button" value='Retornar Resumo' name='resumo' (click)="returnAbstract()" >
      </div>
    </div>
</form>
