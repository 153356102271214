import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TipoCombustivel } from 'src/models/tipo-combustivel';

@Injectable({
  providedIn: 'root'
})
export class TipoCombustivelService {
  private readonly RESOURCE = '/tipo-combustivel';

  constructor(private http: HttpClient) {}

  list(): Observable<TipoCombustivel[]> {
    return this.http.get<TipoCombustivel[]>(this.RESOURCE).pipe(take(1));
  }
  
}
