import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogConfirmResponseProps {
  confirm: boolean;
};

@Component({
  selector: 'app-generic-confirm',
  templateUrl: './generic-confirm.component.html',
  styleUrls: ['./generic-confirm.component.css']
})
export class GenericConfirmComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<GenericConfirmComponent>
  ) { }

  ngOnInit(): void {
  }


  onNoClick(): void {

    this.dialogRef.close({ confirm: false });
  }

  onOkClick(): void {
    this.dialogRef.close({ confirm: true });
  }

}
