<div class="camera-container">

  <div class="close-container">
    <mat-icon class="icon icon-close" (click)="close()">close</mat-icon>
  </div>

  <div class="video-container" [style.display]="!isPhotoPreviewActive ? 'flex' : 'none'">
    <img class="video-preview" crossorigin="anonymous" #boroscopioPreview>

    <div class="container-actions">
      <div class="actions">
        <mat-icon class="icon icon-camera" (click)="takePhoto()">photo_camera</mat-icon>
      </div>
    </div>
  </div>

  <canvas hidden #canvasVideo></canvas>

  <div class="preview-container" [style.display]="isPhotoPreviewActive ? 'block' : 'none'">

    <div class="image-preview-container">
      <img class="image-preview" #photoPreview src="" alt="">
    </div>

    <div class="container-actions-preview">
      <div class="actions-preview">
        <button (click)="repeatPhoto()">Repetir</button>
        <button (click)="savePhoto()">OK</button>
      </div>
    </div>
  </div>

</div>
