import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import Apontamento from "src/models/apontamento.model";

@Injectable({
  providedIn: 'root'
})
export class ApontamentoService {
  private readonly RESOURCE = '/apontamento';

  constructor(private http: HttpClient) { }

  arvore(termoPesquisa: string): Observable<Apontamento[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('search', termoPesquisa);
    return this.http.get<Apontamento[]>(this.RESOURCE, { params: httpParams }).pipe(take(1));
  }
}
