import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { DialogResponseProps, DialogSetDescriptionComponent } from 'src/app/dialog-set-description/dialog-set-description.component';
import Arquivo from 'src/models/arquivo.model';
import { DadosArquivo } from 'src/models/dados-arquivo.model';
import InformacaoArquivo from 'src/models/dto/informacao-arquivo.dto';
import InputFileEventEmitterProps from 'src/types/InputFileEventEmitterProps';

@Component({
  selector: 'app-vistoria-midia-view',
  templateUrl: './vistoria-midia-view.component.html',
  styleUrls: ['./vistoria-midia-view.component.css']
})
export class VistoriaMidiaViewComponent implements OnInit {
  @Input() imagensVeiculo: DadosArquivo[];
  @Input() itensAgregados: DadosArquivo[];
  @Input() videos: DadosArquivo[];

  informacaoFotos: InformacaoArquivo[];
  informacaoVideos: InformacaoArquivo[];
  informacaoAgregados: InformacaoArquivo[];
  arquivos: Arquivo[];

  //TO-DO: Popular informacaoVideos e informacaoFotos
  constructor(private dialog: MatDialog) {
    this.imagensVeiculo = [];
    this.itensAgregados = [];
    this.videos = [];
    this.informacaoFotos = [];
    this.informacaoVideos = [];
    this.informacaoAgregados = [];
    this.arquivos = [];
  }

  ngOnInit(): void { }

  getArquivoInputFile(file: DadosArquivo): InputFileEventEmitterProps {
    const date = new Date(file.descricao);
    return {
      date: date,
      file: new File([], file.titulo),
      location: { latitude: file.latitude, longitude: file.longitude },
      url: file.url
    };
  }

  novoItemAgregado(inputFile: InputFileEventEmitterProps): void {
    const id = this.idItemAgregado();
    const dialogRef = this.dialog.open<DialogSetDescriptionComponent, any, DialogResponseProps>(
      DialogSetDescriptionComponent,
      {
        width: '80%',
        maxWidth: '400px'
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirm && result?.value) {
        this.arquivos.push({
          id: id,
          inputFile: inputFile
        });

        this.informacaoAgregados.push({
          id: id,
          dataHora: inputFile.date,
          descricao: result.value,
          idItemVistoriado: id,
          latitude: inputFile.location.latitude,
          longitude: inputFile.location.longitude,
          mediaSizeOriginalBytes: inputFile.file.size
          
        });
      }
    });
  }

  private idItemAgregado = (): number => this.informacaoAgregados.length === 0 ?
    -1 : (this.informacaoAgregados[this.informacaoAgregados.length - 1].id - 1);

  getArquivoAgregado(id: number): string | ArrayBuffer | undefined | null | SafeUrl {
    const index = this.arquivos.findIndex(a => a.id === id);
    return this.arquivos[index].inputFile.url;
  }

  removerItemAgregado(id: number): void {

    const index = this.arquivos.findIndex(a => a.id === id);
    this.arquivos.splice(index, 1);

    const indexInfo = this.informacaoAgregados.findIndex(a => a.id === id);
    this.informacaoAgregados.splice(indexInfo, 1);
  }
}
