import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarServiceService {

  constructor(private snackBar: MatSnackBar) { }

  ativa(msg: string){
    this.snackBar.open(msg,'', {duration:3*1000,panelClass:'alerta-aprovado'})
  }

  erro(msg: string){
    this.snackBar.open(msg,'', {duration:3*1000,panelClass:'alerta-nao-aprovado'})
  }

  campoVazio(msg: string){
    this.snackBar.open(msg,'', {duration:3*1000,panelClass:'alerta-campo-vazio'})
  }

}
