import SITUACAO_VISTORIA from 'src/models/enums/situacoesVistoria.enum';
import { Component, OnInit } from '@angular/core';
import { VistoriaService } from 'src/app/services/vistoria.service';
import { VistoriaInfo } from 'src/models/vistoria-info.model';
import { CORES_SITUACAO_VISTORIA } from 'src/models/enums/cores-situacao-vistoria.enum';
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { VistoriaListFilterComponent } from '../list-filter/vistoria-list-filter.component';
import { VistoriaFiltro } from 'src/models/dto/vistoria-filtro.dto';
import { ToastrService } from 'ngx-toastr';
import { SITUACAO_VISTORIA_BACK_OFFICE } from 'src/models/enums/situacao-back-office';


@Component({
  selector: 'app-vistoria-list',
  templateUrl: './vistoria-list.component.html',
  styleUrls: ['./vistoria-list.component.css']
})
export class VistoriaListComponent implements OnInit {
  isSearching: boolean;
  distance: number;
  throttle: number;
  pageNo: number;
  pageSize: number;
  totalSize: number;
  filtro: VistoriaFiltro;
  totalPages: number;

  vistorias: VistoriaInfo[];

  mascaraPlacaCustomizada = [/[a-s,A-S]/, /[a-s,A-S]/, /[a-s,A-S]/, '-', /\d/, /[\d, a-s,A-S]/, /\d/, /\d/];

  constructor(private vistoriaService: VistoriaService,
    private bottomSheet: MatBottomSheet,
    private toastr: ToastrService) {
    this.vistorias = [];
    this.isSearching = true;
    this.distance = 2;
    this.throttle = 0;
    this.pageNo = 0;
    this.pageSize = 100;
    this.totalSize = 100;
    this.totalPages = 0
    this.filtro = new VistoriaFiltro();
  }

  ngOnInit(): void {
    this.search();
  }

  onScroll(): void {
    if (!this.isSearching) {
      this.isSearching = true;
      this.search();
    }
  }

  openFilterSheet(): void {
    const filterSheetRef = this.bottomSheet.open(VistoriaListFilterComponent);
    filterSheetRef.afterDismissed().subscribe((data) => {
      if (data) {
        this.vistorias = [];
        this.filtro = data;
        this.clicaPesquisa()
      }
    });

  }

  printLaudo(vistoriaId: number): void {
    this.vistoriaService.printLaudo(vistoriaId)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "Laudo.pdf";
        link.click();
      }, (errorResponse) => {
        this.toastr.error(errorResponse.errors[0], 'Erro ao gerar relatório', {
          positionClass: 'toast-top-center'
        });
      });
  }

  corPorSituacao(tipoSituacao: SITUACAO_VISTORIA): string {
    switch (tipoSituacao) {
      case SITUACAO_VISTORIA.APROVADA_COM_RESTRICAO:
        return CORES_SITUACAO_VISTORIA.APROVADO_COM_RESTRICAO;
      case SITUACAO_VISTORIA.REPROVADA:
        return CORES_SITUACAO_VISTORIA.REPROVADO;
      case SITUACAO_VISTORIA.APROVADA:
      default:
        return CORES_SITUACAO_VISTORIA.APROVADO;
    }
  }

  corPorSituacaoBackOffice(tipoSituacaoBackOffice: SITUACAO_VISTORIA_BACK_OFFICE): string {
    switch (tipoSituacaoBackOffice) {
      case SITUACAO_VISTORIA_BACK_OFFICE.PENDENTE_DE_APROVACAO:
        return CORES_SITUACAO_VISTORIA.PENDENTE_DE_APROVACAO;
      case SITUACAO_VISTORIA_BACK_OFFICE.APROVADO_COM_RESTRICAO:
        return CORES_SITUACAO_VISTORIA.APROVADO_COM_RESTRICAO;
      case SITUACAO_VISTORIA_BACK_OFFICE.REPROVADO:
        return CORES_SITUACAO_VISTORIA.REPROVADO;
      case SITUACAO_VISTORIA_BACK_OFFICE.APROVADO:
      default:
        return CORES_SITUACAO_VISTORIA.APROVADO;
    }
  }


  private search(): void {
    /*const canSearch = ((this.page + 1 * this.pageSize) <= this.totalSize);
      if(this.totalSize != 0 && !canSearch) {
        return;
      }*/
    const resultFiltro = this.filtro;
    this.vistoriaService.filtroListPwa(resultFiltro, this.pageNo, this.pageSize).subscribe(data => {
      this.totalPages = data.totalPages
      this.vistorias.push(...data.content);
      this.isSearching = false;
    });
    this.pageNo += 1
  }

  clicaPesquisa() {
    this.pageNo = 0;
    this.search();
  }
}
