import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import {
  DialogResponseProps,
  DialogSetDescriptionComponent,
} from 'src/app/dialog-set-description/dialog-set-description.component';
import Arquivo from 'src/models/arquivo.model';
import InformacaoArquivo from 'src/models/dto/informacao-arquivo.dto';
import ItemVistoriado from 'src/models/dto/item-vistoriado.dto';
import InputFileEventEmitterProps from 'src/types/InputFileEventEmitterProps';

@Component({
  selector: 'app-vistoria-midia',
  templateUrl: './vistoria-midia.component.html',
  styleUrls: ['./vistoria-midia.component.css'],
})
export class VistoriaMidiaComponent implements OnInit, OnChanges {
  @Input() isSubmitting: boolean;
  @Input() fotosObrigatorias: ItemVistoriado[];
  @Input() videosObrigatorios: ItemVistoriado[];

  arquivos: Arquivo[];

  informacaoFotos: InformacaoArquivo[];
  informacaoAgregados: InformacaoArquivo[];
  informacaoVideos: InformacaoArquivo[];

  showCropper = false;

  constructor(private dialog: MatDialog) {
    this.fotosObrigatorias = [];
    this.videosObrigatorios = [];
    this.arquivos = [];
    this.informacaoFotos = [];
    this.informacaoAgregados = [];
    this.informacaoVideos = [];
    this.isSubmitting = false;
  }

  ngOnInit(): void {
    console.log('VistoriaMidiaComponent - ngOnInit');
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('VistoriaMidiaComponent - ngOnChanges');
  }



  novoArquivoObrigatorio(inputFile: InputFileEventEmitterProps): void {
    if (inputFile.file.type.includes('video')) {
      this.novoVideo(inputFile);
      // console.log("Tamanho do video: \n")
      // console.log(inputFile.file.size)
      // console.log("bytes")
    } else {
      this.novaImagem(inputFile);
      // console.log("Tamanho da imagem: \n")
      // console.log(inputFile.file.size)
      // console.log("bytes")
    }
  }

  removerArquivoObrigatorio(id: number, isVideo: boolean): void {
    if (isVideo) {
      this.removerVideo(id);
    } else {
      this.removerImagem(id);
    }
  }

  novoItemAgregado(inputFile: InputFileEventEmitterProps): void {
    const id = this.idItemAgregado();
    const dialogRef = this.dialog.open<
      DialogSetDescriptionComponent,
      any,
      DialogResponseProps
    >(DialogSetDescriptionComponent, {
      width: '80%',
      maxWidth: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm && result?.value) {
        this.arquivos.push({
          id: id,
          inputFile: inputFile,
        });

        this.informacaoAgregados.push({
          id: id,
          dataHora: inputFile.date,
          descricao: result.value,
          idItemVistoriado: id,
          latitude: inputFile.location.latitude,
          longitude: inputFile.location.longitude,
          mediaSizeOriginalBytes: inputFile.file.size
        });
      }
    });
  }

  removerItemAgregado(id: number): void {
    const index = this.arquivos.findIndex((a) => a.id === id);
    this.arquivos.splice(index, 1);

    const indexInfo = this.informacaoAgregados.findIndex((a) => a.id === id);
    this.informacaoAgregados.splice(indexInfo, 1);
  }

  getArquivoAgregado(id: number): string | ArrayBuffer | undefined | null | SafeUrl {
    const index = this.arquivos.findIndex((a) => a.id === id);
    return this.arquivos[index].inputFile.url;
  }

  private idItemAgregado = (): number =>
    this.informacaoAgregados.length === 0
      ? -1
      : this.informacaoAgregados[this.informacaoAgregados.length - 1].id - 1;

  private async  novoVideo(inputFile: InputFileEventEmitterProps): Promise<void> {
    // const duracao = await this.obterDuracaoDoVideo(inputFile.file);

    const id = +inputFile.file.name.split('.')[0];
    this.arquivos.push({
      id: id,
      inputFile: inputFile,
    });
    this.informacaoVideos.push({
      id: id,
      dataHora: inputFile.date,
      descricao: '',
      idItemVistoriado: id,
      latitude: inputFile.location.latitude,
      longitude: inputFile.location.longitude,
      mediaSizeOriginalBytes: inputFile.file.size,
      duration: inputFile.duration
    });

    this.mudaSituacaoVideo(id, 1);
  }



  private removerVideo(id: number): void {
    const indexArquivo = this.arquivos.findIndex((v) => v.id === id);
    this.arquivos.splice(indexArquivo, 1);

    const indexInfo = this.informacaoVideos.findIndex((v) => v.id === id);
    this.informacaoVideos.splice(indexInfo, 1);

    this.mudaSituacaoVideo(id, 0);
  }

  private mudaSituacaoVideo = (id: number, selecionado: number) =>
    (this.videosObrigatorios = this.videosObrigatorios.map((v) => {
      if (v.idItemVistoria === id) {
        v.selecionado = selecionado = 1;
      }

      return v;
    }));

  private novaImagem(inputFile: InputFileEventEmitterProps): void {
    const id = +inputFile.file.name.split('.')[0];
    this.arquivos.push({
      id: id,
      inputFile: inputFile,
    });
    this.informacaoFotos.push({
      id: id,
      dataHora: inputFile.date,
      descricao: '',
      idItemVistoriado: id,
      latitude: inputFile.location.latitude,
      longitude: inputFile.location.longitude,
      mediaSizeOriginalBytes: inputFile.file.size
    });

    this.mudaSituacaoImagem(id, 1);
  }

  private removerImagem(id: number): void {
    const indexArquivo = this.arquivos.findIndex((v) => v.id === id);
    this.arquivos.splice(indexArquivo, 1);

    const indexInfo = this.informacaoFotos.findIndex((v) => v.id === id);
    this.informacaoFotos.splice(indexInfo, 1);

    this.mudaSituacaoImagem(id, 0);
  }

  private mudaSituacaoImagem = (id: number, selecionado: number) =>
    (this.fotosObrigatorias = this.fotosObrigatorias.map((v) => {
      if (v.idItemVistoria === id) {
        v.selecionado = selecionado = 1;
      }

      return v;
    }));
}
