<div class="camera-container">

  <div class="close-container">
    <mat-icon class="icon icon-close" (click)="close()">close</mat-icon>
  </div>

  <!-- <pre class="devices">{{devices}}</pre> -->

  <div class="video-container" [hidden]="isPhotoPreviewActive">
    <video class="video-preview" autoplay #videoPreview></video>

    <div class="slider-zoom">
      <mat-icon class="icon">zoom_out</mat-icon>
      <mat-slider [min]="minZoom" [max]="maxZoom" [step]="stepZoom" (input)="increaseZoom($event.value)">
        <input matSliderThumb  [(ngModel)]="valueZoom" >
      </mat-slider>
      <mat-icon class="icon">zoom_in</mat-icon>
    </div>

    <div class="container-actions">
      <div class="actions">
        <mat-icon class="icon icon-switch" (click)="toggleCamera()">switch_camera</mat-icon>
        <mat-icon class="icon icon-camera" (click)="takePhoto()">photo_camera</mat-icon>
      </div>
    </div>
  </div>

  <canvas hidden #canvasVideo></canvas>

  <div class="preview-container" [style.display]="isPhotoPreviewActive ? 'block' : 'none'">

    <div class="image-preview-container">
      <img class="image-preview" #photoPreview src="" alt="">
    </div>

    <div class="container-actions-preview">
      <div class="actions-preview">
        <button (click)="repeatPhoto()">Repetir</button>
        <button (click)="savePhoto()">OK</button>
      </div>
    </div>
  </div>

</div>
