<mat-grid-list cols="1" rowHeight="4:1">
  <mat-grid-tile colspan="1">
    Bem vindo(a) {{nomeUsuario}}
  </mat-grid-tile>
  <div [hidden]="validarPermissao == false">
    <mat-grid-tile colspan="1" rowspan="2">
      <button mat-button class="home-button green-white-content" routerLink="/vistoria/criar">
        <h4>Nova Vistoria</h4>
        <mat-icon>insert_drive_file</mat-icon>
      </button>
    </mat-grid-tile>

  </div>
  <mat-grid-tile colspan="1" rowspan="2">
    <button mat-button class="home-button green-white-content" routerLink="/vistoria">
      <h4>Consultar Vistorias</h4>
      <mat-icon>search</mat-icon>
    </button>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
  </mat-grid-tile>
</mat-grid-list>



<!-- <dialog id="dialog">
  <div class="formulario" >
    <span class="titulo-popup"> Ativar dispositivo</span>
    <div>
    <label for="codigo-aparelho">Código:</label><br>
    <input type="text" name="codigo-aparelho" id="dispositivo">
  </div>
  <div>
    <label for="chave-aparelho">Chave:</label><br>
    <input type="text" name="chave-aparelho" id="chave">
    <button type="button" (click)="ativarChaveDispositivo()" class="button-send">Validar</button>
  </div>
  </div>
</dialog> -->