import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Credentials } from 'src/models/dto/credentials.dto';
import { LatLongUsuarioDTO } from 'src/models/dto/latLongUsuario.dto';
import { Profile } from 'src/models/dto/profile.dto';

@Injectable()
export class AuthService {
  private readonly BASE_TOKEN_URL = environment.urlToken;
  private readonly cisab = environment.cisab;
  private readonly baseUrl = environment.apiBaseUrl;
  // private readonly urlLogout = this.baseUrl.replace('/api', '/oauth/logout');  



  jwtHelperService: JwtHelperService = new JwtHelperService();
  private logged = new BehaviorSubject<boolean>(false);
  get isLogged() {
    if (this.isAuthenticated()) {
      this.logged.next(true);
    }

    return this.logged.asObservable();
  }

  authorize = () => this.logged.next(this.isAuthenticated());

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  login(credentials: Credentials): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('username', credentials.username)
      .set('password', credentials.password)
      .set('grant_type', 'password');

    const headers = {
      Authorization: 'Basic ' + this.cisab,
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    return this.http.post<any>(`${this.BASE_TOKEN_URL}`, params, { headers: headers });
  }

  getToken(): any {
    const tokenString = localStorage.getItem('accessToken');
    if (tokenString) {
      const token = JSON.parse(tokenString).access_token;
      return token;
    }
    return null;
  }

  async logout(): Promise<void> {
    localStorage.removeItem('nomeUsuario');
    localStorage.removeItem('urlFoto');
    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    localStorage.removeItem('login');
    localStorage.removeItem('nomePerfil');
    localStorage.removeItem('codigoPerfil');
    this.logged.next(false);
    const token = this.getToken();

    const headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    localStorage.removeItem('accessToken');
    try {
      await this.http.post<any>(`${this.baseUrl}/auth/logout`, null, { headers: headers }).toPromise();
    } catch (error) {
      console.error("Logout failed", error);
    }
  }


  isAuthenticated(): boolean {
    const token = this.getToken();
    if (token) {
      const expired = this.jwtHelperService.isTokenExpired(token);
      return !expired;
    }

    return false;
  }

  getUserAuthenticated(): string {
    const token = this.getToken();
    if (token) {
      return this.jwtHelperService.decodeToken(token).user_name;
    }
    return 'Visitante';
  }

  // async loadProfile(): Promise<void> {
  //   this.http.get<Profile>(`/usuario/find/${this.getUserAuthenticated()}`).subscribe(response => {
  //     localStorage.setItem('nomeUsuario', response.nome);
  //     localStorage.setItem('urlFoto', response.urlFoto);
  //     return Promise.resolve(true);
  //   }, errorResponse =>  {
  //     this.toastr.error('Não foi possível realizar o login', 'Erro');
  //     return Promise.resolve(false);
  //   })
  // }

  async loadProfile(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get<Profile>(`/usuario/find/${this.getUserAuthenticated()}`).subscribe(
        (response) => {
          localStorage.setItem('nomeUsuario', response.nome);
          localStorage.setItem('urlFoto', response.urlFoto);
          localStorage.setItem('login', response.login);
          localStorage.setItem('nomePerfil', response.perfil?.nome);
          localStorage.setItem('codigoPerfil', response.perfil?.codigo);
          resolve(true);
        },
        (errorResponse) => {
          this.toastr.error('Não foi possível realizar o login', 'Erro');
          resolve(false);
        }
      );
    });
  }

  loadProfileInfo(): Observable<Profile> {
    return this.http.get<Profile>(`/usuario/find/${this.getUserAuthenticated()}`);
  }

  atualizarOuCadastrarLocUsuario(latLongUsuario: LatLongUsuarioDTO): Observable<LatLongUsuarioDTO> {
    return this.http.put<LatLongUsuarioDTO>(`/usuario/capturar-localizacao`, latLongUsuario);
  }
}
