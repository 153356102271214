export enum VISTORIA_FORM_NAMES {
  TIPO_VISTORIA = 'tipoVistoria',
  EMPLACAMENTO = 'idEmplacamento',

  //Informação do Veículo
  TIPO_VEICULO = 'tipoVeiculo',
  PLACA = 'placa',
  RENAVAM = 'renavam',
  MARCA = 'marca',
  MODELO = 'modelo',
  COR = 'cor',
  ANO_FABRICACAO = 'anoFabricacao',
  ANO_MODELO = 'anoModelo',
  CARROCERIA = 'carroceria',
  ESPECIE = 'especie',
  TIPO_COMBUSTIVEL = 'idTipoCombustivel',
  CAPACIDADE_PASSAGEIROS = 'capacidadePassageiros',
  POTENCIA = 'potencia',
  CILINDRADA = 'cilindrada',
  CAPACIDADE_CARGA = 'capacidadeCarga',
  CMT = 'cmt',
  PBT = 'pbt',
  CHASSI = 'chassi',
  ETAVIS = 'etaVis',
  NUMERO_MOTOR = 'numeroMotor',
  HODOMETRO = 'hodometro',
  PRIMEIRO_EMPLACAMENTO = 'primeiroEmplacamento',
  NUMERO_EIXOS = 'numeroEixos',

  //Proprietario
  NOME_PROPRIETARIO = 'nome',
  CPF_CNPJ_PROPRIETARIO = 'cpfCnpj',

  //Demais Informações
  OBSERVACAO = 'observacao'
}
