import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DominioService } from 'src/app/services/dominio.service';
import Dominio from 'src/models/dominio.model';

@Component({
  selector: 'app-emplacamento',
  templateUrl: './emplacamento.component.html',
  styleUrls: ['./emplacamento.component.css']
})
export class EmplacamentoComponent implements OnInit {

  @Input() visualizacao: boolean;
  @Input() control: FormControl;
  
  idEmplacamento: Dominio[];

  constructor(private dominioService: DominioService) {
    this.visualizacao = false;
    this.control = new FormControl();
    this.idEmplacamento = [];
  }
  ngOnInit(): void {
    this.dominioService.listarEmplacamento().subscribe(data => {
      this.idEmplacamento = data;
    });
  }

  compareTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 && tipo1 == tipo2;
  }

}
