<mat-toolbar class="white">
  <h2>Proprietário Atual</h2>
</mat-toolbar>
<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Nome</mat-label>
  <input matInput type="text" [formControl]="getFormControl(FormNames.NOME_PROPRIETARIO)" onkeyup="this.value = this.value.toUpperCase();">
</mat-form-field>
<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>CPF/CNPJ</mat-label>
  <input matInput #cpfCnpjRef maxlength="18" type="text" [formControl]="getFormControl(FormNames.CPF_CNPJ_PROPRIETARIO)" (input)="changeCpfCnpj($event, cpfCnpjRef)"
    [(ngModel)]="cpfCnpjValue"  onkeyup="this.value = this.value.toUpperCase()">
  </mat-form-field>
