<mat-card>
  <h2 *ngIf="title">{{title}}</h2>
  
  <mat-card-content>

    <img *ngIf="imageUrl && !isVideo" [src]="imageUrl"> 
    <video *ngIf="isVideo && imageUrl" #video width="100%" height="100%" [src]="imageUrl" type="video/mp4" controls></video>
    <div *ngIf="imageDate" class="image-date">
      <span>Latitude:</span>
      <br />
      <span>{{localizacaoLat}}</span>
    </div>
    <div *ngIf="imageDate" class="image-date">
      <span>Longitude:</span>
      <br />
      <span>{{localizacaoLong}}</span>
    </div>
  </mat-card-content>

  <app-file-input
    *ngIf="id && title && isImageToBeChanged && inputFile"
    [hasError]="isSubmitting"
    [isVideo]="isVideo" 
    [id]="id"
    [title]="title"
    [ativoFoto]="true"
    (newFile)="novoArquivoObrigatorio($event)"
    (deleteFile)="removerArquivoObrigatorio($event, false)">
  </app-file-input>

  <div *ngIf="deleteButton">
    <button mat-raised-button class="full-width" color="warn" (click)="handleDeleteImage()">
      <span class="material-icons">
        delete
      </span>
      <span>Deletar</span>
    </button>
  </div>
</mat-card>
