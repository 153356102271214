import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TokenInterceptor } from 'src/interceptors/token.interceptor';
import { ApontamentoService } from './apontamento.service';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { CarroceriaService } from './carroceria.service';
import { DominioService } from './dominio.service';
import { IntegracaoService } from './integracao.service';
import { ItemVistoriaService } from './item-vistoria.service';
import { VistoriaService } from './vistoria.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    VistoriaService,
    DominioService,
    ApontamentoService,
    CarroceriaService,
    ItemVistoriaService,
    IntegracaoService,
    AuthService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ]
})
export class ServicesModule {}
