import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DadosApontamento } from 'src/models/dados-apontamento.model';
import CORES_TIPO_APONTAMENTO from 'src/models/enums/CoresTipoApontamento.enum';
import TIPO_APONTAMENTO from 'src/models/enums/TipoApontamento.enum';

@Component({
  selector: 'app-demais-informacoes-view',
  templateUrl: './vistoria-demais-informacoes-view.component.html',
  styleUrls: ['./vistoria-demais-informacoes-view.component.css']
})
export class VistoriaDemaisInformacoesViewComponent implements OnInit {
  @Input() control: FormControl;
  @Input() visualizacao: boolean;
  @Input() apontamentos: DadosApontamento[];

  constructor() {
    this.control = new FormControl();
    this.visualizacao = false;
    this.apontamentos = [];
  }

  ngOnInit(): void { }

  corDoCirculo(tipoApontamento: TIPO_APONTAMENTO): string {
    switch (tipoApontamento) {
      case TIPO_APONTAMENTO.REPROVADO:
        return CORES_TIPO_APONTAMENTO.REPROVADO;
      case TIPO_APONTAMENTO.SUSPEITA_DE_ADULTERACAO:
        return CORES_TIPO_APONTAMENTO.SUSPEITA_DE_ADULTERACAO;
      case TIPO_APONTAMENTO.COM_APONTAMENTOS:
        return CORES_TIPO_APONTAMENTO.COM_APONTAMENTOS;
      default:
        return CORES_TIPO_APONTAMENTO.NAO_APLICAVEL;
    }
  }
}
