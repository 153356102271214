<mat-toolbar class="center-justify" color="primary">
  <h1>Vistoria Veicular</h1>
  <a class="fixed-right" mat-list-item (click)="logout()">
    <mat-icon mat-list-icon>logout</mat-icon>
  </a>
</mat-toolbar>
<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="over" class="example-sidenav" [fixedInViewport]="false" [fixedTopGap]="0"
    [fixedBottomGap]="0">
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-toolbar class="example-footer">Inovvati</mat-toolbar>
