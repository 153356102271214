import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DadosArquivo } from 'src/models/dados-arquivo.model';
import { DadosVistoria } from 'src/models/dados-vistoria.model';
import { ResponseList } from 'src/models/dto/response-list.dto';
import { VistoriaInfo } from 'src/models/vistoria-info.model';
import Arquivo from 'src/models/arquivo.model';
import NovaVistoria from 'src/models/dto/nova-vistoria.dto';
import { VeiculoDetran } from 'src/models/veiculo-detran';
import InativarVistoria from 'src/models/inativar-vistoria';
import { AuthService } from './auth.service';
import UpdateVistoria from 'src/models/dto/update-vistoria.dto';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VistoriaService {
  private readonly RESOURCE = '/vistoria';

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  list(filtro: any, page: number, pageSize: number): Observable<ResponseList<VistoriaInfo>> {
    let httpParams = new HttpParams();
    Object.keys(filtro).forEach(
      key => filtro[key] && (httpParams = httpParams.append(key, filtro[key]))
    );

    httpParams = httpParams.append("page", page.toString());
    httpParams = httpParams.append("pageSize", pageSize.toString());
    return this.http.get<ResponseList<VistoriaInfo>>(`${this.RESOURCE}/list`, { params: httpParams }).pipe(take(1))
  }

  filtroListPwa(filtro: any, pageNo?: number, pageSize?: number): Observable<ResponseList<VistoriaInfo>> {
    const usuarioLogin = this.authService.getUserAuthenticated(); 
    filtro.usuarioLogin = usuarioLogin;
    let httpParams = new HttpParams();
    Object.keys(filtro).forEach(
      key => filtro[key] && (httpParams = httpParams.append(key, filtro[key]))
    );

    return this.http.post<ResponseList<VistoriaInfo>>(`${this.RESOURCE}/lista-filtro-pwa?pageNo=${pageNo}`, filtro);
    // return this.http.post<ResponsePaginationList<VistoriaVeiculo>>(`${this.BASE_URL}/lista-filtro-admin`, { pageNo, pageSize, filtro });

  }

  create(novaVistoria: NovaVistoria, midia: Arquivo[]): Observable<void> {
    const formData = new FormData();
    formData.append('novaVistoriaJson', JSON.stringify(novaVistoria));
    // console.log(novaVistoria);
    for (const arquivo of midia) {
      formData.append(arquivo.id.toString(), arquivo.inputFile.file);
    }

    return this.http.post<void>(`${this.RESOURCE}`, formData).pipe(take(1));
  }

  update(updateVistoria: UpdateVistoria, midia: Arquivo[], idVistoria: number): Observable<void> {
    const formData = new FormData();
    formData.append('updateVistoriaJson', JSON.stringify(updateVistoria));

    for (const arquivo of midia) {
      formData.append(arquivo.id.toString(), arquivo.inputFile.file);
    }

    return this.http.put<void>(`${this.RESOURCE}/editar/${idVistoria}`, formData).pipe(take(1));
  }

  getById(id: number): Observable<DadosVistoria> {
    return this.http.get<DadosVistoria>(`${this.RESOURCE}/${id}`).pipe(take(1));
  }

  getArquivosMidia(id: number): Observable<DadosArquivo[]> {
    return this.http.get<DadosArquivo[]>(`/item-vistoria/media/${id}`).pipe(take(1));
  }

  printLaudo(id: number) {
    return this.http.get(`${this.RESOURCE}/print/laudo/${id}`, { responseType: 'blob' }).pipe(take(1));
  }

  getVeiculoDetranPorPlaca(placa: string, renavam: string): Observable<VeiculoDetran> {
    return this.http.get<VeiculoDetran>(`/integration/veiculo/consulta?placa=${placa}&renavam=${renavam}`).pipe(take(1));
  }

  inativarVistoria(inativarVistoria: InativarVistoria): Observable<InativarVistoria> {
    return this.http.put<InativarVistoria>(`${this.RESOURCE}/inativar-vistoria`, inativarVistoria).pipe(take(1));
  }

  getVeiculoDetranPorChassi(chassi: string): Observable<VeiculoDetran> {
    return this.http.get<VeiculoDetran>(`/integration/veiculo/consulta-primeiro-emplacamento?chassi=${chassi}`).pipe(take(1));
  }

}
