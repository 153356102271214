import { Injectable } from '@angular/core';
import { TipoPerfilEnum } from 'src/models/enums/tipo-perfil-enum';



@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  hasPermission(perfil: number): boolean {
    if (perfil == TipoPerfilEnum.VISTORIADOR || perfil == TipoPerfilEnum.ADMIN_REGIONAIS || perfil == TipoPerfilEnum.ADMIN_MASTER)  return true;
      return false;
  }
}
