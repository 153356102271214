<mat-toolbar class="dark">
  <h2>Imagens do Veículo</h2>
</mat-toolbar>
<app-image-card 
  *ngFor="let imagem of imagensVeiculo; let indice = index" 
  [id]="imagem.id" 
  [inputFile]="true"
  [deleteButton]="true" 
  [title]="imagem.titulo" 
  [imagem]="getArquivoInputFile(imagem)">
</app-image-card>

<mat-toolbar class="dark">
  <h2>Itens Agregados</h2>
</mat-toolbar>

<div *ngIf="itensAgregados.length == 0 && informacaoAgregados.length == 0" class="itensAgregadosNull">
  <h3>Nenhum item agregado</h3>
</div>

<app-image-card 
  *ngFor="let item of itensAgregados; let indice = index" 
  [inputFile]="false"
  [deleteButton]="true" 
  [title]="item.titulo" 
  [imagem]="getArquivoInputFile(item)">
</app-image-card>

<app-file-input
  *ngFor="let agregado of informacaoAgregados; let indice = index"
  [isVideo]="false" 
  [id]="agregado.id"
  [midia]="getArquivoAgregado(agregado.id)"
  (deleteFile)="removerItemAgregado($event)"
  [title]="agregado.descricao" 
  [subtitle]="agregado.dataHora">
</app-file-input>


<app-custom-input-file
    (newFile)="novoItemAgregado($event)"
    [arquivos]="arquivos"
    [deleteButton]="false">
</app-custom-input-file>

<mat-toolbar class="dark">
  <h2>Vídeos</h2>
</mat-toolbar>
<app-image-card 
  *ngFor="let video of videos; let indice = index" 
  [title]="video.titulo" 
  [deleteButton]="true"
  [isVideo]="true" 
  [id]="video.id"
  [imagem]="getArquivoInputFile(video)">
</app-image-card>
