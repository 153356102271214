import { Component, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type CameraType = 'camera' | 'boroscopio';
export interface DialogResponseCameraType {
  confirm: boolean;
  cameraType: CameraType
};

@Component({
  selector: 'app-decisao-camera',
  templateUrl: './decisao-camera.component.html',
  styleUrls: ['./decisao-camera.component.css']
})
export class DecisaoCameraComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DecisaoCameraComponent, DialogResponseCameraType>
  ) { }
  
  ngOnInit(): void {
  }
  
  onSelectCameraType(cameraType: CameraType): void {
    this.dialogRef.close({ confirm: false, cameraType});
  }

}
