import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AtivaChaveDispositivo } from 'src/models/ativa-chave-dispositivo';

@Injectable({
  providedIn: 'root'
})
export class ChaveDispositivoService {

  constructor(private http: HttpClient) { }

  ativarChaveDispositivo(dispositivo: string, chave: string): Observable<any>{
    return this.http.put<AtivaChaveDispositivo>(`/chaves/ativar`, {dispositivo, chave } ).pipe(take(1))
  }

}
