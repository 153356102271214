import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AuthService } from 'src/app/services/auth.service';
import { VistoriaFiltro } from 'src/models/dto/vistoria-filtro.dto';

@Component({
  selector: 'app-vistoria-list-filter',
  templateUrl: './vistoria-list-filter.component.html',
  styleUrls: ['./vistoria-list-filter.component.css']
})
export class VistoriaListFilterComponent implements OnInit {
  form!: FormGroup;

  constructor(private bottomSheetRef: MatBottomSheetRef<VistoriaListFilterComponent>,
              private fb: FormBuilder,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      placa: null,
      dataHora: null,
      idSituacao: null,
      inativarVistoria: null,
      usuarioLogin: this.authService.getUserAuthenticated()
    });
  }

  pesquisar(): void {
    const data = this.form.value.dataHora ? this.form.value.dataHora.toISOString().split('T')[0] : null;
    const filtro: VistoriaFiltro = {
      placa: this.form.value.placa,
      idSituacao: this.form.value.idSituacao,
      dataHora: data,
      inativa: this.form.value.inativarVistoria,
      usuarioLogin: this.authService.getUserAuthenticated()
    };
    console.log(filtro)
    this.bottomSheetRef.dismiss(filtro);
    
  }

  limparFormulario(){
    this.form = this.fb.group({
      placa: null,
      dataHora: null,
      idSituacao: null,
      inativarVistoria: null,
    });
  }

}
