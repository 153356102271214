<div class="container-crop">
  <div *ngIf="isLoading" class="container-spinner">
    <img class="spinner-topo" src="./assets/img/spinner.gif" />
  </div>

  <div class="cropper-wrapper">
    <image-cropper [imageFile]="importedFile" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
      [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatio" [resizeToWidth]="defaultWidth"
      [resizeToHeight]="defaultWidth" [cropperMinWidth]="0" [onlyScaleDown]="true" [roundCropper]="false"
      [canvasRotation]="canvasRotation" [hideResizeSquares]="hideResizeSquares" [disabled]="disabled"
      [transform]="transform" [alignImage]="'center'" [style.display]="showCropper ? null : 'none'"
      backgroundColor="black" format="jpeg" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>


  <div class="cropper-action">
    <button [disabled]="isLoading" type="button" class="rotate" mat-raised-button (click)="rotateRight()">
      <mat-icon>crop_rotate</mat-icon>
    </button>
    <button type="button" class="crop" mat-raised-button (click)="showCutter()">
      <mat-icon>crop_free</mat-icon>
    </button>
  </div>


  <div class="rodape">

    <button type="button" class="cancel-button" mat-raised-button (click)="onNoClick()" color="warn">
      <mat-icon>backspace</mat-icon>
    </button>

    <button type="button" class="confirm-button" mat-raised-button (click)="onOkClick()" color="primary">
      <mat-icon>done_outline</mat-icon>
    </button>

  </div>

</div>
