<mat-form-field class="full-width textarea-form mt-5" appearance="outline">
  <mat-label>Observações</mat-label>
  <textarea matInput [formControl]="control" onkeyup="this.value = this.value.toUpperCase()"></textarea>
</mat-form-field>
<div *ngFor="let apontamento of apontamentos">
  <a [ngClass]="'mt-5 mr-5 circle ' + corDoCirculo(apontamento.situacao? apontamento.situacao : 0)"></a>
  <span #spanRef>{{apontamento.descricao}}</span>
  <!-- <div class="organizar-apontamentos">
    <div class="card-itens">
    <span class="linha-principal"> <span class="titulo-nome">Número:</span>
      {{apontamento.grupoNumero}}.{{apontamento.itemNumero}}.{{apontamento.condicaoNumero}} </span>
    <span #spanRef class="linha-principal"> <span class="titulo-nome">Grupo:</span> {{apontamento.grupoDescricao}}</span>
    <span  class="linha-principal"> <span class="titulo-nome">Item:</span> {{apontamento.itemDescricao}}</span>
    <span class="linha-principal"> <span class="titulo-nome">Condição:</span> {{apontamento.condicaoDescricao}}</span>
  </div> -->
  <!-- </div> -->
</div>
