<mat-card>
  <mat-grid-list cols="1" rowHeight="1.8em">
    <!-- <mat-grid-tile>
      <p class="bold">Itens Vistoriados:</p> -->
      <!-- <div  *ngFor="let itemVistoriado of itensVistoriados">
        <p>
          <span class="bold">{{itemVistoriado.nome}}</span>
        </p>
        {{itemVistoriado}}
        </div> -->

    <!-- </mat-grid-tile> -->
    <mat-grid-tile>
      <p class="bold">Legenda:</p>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="circle apontamentos"></div>
      <span>Com Apontamentos</span>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="circle adulteracao"></div>
      <span>Suspeito de Adulteração</span>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="circle reprovado"></div>
      <span>Reprovado</span>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="circle sem-adulteracao"></div>
      <span>Sem adulteração</span>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="circle apontamento-nao-aplicavel"></div>
      <span>Não aplicável</span>
    </mat-grid-tile>
  </mat-grid-list>
</mat-card>
<mat-card>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Pesquisa</mat-label>
    <input matInput placeholder="Digite 3 letras para pesquisar" [formControl]="termoPesquisa"
      (keydown)="onPesquisaApontamentoTyped($event)">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node class="checkbox" matTreeNodePaddingIndent="20px" *matTreeNodeDef="let node" matTreeNodePadding>
      <mat-checkbox #checkboxRef [checked]="isChecked(node)" [disabled]="treeBlocked && !checkboxRef.checked"
        (change)="onCheckBoxFlagged($event, spanRef, node)">
        <a [ngClass]="'mt-5 mr-5 circle ' + corDoCirculo(node.apontamento.idDominioTipo)"></a>
        <span #spanRef>{{node.apontamento.descricao}}</span>
      </mat-checkbox>
    </mat-tree-node>
    <mat-tree-node matTreeNodePaddingIndent="10px" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.descricao"
      *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <a class="justify">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
        <span>{{node.apontamento.descricao}}</span>
      </a>
    </mat-tree-node>
  </mat-tree>
</mat-card>