import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ImageCropperModule, LoadedImage } from 'ngx-image-cropper';


import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './template/layout/layout.component';
import { NavbarComponent } from './template/navbar/navbar.component';
import { HomeComponent } from './template/home/home.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { DialogSetDescriptionComponent } from './dialog-set-description/dialog-set-description.component';
import { ApontamentoVistoriadorArvoreComponent } from './apontamento-vistoriador-arvore/apontamento-vistoriador-arvore.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from 'src/interceptors/HttpInterceptor';
import { ToastrModule } from 'ngx-toastr';
import { VistoriaModule } from './modules/vistoria/vistoria.module';
import { SharedModule } from './template/shared.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule} from '@angular/material/progress-bar'
import { GenericConfirmComponent } from './generic-confirm/generic-confirm.component';
import { ReturnHomeComponent } from './return-home/return-home.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    NavbarComponent,
    HomeComponent,
    DialogSetDescriptionComponent,
    GenericConfirmComponent,
    ReturnHomeComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    BlockUIModule.forRoot({
      delayStart: 500,
      message: 'Carregando...'
    }),
    BlockUIHttpModule.forRoot(),
    MatSnackBarModule,
    MatSidenavModule,
    MatDialogModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    SharedModule,
    VistoriaModule,
    ImageCropperModule,
    MatProgressBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true, },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, 
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
