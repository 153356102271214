<mat-toolbar class="white mt-5">
  <h2>Informações do Veículo</h2>
</mat-toolbar>

<mat-slide-toggle [formControl]="getFormControl(FormNames.PRIMEIRO_EMPLACAMENTO)" [(ngModel)]="primeiroEmplacamento"
  (click)="limparCampos()" color="primary">Primeiro Emplacamento</mat-slide-toggle>

<!-- <dialog id="modalLoading" open class="dialog"><img  class="spinner-dialog"  src="./assets/img/spinner.gif"/>  </dialog> -->
<!-- (change)="consultaVeiculo()" -->
<mat-form-field class="s-100 mt-5" appearance="outline" *ngIf="!primeiroEmplacamento">
  <mat-label>Placa</mat-label>
  <input required matInput type="text" (oninput)="handleInput($event)" [formControl]="getFormControl(FormNames.PLACA)"
    maxLength="7" onkeyup="this.value = this.value.toUpperCase()" />
  <!-- <button mat-icon-button matSuffix type="button" [disabled]="isLoading" (click)="puxarPlaca()" >
    <img *ngIf="isLoading" class="spinner"  src="./assets/img/spinner.gif"  />
    <mat-icon *ngIf="!isLoading" >search</mat-icon>
  </button> -->
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline" *ngIf="!primeiroEmplacamento">
  <mat-label>Renavam</mat-label>
  <input id="renavam" matInput type="text" [formControl]="getFormControl(FormNames.RENAVAM)" (oninput)="handleInput($event)" onkeyup="this.value = this.value.toUpperCase()"/>
  <button *ngIf="!ocultarBotao" mat-icon-button matSuffix type="button" [disabled]="isLoading" (click)="puxarPlaca()">
    <img *ngIf="isLoading" class="spinner" src="./assets/img/spinner.gif" />
    <mat-icon *ngIf="!isLoading">search</mat-icon>
  </button>
</mat-form-field>

<!-- (change)="consultaVeiculo()" -->
<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Chassi</mat-label>
  <input id="chassi" matInput type="text" (oninput)="handleInput($event)" onkeyup="this.value = this.value.toUpperCase()" (keypress)="omit_special_char($event)"
    [formControl]="getFormControl(FormNames.CHASSI)" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabChassi()" *ngIf="!primeiroEmplacamento">
    <mat-icon>edit</mat-icon>
  </button>
  <button *ngIf="primeiroEmplacamento" mat-icon-button matSuffix type="button" [disabled]="isLoading"
    (click)="puxarChassi()">
    <img *ngIf="isLoading" class="spinner" src="./assets/img/spinner.gif" />
    <mat-icon *ngIf="!isLoading">search</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline" *ngIf="primeiroEmplacamento">
  <mat-label>Renavam</mat-label>
  <input id="renavam" matInput type="text" [formControl]="getFormControl(FormNames.RENAVAM)" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabRenavam()" *ngIf="primeiroEmplacamento">
    <mat-icon>edit</mat-icon>
  </button>

  <button *ngIf="ocultarBotao" mat-icon-button matSuffix type="button" [disabled]="isLoading" (click)="puxarPlaca()">
    <img *ngIf="isLoading" class="spinner" src="./assets/img/spinner.gif" />
    <mat-icon *ngIf="!isLoading">search</mat-icon>
  </button>
</mat-form-field>

<mat-form-field *ngIf="!visualizacao" class="s-100 mt-5" appearance="outline">
  <mat-label>Tipo de Veículo</mat-label>
  <input
    class="inputTipoVeiculo"
    id="tipoVeiculo"
    type="text"
    placeholder="Selecione"
    matInput
    [formControl]="getFormControl(FormNames.TIPO_VEICULO)"
    [matAutocomplete]="autoTiposVeiculo"
    (change)="limpaCampo($event.target)"
  />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabTipoVeiculo()">
    <mat-icon>edit</mat-icon>
  </button>
  <mat-autocomplete #autoTiposVeiculo="matAutocomplete" (optionSelected)="selectTipoVeiculo($event)"
    [displayWith]="optionTextNomeComprimido">
    <mat-option #optionCampoMenorTipoVeiculo id="valueTipoVeiculo"
      *ngFor="let tipoVeiculo of tipoVeiculoFiltradas | async" [value]="tipoVeiculo">
      <span> {{ tipoVeiculo.nomeComprimido }} </span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Eta Vis</mat-label>
  <input id="etaVis" matInput type="text" (keypress)="omit_special_char($event)"
    [formControl]="getFormControl(FormNames.ETAVIS)" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabEtaVis()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field *ngIf="!visualizacao" class="s-100 mt-5" appearance="outline">
  <mat-label>Modelo</mat-label>
  <input
    class="inputModelo"
    id="modelo"
    type="text"
    placeholder="Selecione"
    matInput
    [formControl]="getFormControl(FormNames.MODELO)"
    [matAutocomplete]="autoModelo"
    (change)="limpaCampo($event.target)"
  />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabModelo()">
    <mat-icon>edit</mat-icon>
  </button>
  <mat-autocomplete #autoModelo="matAutocomplete" (optionSelected)="selectModelo($event)" [displayWith]="optionText">
    <mat-option #optionCampoMenorModelo *ngFor="let modelo of modeloFiltradas | async | slice : 0 : 50; index as i"
      [value]="modelo">
      {{ modelo.descricao }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Ano de Fabricação</mat-label>
  <input id="anoDeFabricacao" matInput type="number" [formControl]="getFormControl(FormNames.ANO_FABRICACAO)"
    [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabAnoDeFabricacao()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Modelo de Fabricação</mat-label>
  <input id="modeloDeFabricacao" matInput type="number" [formControl]="getFormControl(FormNames.ANO_MODELO)" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabModeloDeFabricacao()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field *ngIf="!visualizacao" class="s-100 mt-5" appearance="outline">
  <mat-label>Espécie</mat-label>
  <input
    class="inputEspecie"
    id="especie"
    type="text"
    placeholder="Selecione"
    matInput
    [formControl]="getFormControl(FormNames.ESPECIE)"
    [matAutocomplete]="autoEspecies"
    (change)="limpaCampo($event.target)"
  />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabEspecie()">
    <mat-icon>edit</mat-icon>
  </button>
  <mat-autocomplete #autoEspecies="matAutocomplete" (optionSelected)="selectEspecie($event)" [displayWith]="optionText">
    <mat-option #optionCampoMenorEspecie *ngFor="let especie of especiesFiltradas | async" [value]="especie">
      {{ especie.descricao }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Potência</mat-label>
  <input id="potencia" matInput type="number" [formControl]="getFormControl(FormNames.POTENCIA)"
    [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabPotencia()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Cilindrada</mat-label>
  <input id="cilindrada" matInput type="number" [formControl]="getFormControl(FormNames.CILINDRADA)"
    [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabCilindrada()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Capacidade de Carga</mat-label>
  <input id="capacidadeDeCarga" matInput type="number" [formControl]="getFormControl(FormNames.CAPACIDADE_CARGA)"
    [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabCapacidadeDeCarga()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>C.M.T.</mat-label>
  <input id="cmt" matInput type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    [formControl]="getFormControl(FormNames.CMT)" [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabCmt()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>P.B.T</mat-label>
  <input id="pbt" placeholder="Coloque apenas números" matInput type="number"
    onkeypress="return event.charCode >= 48 && event.charCode <= 57" [formControl]="getFormControl(FormNames.PBT)"
    [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabPbt()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<!-- (focusout)="closedCarroceriaAutoComplete($event)" -->
<mat-form-field *ngIf="!visualizacao" class="s-100 mt-5" appearance="outline">
  <mat-label>Carroceria</mat-label>
  <input
    class="inputCarroceria"
    id="carroceria"
    type="text"
    placeholder="Selecione"
    matInput
    [formControl]="getFormControl(FormNames.CARROCERIA)"
    [matAutocomplete]="autoCarroceria"
    (change)="limpaCampo($event.target)"
  />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabCarroceria()">
    <mat-icon>edit</mat-icon>
  </button>
  <mat-autocomplete #autoCarroceria="matAutocomplete" (optionSelected)="selectCarroceria($event)"
    [displayWith]="optionTextNomeComprimido">
    <mat-option #optionCampoMenorCarroceria *ngFor="let carroceria of carroceriasFiltradas | async"
      [value]="carroceria">
      {{ carroceria.nomeComprimido }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field *ngIf="!visualizacao" class="s-100 mt-5" appearance="outline">
  <mat-label>Cor</mat-label>
  <input
    class="inputCor"
    id="cor"
    type="text"
    placeholder="Selecione"
    matInput
    [formControl]="getFormControl(FormNames.COR)"
    [matAutocomplete]="autoCor"
    (change)="limpaCampo($event.target)"
  />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabCor()">
    <mat-icon>edit</mat-icon>
  </button>
  <mat-autocomplete #autoCor="matAutocomplete" (optionSelected)="selectCor($event)" [displayWith]="optionText">
    <mat-option #optionCampoMenorCor *ngFor="let cor of corFiltradas | async" [value]="cor">
      {{ cor.descricao }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Número do Motor</mat-label>
  <input id="numeroMotor" matInput type="text" (keypress)="omit_special_char($event)"
    onkeyup="this.value = this.value.toUpperCase()" [formControl]="getFormControl(FormNames.NUMERO_MOTOR)"
    [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabNumeroMotor()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field *ngIf="!visualizacao" class="s-100 mt-5" appearance="outline">
  <mat-label>Combustível</mat-label>
  <input
    class="inputCombustivel"
    id="combustivel"
    type="text"
    placeholder="Selecione"
    matInput
    [formControl]="getFormControl(FormNames.TIPO_COMBUSTIVEL)"
    [matAutocomplete]="autoTiposCombustivel"
    (change)="limpaCampo($event.target)"
  />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabCombustivel()">
    <mat-icon>edit</mat-icon>
  </button>
  <mat-autocomplete #autoTiposCombustivel="matAutocomplete" (optionSelected)="selectTipoCombustivel($event)"
    [displayWith]="optionTextNomeComprimido">
    <mat-option #optionCampoMenorCombustivel *ngFor="let tipo of tipoCombustivelFiltradas | async" [value]="tipo">
      {{ tipo.nomeComprimido }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Capacidade de Passageiros</mat-label>
  <input id="capacidadePassageiros" matInput type="number"
    [formControl]="getFormControl(FormNames.CAPACIDADE_PASSAGEIROS)" [textMask]="{ mask: false }" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabCapacidadePassageiros()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Eixos</mat-label>
  <input id="numeroEixos" matInput type="number" [formControl]="getFormControl(FormNames.NUMERO_EIXOS)"
    [textMask]="{mask: false}" />
  <button mat-icon-button matSuffix type="button" (click)="habOrDesabNumeroEixos()">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="s-100 mt-5" appearance="outline">
  <mat-label>Hodômetro</mat-label>
  <form>
    <input matInput type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
      [formControl]="getFormControl(FormNames.HODOMETRO)" [textMask]="{ mask: false }" />
  </form>
</mat-form-field>