import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import TipoVeiculo from "src/models/tipo-veiculo";

@Injectable({
  providedIn: 'root'
})
export class TipoVeiculoService {
  private readonly RESOURCE = '/tipo-veiculo';

  constructor(private http: HttpClient) { }

  list(): Observable<TipoVeiculo[]> {
    return this.http.get<TipoVeiculo[]>(this.RESOURCE).pipe(take(1));
  }
}
