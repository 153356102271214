import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import ItemVistoria from "src/models/item-vistoria.model";

@Injectable({
  providedIn: 'root'
})
export class ItemVistoriaService {
  private readonly RESOURCE = '/item-vistoria';

  constructor(private http: HttpClient) { }

  list(): Observable<ItemVistoria[]> {
    return this.http.get<ItemVistoria[]>(this.RESOURCE).pipe(take(1));;
  }

  getMedia(idVistoria: number, idMedia:number, tipo: number){
    return this.http.get(`${this.RESOURCE}/media/${idVistoria}/${idMedia}/${tipo}`, { responseType: 'blob' }).pipe(take(1));
 }

}
