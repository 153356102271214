import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenString = localStorage.getItem('accessToken');

    if(tokenString && !req.url.endsWith('oauth/token')){
      const token = JSON.parse(tokenString);
      req = req.clone({
        setHeaders:{
          Authorization: 'Bearer ' + token.access_token
        }
      });
    }

    return next.handle(req);
  }

}
