<mat-toolbar color="primary">
  <a matRipple [matRippleUnbounded]="true" [routerLink]="'/home'" class="material-icons">
    <mat-icon>keyboard_backspace</mat-icon>
  </a>
  <h1>Listagem de Vistoria</h1>
</mat-toolbar>
<div class="container"
     infinite-scroll
     [infiniteScrollDistance]="distance"
     [infiniteScrollThrottle]="throttle"
     (scrolled)="onScroll()">
  <mat-card class="mb-10" matRipple *ngFor="let vistoria of vistorias" routerLink="visualizar/{{vistoria.vistoriaId}}">
    <mat-grid-list cols="4" rowHeight="4em">
      <mat-grid-tile>
        <span>{{vistoria.data | date: 'dd/MM/yyy HH:mm:ss'}}</span>
      </mat-grid-tile>
      <mat-grid-tile>
        <span>{{ vistoria.placa ? vistoria.placa : "SEM PLACA"}}</span>
    </mat-grid-tile>
      <mat-grid-tile>
        <span [class]="corPorSituacao(vistoria.idSituacao)">{{vistoria.situacao}}</span>
      </mat-grid-tile>
      <mat-grid-tile>
        <span [class]="corPorSituacaoBackOffice(vistoria.idSituacaoBackOffice)">{{vistoria.situacaoBackOffice}}</span>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card>
</div>
<button class="bottom-center-fab" mat-fab color="primary" (click)="openFilterSheet()">
  <mat-icon>search</mat-icon>
</button>
