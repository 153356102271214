export interface Coords {
  latitude: number;
  longitude: number;
}

const getCurrentLocation = (): Promise<Coords> =>  {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      resolve({ latitude, longitude });
    },
    err => {
      reject(err.code);
    },
    { enableHighAccuracy: true, timeout: 30000});
  });
};

// const getCurrentLocation = (): Promise<Coords> => {
//   return new Promise((resolve, reject) => {
//     resolve({ latitude: 0, longitude: 0 });
//   });
// }

export default getCurrentLocation;
