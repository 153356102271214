const formatSingleDigit = (stringDate: number): string => {
  return ('0' + stringDate).slice(-2);
}

const formatDateString = (dateString: Date | string = '') => {
  if (dateString === null) {
    return '--/--/----';
  } else {
    const date = new Date(dateString);

    const day = formatSingleDigit(date.getDate());
    const month = formatSingleDigit(date.getMonth());
    const year = date.getFullYear();
    const hour = formatSingleDigit(date.getHours());
    const minutes = formatSingleDigit(date.getMinutes());
    const seconds = formatSingleDigit(date.getSeconds());

    return `${day}/${month}/${year} - ${hour}:${minutes}:${seconds}`;
  }
};

export default formatDateString;
