import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';
import Apontamento from 'src/models/apontamento.model';
import { DadosApontamento } from 'src/models/dados-apontamento.model';
import ItemVistoriado from 'src/models/dto/item-vistoriado.dto';
import CORES_TIPO_APONTAMENTO from 'src/models/enums/CoresTipoApontamento.enum';
import TIPO_APONTAMENTO from 'src/models/enums/TipoApontamento.enum';
import { ApontamentoService } from '../services/apontamento.service';
import { ItemVistoriaService } from '../services/item-vistoria.service';

interface FlatNode {
  expansivel: boolean;
  apontamento: Apontamento;
  nivel: number;
}

@Component({
  selector: 'app-apontamento-vistoriador-arvore',
  templateUrl: './apontamento-vistoriador-arvore.component.html',
  styleUrls: ['./apontamento-vistoriador-arvore.component.css']
})
export class ApontamentoVistoriadorArvoreComponent implements OnInit {
  @Output() hasChanged = new EventEmitter();
  @Input() itensVistoriados: ItemVistoriado[];
  @Input() visualizacao: boolean;
  @Input() control: FormControl;

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.nivel, node => node.expansivel
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.nivel,
    node => node.expansivel,
    node => node.apontamentosFilhos
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  termoPesquisa = new FormControl('');
  searchTimer!: any;
  isSearching = false;
  treeBlocked = false;

  selected: Apontamento[];
  data: DadosApontamento[];

  constructor(private apontamentoService: ApontamentoService, private toastr: ToastrService, private itemVistoriaService: ItemVistoriaService) {
    this.selected = [];
    this.data = [];
    this.itensVistoriados = [];
    this.visualizacao = false;
    this.control = new FormControl();
  }

  ngOnInit(): void {
    this.apontamentoService.arvore('').subscribe(data => {
      this.dataSource.data = data;
    });

    this.itemVistoriaService.list().subscribe(data => {
      for (const itemVistoria of data) {
        const item: ItemVistoriado = {
          selecionado: 1,
          idItemVistoria: itemVistoria.id,
          nome: itemVistoria.nome,
          ativoFoto: itemVistoria.ativoFoto,
          ativoVideo: itemVistoria.ativoVideo
        };
      }
    })
  }

  onCheckBoxFlagged(value: MatCheckboxChange, divRef: HTMLSpanElement, node: FlatNode): void {
    if (value.checked) {
      this.pushValue(divRef, node);
    } else {
      this.spliceValue(divRef, node);
    }
    this.hasChanged.emit();
  }

  isChecked(node: FlatNode) {
    const findApontamento = this.selected.find(apontamento => apontamento.id == node.apontamento.id);
    return findApontamento ? true : false;
  }

  pushValue(divRef: HTMLSpanElement, node: FlatNode) {
    divRef.className = 'font-bold';
    const findApontamento = this.selected.find(apontamento => apontamento.id == node.apontamento.id);
    if (findApontamento) return;
    this.selected.push(node.apontamento);
    this.data.push({
      codigo: `${node.apontamento.id}`,
      descricao: node.apontamento.descricao,
      situacao: node.apontamento.idDominioTipo ? node.apontamento.idDominioTipo : 0
    });

    this.treeBlocked = this.blockTree();
    if (this.treeBlocked) {
      this.toastr.info('35 apontamentos selecionados.', 'Limite de apontamentos atingido!', {
        positionClass: 'toast-top-center'
      });
    }
  }

  spliceValue(divRef: HTMLSpanElement, node: FlatNode) {
    divRef.className = '';

    let index = this.selected.findIndex(a => a.id === node.apontamento.id);
    this.selected.splice(index, 1);

    index = this.data.findIndex(a => +a.codigo === node.apontamento.id);
    this.data.splice(index, 1);

    this.treeBlocked = this.blockTree();
  }

  corDoCirculo(tipoApontamento: TIPO_APONTAMENTO): string {
    switch (tipoApontamento) {
      case TIPO_APONTAMENTO.REPROVADO:
        return CORES_TIPO_APONTAMENTO.REPROVADO;
      case TIPO_APONTAMENTO.SUSPEITA_DE_ADULTERACAO:
        return CORES_TIPO_APONTAMENTO.SUSPEITA_DE_ADULTERACAO;
      case TIPO_APONTAMENTO.COM_APONTAMENTOS:
        return CORES_TIPO_APONTAMENTO.COM_APONTAMENTOS;
      case TIPO_APONTAMENTO.SEM_ADULTERACAO:
        return CORES_TIPO_APONTAMENTO.SEM_ADULTERACAO;
      case TIPO_APONTAMENTO.NAO_APLICAVEL:
        return CORES_TIPO_APONTAMENTO.NAO_APLICAVEL
      default:
        return CORES_TIPO_APONTAMENTO.NAO_APLICAVEL;
    }
  }

  onPesquisaApontamentoTyped(keyEvent: any): void {
    if (this.termoPesquisa.value.length + 1 < 3) {
      return;
    }

    if (this.searchTimer != null) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.apontamentoService.arvore(`${this.termoPesquisa.value}`).subscribe(data => {
        this.dataSource.data = data;
      });
    }, 800);
  }

  hasChild = (_: number, node: FlatNode) => node.expansivel;

  blockTree = () => this.selected.length === 35;

  private transformer(apontamento: Apontamento, nivel: number): FlatNode {
    return {
      expansivel: !!apontamento.apontamentosFilhos,
      apontamento,
      nivel,
    };
  }
}
