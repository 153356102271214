export class VeiculoDetran{

anofab!: number;
anomod!: number;
capCarga!: string;
cappass!: number;
carroc!: string;
chassi!: string;
etaVis!: string;
cilindrada!: string;
cmt!: number;
combveic!: string;
cor!: string;
espveic!: string;
marcamod!: string;
nmotor!: string;
numdoc!: string;
pbt!: number;
potencia!: string;
renavam!: string;
tpveic!: string;
messageRetorno!: String;
codigoRetorno!: number;
generico = 'nao tem';
nmprop!: string;
combVeic!: string;
capPass!: string;
eixo!: number;
}
