<form (submit)="login()" [formGroup]="formLogin">
  <mat-card class="login-card">
    <mat-card-title class="align-center" color="primary">Login</mat-card-title>
    <mat-card-content>
        <mat-divider></mat-divider>
        <mat-form-field appearance="fill" class="full-width mt-10">
            <mat-label>Usuário</mat-label>
            <input matInput type="text" formControlName="username">
            <button mat-button *ngIf="fControls.user" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearInputUser()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Senha</mat-label>
            <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hidePass">
                <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <button mat-raised-button color="primary" class="full-width">Iniciar</button>
        <!-- <app-file-input [isVideo]="false"></app-file-input> -->
        <span class="version-app">v11.0.0</span>
    </mat-card-content>
  </mat-card>
</form>


