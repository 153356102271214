import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth.guard';
import { VistoriaCriarComponent } from './criar/vistoria-criar.component';
import { VistoriaListComponent } from './list/vistoria-list.component';
import { VistoriaViewComponent } from './view/vistoria-view.component';

const routes = [
  {
    path: 'vistoria',
    children: [
      { path: '', redirectTo: 'vistoria', pathMatch: 'full' },
      { path: '', component: VistoriaListComponent },
      { path: 'visualizar/:id', component: VistoriaViewComponent },
      { path: 'criar', component: VistoriaCriarComponent }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VistoriaRoutingModule { }
