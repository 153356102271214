import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { SnackBarServiceService } from '../messages/snack-bar-service.service';
import { ChaveDispositivoService } from '../services/chave-dispositivo.service';
import { Location } from '@angular/common';
import getCurrentLocation from 'src/utils/getCurrentLocation';
import { LatLongUsuarioDTO } from 'src/models/dto/latLongUsuario.dto';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formLogin: FormGroup;
  hidePass = true;
  private initialPageUrl!: string;
  load: boolean = true

  latitude!: string;
  longitude!: string;

  constructor(private fb: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService,
    private authService: AuthService,
    private location: Location
  ) {

    this.formLogin = this.fb.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {

  }


  async login(): Promise<void> {
    if (this.formLogin.invalid) {
      this._snackBar.open('Usuário e senha são obrigatórios', 'Ok', {
        verticalPosition: 'top',
        duration: 2500
      });
      return;
    }

    try {
      if (!isChrome()) {
        alert('Este aplicativo só é compatível com o Google Chrome.');
        return;
      }
      if (navigator.geolocation) {
        const { latitude, longitude } = await getCurrentLocation();
        localStorage.setItem('latitude', latitude.toString());
        localStorage.setItem('longitude', longitude.toString());
      }
      this.authService.login(this.formLogin.value).subscribe(async response => {
        const accessToken = JSON.stringify(response);
        localStorage.setItem('accessToken', accessToken);
        this.authService.authorize();

        const load = await this.authService.loadProfile();
        console.log('esse é o load', load)
        if (load) {
          this.atualizarOuCadastrarLocUsuario();
          var codigoPerfil = parseInt(localStorage.getItem('codigoPerfil') || '');
          if (codigoPerfil !== 7 && codigoPerfil !== 999 && codigoPerfil !== 998) {
            this.toastr.info('Seu usuário não tem permissão para fazer vistoria')
            return;
          }
          
          await this.router.navigate(['/home']);
        }
      }
        , errorResponse => {
          if (errorResponse.error.error === 'invalid_grant') {
            // this.toastr.info('Usuário e/ou senha inválidos' + ': ' + errorResponse.error.error_description, 'Aviso');
            this.toastr.info('Usuário e/ou senha inválidos', 'Aviso');

          }
          else {
            // this.toastr.error('Não foi possível realizar o login' + ': ' + errorResponse.error.error_description, 'Erro');
            this.toastr.error('Não foi possível realizar o login', 'Erro');

          }
        });
      // setTimeout(() => {
      //   this.atualizarOuCadastrarLocUsuario();
      // }, 5000);
    }
    catch (error) {
      this.toastr.error('Não foi possível encontrar sua localização.', 'Erro', {
        positionClass: 'toast-top-center',
      });
    }
  }

  async atualizarOuCadastrarLocUsuario(): Promise<void> {
    let latitudeUsuario = localStorage.getItem('latitude')
    if (latitudeUsuario === null) {
      latitudeUsuario = ''; // Valor padrão caso seja nulo
    }

    let longitudeUsuario = localStorage.getItem('longitude')
    if (longitudeUsuario === null) {
      longitudeUsuario = ''; // Valor padrão caso seja nulo
    }

    let loginUsuario = localStorage.getItem('login');
    if (loginUsuario === null) {
      loginUsuario = ''; // Valor padrão caso seja nulo
    }

    const montaBody: LatLongUsuarioDTO = {
      login: loginUsuario,
      latitude: parseFloat(latitudeUsuario),
      longitude: parseFloat(longitudeUsuario)
    }; this.authService.atualizarOuCadastrarLocUsuario(montaBody).subscribe(data => {

    }, (err) => {
      this.toastr.error('Erro ao cadastrar localização', 'Ocorreu um erro interno, tente novamente mais tarde ', {
        positionClass: 'toast-top-center'
      });

      console.log('deu ruim', JSON.stringify(err));

    });
  }

  clearInputUser(): void {
    this.formLogin.patchValue({
      user: ''
    })
  }

  get fControls() {
    return this.formLogin.controls;
  }
}

function isChrome(): boolean {
  const userAgent = navigator.userAgent;
  const isChromeAgent = /Chrome/i.test(userAgent);
  const isEdgeAgent = /Edg/i.test(userAgent);
  const isOperaAgent = /OPR/i.test(userAgent); // Opera também é baseado no Chromium
  const isFirefoxAgent = /Firefox/i.test(userAgent);
  const isSamsungBrowserAgent = /SamsungBrowser/i.test(userAgent);
  const isSafeBrowserAgent = /4.0 Chrome/i.test(userAgent);
  return isChromeAgent && !isEdgeAgent && !isOperaAgent && !isFirefoxAgent && !isSamsungBrowserAgent && !isSafeBrowserAgent;
}
