import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import Arquivo from 'src/models/arquivo.model';
import InformacaoArquivo from 'src/models/dto/informacao-arquivo.dto';
import ItemVistoriado from 'src/models/dto/item-vistoriado.dto';
import InputFileEventEmitterProps from 'src/types/InputFileEventEmitterProps';
import formatDateString from 'src/utils/formatDateString';
import {
  DialogResponseProps,
  DialogSetDescriptionComponent
} from '../dialog-set-description/dialog-set-description.component';
import { Coords } from './../../utils/getCurrentLocation';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.css']
})
export class ImageCardComponent implements OnInit, AfterViewInit {
  @Input() imageUrl?: string | ArrayBuffer | null | SafeUrl;
  @Input() deleteButton = true;
  @Input() buttonText = 'Nova imagem';
  @Input() title?: string;
  @Input() isVideo: boolean = false;
  @Input() imagem?: InputFileEventEmitterProps;
  @Input() inputFile?: boolean = true;
  @Input() ativoFoto?: boolean;
  @Input() ativoVideo?: boolean;
  @Input() id?: number;
  @Input() localizacaoLat?: Coords | string | number;
  @Input() localizacaoLong?: Coords | string | number;

  imageDate?: string;

  @Output() onChange = new EventEmitter<InputFileEventEmitterProps>();
  @Output() onDelete = new EventEmitter();
  @ViewChild('video') video!: ElementRef;

  isSubmitting: boolean;
  fotosObrigatorias: ItemVistoriado[];
  videosObrigatorios: ItemVistoriado[];

  arquivos: Arquivo[];

  informacaoFotos: InformacaoArquivo[];
  informacaoAgregados: InformacaoArquivo[];
  informacaoVideos: InformacaoArquivo[];

  showCropper = false;
  isImageToBeChanged = false;

  constructor(private dialog: MatDialog) {
    this.isSubmitting = false;
    this.fotosObrigatorias = [];
    this.videosObrigatorios = [];

    this.arquivos = [];
    this.informacaoFotos = [];
    this.informacaoAgregados = [];
    this.informacaoVideos = [];
  }

  ngOnInit(): void {
    if (this.imagem) {
      this.imageUrl = this.imagem.url;
      // console.log(this.imagem);

      this.imageDate = formatDateString(this.imagem.date);
      this.localizacaoLat = this.imagem.location.latitude;
      this.localizacaoLong = this.imagem.location.longitude;
      // console.log(this.localizacaoLat)
      // console.log(this.localizacaoLong)
    }
  }

  ngAfterViewInit(): void {
    if (this.isVideo) {
      this.video.nativeElement.load();
    }
  }

  handleChangeImage(inputFile: InputFileEventEmitterProps) {
    this.imageUrl = inputFile.url;
    this.onChange.emit(inputFile);
    this.imageDate = formatDateString(inputFile.date);
  }

  generateRandomId(): number {
    return Math.floor(Math.random() * 1000000);
  }

  handleDeleteImage() {
    this.imageUrl = undefined;
    this.deleteButton = false;
    this.imageDate = undefined;
    this.onDelete.emit();
    this.isImageToBeChanged = true;
  }

  novoArquivoObrigatorio(inputFile: InputFileEventEmitterProps): void {
    if (inputFile.file.type.includes('video')) {
      this.novoVideo(inputFile);
    } else {
      this.novaImagem(inputFile);
    }
  }

  removerArquivoObrigatorio(id: number, isVideo: boolean): void {
    if (isVideo) {
      this.removerVideo(id);
    } else {
      this.removerImagem(id);
    }
  }

  novoItemAgregado(inputFile: InputFileEventEmitterProps): void {
    const id = this.idItemAgregado();
    const dialogRef = this.dialog.open<DialogSetDescriptionComponent, any, DialogResponseProps>(
      DialogSetDescriptionComponent,
      {
        width: '80%',
        maxWidth: '400px'
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm && result?.value) {
        this.arquivos.push({
          id: id,
          inputFile: inputFile
        });

        this.informacaoAgregados.push({
          id: id,
          dataHora: inputFile.date,
          descricao: result.value,
          idItemVistoriado: id,
          latitude: inputFile.location.latitude,
          longitude: inputFile.location.longitude,
          mediaSizeOriginalBytes: inputFile.file.size
        });
      }
    });
  }

  removerItemAgregado(id: number): void {
    const index = this.arquivos.findIndex((a) => a.id === id);
    this.arquivos.splice(index, 1);

    const indexInfo = this.informacaoAgregados.findIndex((a) => a.id === id);
    this.informacaoAgregados.splice(indexInfo, 1);
  }

  getArquivoAgregado(id: number): string | ArrayBuffer | undefined | null | SafeUrl {
    const index = this.arquivos.findIndex((a) => a.id === id);
    return this.arquivos[index].inputFile.url;
  }

  private idItemAgregado = (): number =>
    this.informacaoAgregados.length === 0
      ? -1
      : this.informacaoAgregados[this.informacaoAgregados.length - 1].id - 1;

  private novoVideo(inputFile: InputFileEventEmitterProps): void {
    const id = +inputFile.file.name.split('.')[0];
    this.arquivos.push({
      id: id,
      inputFile: inputFile
    });
    this.informacaoVideos.push({
      id: id,
      dataHora: inputFile.date,
      descricao: '',
      idItemVistoriado: id,
      latitude: inputFile.location.latitude,
      longitude: inputFile.location.longitude,
      mediaSizeOriginalBytes: inputFile.file.size
    });

    this.mudaSituacaoVideo(id, 1);
  }

  private removerVideo(id: number): void {
    const indexArquivo = this.arquivos.findIndex((v) => v.id === id);
    this.arquivos.splice(indexArquivo, 1);

    const indexInfo = this.informacaoVideos.findIndex((v) => v.id === id);
    this.informacaoVideos.splice(indexInfo, 1);

    this.mudaSituacaoVideo(id, 0);
  }

  private mudaSituacaoVideo = (id: number, selecionado: number) =>
    (this.videosObrigatorios = this.videosObrigatorios.map((v) => {
      if (v.idItemVistoria === id) {
        v.selecionado = selecionado = 1;
      }

      return v;
    }));

  private novaImagem(inputFile: InputFileEventEmitterProps): void {
    const id = +inputFile.file.name.split('.')[0];
    this.arquivos.push({
      id: id,
      inputFile: inputFile
    });
    this.informacaoFotos.push({
      id: id,
      dataHora: inputFile.date,
      descricao: '',
      idItemVistoriado: id,
      latitude: inputFile.location.latitude,
      longitude: inputFile.location.longitude,
      mediaSizeOriginalBytes: inputFile.file.size
    });

    this.mudaSituacaoImagem(id, 1);
  }

  private removerImagem(id: number): void {
    const indexArquivo = this.arquivos.findIndex((v) => v.id === id);
    this.arquivos.splice(indexArquivo, 1);

    const indexInfo = this.informacaoFotos.findIndex((v) => v.id === id);
    this.informacaoFotos.splice(indexInfo, 1);

    this.mudaSituacaoImagem(id, 0);
  }

  private mudaSituacaoImagem = (id: number, selecionado: number) =>
    (this.fotosObrigatorias = this.fotosObrigatorias.map((v) => {
      if (v.idItemVistoria === id) {
        v.selecionado = selecionado = 1;
      }

      return v;
    }));
}
