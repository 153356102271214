import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlider } from '@angular/material/slider';
import { CameraType } from '../decisao-camera/decisao-camera.component';
type PopUpCameraProps = { cameraType: CameraType };

@Component({
  selector: 'app-pop-up-camera',
  templateUrl: './pop-up-camera.component.html',
  styleUrls: ['./pop-up-camera.component.css']
})
export class PopUpCameraComponent implements OnInit, OnDestroy {
  @ViewChild('videoPreview') videoPreview?: ElementRef<HTMLVideoElement>;
  @ViewChild('sliderZoom') sliderZoom?: ElementRef<MatSlider>;
  @ViewChild('sliderFocus') sliderFocus?: ElementRef<MatSlider>;
  @ViewChild('canvasVideo') canvasVideo?: ElementRef<HTMLCanvasElement>;
  @ViewChild('photoPreview') photoPreview?: ElementRef<HTMLImageElement>;
  streamCamera?: MediaStream;
  isPhotoPreviewActive = false;
  type: 'user' | 'environment' = 'environment';
  photo: any = null;
  hasZoom = true;

  minZoom = 0;
  maxZoom = 1;
  stepZoom = 0.1;
  valueZoom = 0;

  devices: any = '';

  constructor(
    private dialogRef: MatDialogRef<PopUpCameraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopUpCameraProps
  ) {}

  ngOnDestroy(): void {
    this.stopVideoStream();
    screen.orientation.removeEventListener('change', this.listenerOrientation);
  }

  ngOnInit(): void {
    this.isPhotoPreviewActive = false;
    this.startCamera();

    if (screen.orientation) {
      screen.orientation.addEventListener('change', this.listenerOrientation);
    }

    const subs = this.dialogRef.afterClosed().subscribe(() => {
      this.stopVideoStream();
      subs.unsubscribe();
    });
  }

  listenerOrientation(){
    this.startCamera();
  }

  takePhoto() {
    if (!this.canvasVideo?.nativeElement) return;
    if (!this.videoPreview?.nativeElement) return;
    if (!this.photoPreview?.nativeElement) return;

    this.isPhotoPreviewActive = true;
    const videoElem = this.videoPreview.nativeElement;

    this.canvasVideo.nativeElement.width = videoElem.videoWidth;
    this.canvasVideo.nativeElement.height = videoElem.videoHeight;

    const context = this.canvasVideo.nativeElement.getContext('2d');
    if (context != null) {
      context.drawImage(videoElem, 0, 0);
      this.canvasVideo.nativeElement.toBlob(
        (blob) => {
          if (!blob) return;

          this.photo = blob;
          const urlPhoto = URL.createObjectURL(blob);

          if (this.photoPreview?.nativeElement) {
            this.photoPreview.nativeElement.src = urlPhoto;
          }
        },
        'image/jpeg',
        1
      );
    }
  }

  stopVideoStream() {
    if (this.streamCamera) {
      this.streamCamera.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  close() {
    this.stopVideoStream();
    this.dialogRef.close({ confirm: false });
  }

  repeatPhoto() {
    if (this.photoPreview?.nativeElement) {
      this.photoPreview.nativeElement.src = '';
    }
    this.isPhotoPreviewActive = false;
  }

  increaseZoom(value: any) {
    if (value == null) return;
    if (!this.streamCamera) return;

    const [track] = this.streamCamera.getVideoTracks();
    track
      .applyConstraints({ advanced: [{ zoom: value }] } as any)
      .catch((err) => console.log('deu ruim no zoom' + err.toString()));
  }

  savePhoto() {
    this.stopVideoStream();
    this.dialogRef.close({ confirm: true, photo: this.photo });
  }

  toggleCamera() {
    if (this.type == 'environment') {
      this.type = 'user';
    } else {
      this.type = 'environment';
    }

    this.startCamera();
  }

  async startCamera() {

    this.stopVideoStream();
    if (navigator.mediaDevices) {
      const devices  = await navigator.mediaDevices.enumerateDevices();
      const idealDevice = devices.find((device) => device.label == 'camera2 0, facing back');

      this.initCamera(this.type, idealDevice)
        .then((stream) => {
          try {
            if (this.videoPreview) {
              this.videoPreview.nativeElement.srcObject = stream;
              this.streamCamera = stream;
            }

            const [track] = stream.getVideoTracks();
            const capabilities: any = track.getCapabilities();
            const settings: any = track.getSettings();
            this.hasZoom = 'zoom' in settings;

            this.minZoom = capabilities.zoom.min;
            this.maxZoom = capabilities.zoom.max;
            this.stepZoom = capabilities.zoom.step;
            this.valueZoom = settings.zoom;

          } catch (error) {
            console.log('deu ruim em alguma coisa aqui' + (error as Error).toString());
          }
        })
        .catch((err) => {
          console.log('erro ao iniciar a camera' + err.toString());
        });
    } else {
      alert('Seu navegador não suporta camera');
    }
  }

  initCamera(type: 'user' | 'environment', device?: MediaDeviceInfo) {
    const isLandscape = screen.orientation.type.includes('landscape');

    const configLandscape: MediaTrackConstraints = {
      aspectRatio: 3 / 4,
      width: {
        max: 1980,
        ideal: 1024,
        min: 1024
      },
      height: {
        max: 1080,
        ideal: 768,
        min: 768
      }
    };

    const configPortrait: MediaTrackConstraints = {
      aspectRatio: 3 / 4,
      width: {
        max: 1080,
        ideal: 768,
        min: 768
      },
      height: {
        max: 1980,
        ideal: 1024,
        min: 1024
      }
    };

    const config = isLandscape ? configLandscape : configPortrait;

    return navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: device?.deviceId,
        facingMode: type,
        zoom: true,
        focusMode: true,
        ...config
      } as any
    });
  }
}
