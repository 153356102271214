import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TextMaskModule } from 'angular2-text-mask';
import { NgBrazil } from 'ng-brazil';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCardComponent } from '../image-card/image-card.component';
import { ServicesModule } from '../services/services.module';
import { DecisaoCameraComponent } from './decisao-camera/decisao-camera.component';
import { FileInputCardComponent } from './file-input-card/file-input-card.component';
import { PopUpBoroscopioComponent } from './pop-up-boroscopio/pop-up-boroscopio.component';
import { PopUpCameraComponent } from './pop-up-camera/pop-up-camera.component';
import { PopUpCropperComponent } from './pop-up-cropper/pop-up-cropper.component';
import { PopUpVideoComponent } from './pop-up-video/pop-up-video.component';

@NgModule({
  declarations: [
    ImageCardComponent,
    FileInputCardComponent,
    PopUpCropperComponent,
    PopUpVideoComponent,
    PopUpCameraComponent,
    DecisaoCameraComponent,
    PopUpBoroscopioComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    ImageCropperModule,
    MatSliderModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ServicesModule,
    MatInputModule,
    MatRadioModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    TextMaskModule,
    NgBrazil,
    MatRippleModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatListModule,
    MatAutocompleteModule,
    ImageCardComponent,
    FileInputCardComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }
