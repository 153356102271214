import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  showNav = false;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void { }

  async logout() {
    await this.authService.logout();
    this.router.navigate(['/login']);
  }
}
