import { Veiculo } from './../../../../models/veiculo.model';
import { ChangeDetectorRef, EventEmitter, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { placa } from 'ng-brazil/placa/validator';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CarroceriaService } from 'src/app/services/carroceria.service';
import { DominioService } from 'src/app/services/dominio.service';
import { VistoriaService } from 'src/app/services/vistoria.service';
import Carroceria from 'src/models/carroceria.model';
import Dominio from 'src/models/dominio.model';
import { VISTORIA_FORM_NAMES } from 'src/models/enums/vistoria-form-names.enum';
import { VeiculoDetran } from 'src/models/veiculo-detran';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { ToastrService } from 'ngx-toastr';
import { EspecieService } from 'src/app/services/especie.service';
import { TipoVeiculoService } from 'src/app/services/tipo-veiculo.service';
import { TipoCombustivelService } from 'src/app/services/tipo-combustivel.service';
import { TipoCombustivel } from 'src/models/tipo-combustivel';
import TipoVeiculo from 'src/models/tipo-veiculo';
import DominioComprimido from 'src/models/dominio-comprimido';
import { Cor } from 'src/models/cor-model';
import { CorService } from 'src/app/services/cor.service';
import { Modelo } from 'src/models/modelo-model';
import { ModeloService } from 'src/app/services/modelo.service';
import validaCpfCnpj from 'src/utils/validaCpfCnpj';

@Component({
  selector: 'app-informacoes-veiculo',
  templateUrl: './vistoria-informacoes-veiculo.component.html',
  styleUrls: ['./vistoria-informacoes-veiculo.component.css']
})
export class VistoriaInformacoesVeiculoComponent implements OnInit, OnChanges {
  @Input() ocultarBotao: boolean = false;

  @Output() dadosVeiculoDetranEvento: EventEmitter<VeiculoDetran> = new EventEmitter<VeiculoDetran>();

  @Input() visualizacao: boolean;

  @Output() integracao = new EventEmitter();

  @ViewChild('optionCampoMenorTipoVeiculo') optionCampoMenorTipoVeiculo: MatOption | undefined;
  @ViewChild('optionCampoMenorModelo') optionCampoMenorModelo: MatOption | undefined;
  @ViewChild('optionCampoMenorEspecie') optionCampoMenorEspecie: MatOption | undefined;

  @ViewChild('optionCampoMenorCarroceria') optionCampoMenorCarroceria: MatOption | undefined;

  @ViewChild('optionCampoMenorCor') optionCampoMenorCor: MatOption | undefined;

  @ViewChild('optionCampoMenorCombustivel') optionCampoMenorCombustivel: MatOption | undefined;

  @ViewChild('FormNames.TIPO_VEICULO') campoDeEntrada: FormControl | undefined;

  // getFormControl(FormNames.TIPO_VEICULO)

  isLoading = false;
  form!: FormGroup;
  cmt!: FormControl;
  pbt!: FormControl;

  carrocerias: Carroceria[];
  carroceriasFiltradas!: Observable<Carroceria[]>;
  carroceriaSelecionada!: number;

  especies: Dominio[];
  especiesFiltradas!: Observable<Dominio[]>;
  especieSelecionada!: number;

  tiposCombustivel: TipoCombustivel[];
  selectTiposCombustivelSelecionada!: number;
  tipoCombustivelFiltradas!: Observable<TipoCombustivel[]>;

  tiposVeiculo: TipoVeiculo[];
  selectTiposVeiculoSelecionada!: number;
  tipoVeiculoFiltradas!: Observable<TipoVeiculo[]>;

  marca: Dominio[];
  selectMarcaSelecionada!: number;
  marcaFiltradas!: Observable<Dominio[]>;

  modelo: Modelo[];
  selectModeloSelecionada!: number;
  modeloFiltradas!: Observable<Modelo[]>;

  cores: Cor[];
  selectCorSelecionada!: number;
  corFiltradas!: Observable<Cor[]>;

  FormNames = VISTORIA_FORM_NAMES;
  mask = MASKS;
  mascaraPlacaCustomizada = [/[a-s,A-S]/, /[a-s,A-S]/, /[a-s,A-S]/, '-', /\d/, /[\d, a-s,A-S]/, /\d/, /\d/];

  placaVeiculo: String;
  dadosVeiculoDetran = VeiculoDetran;
  formReset!: FormGroup;
  primeiroEmplacamento: boolean = false;

  constructor(
    private dominioService: DominioService,
    private carroceriaService: CarroceriaService,
    private especieService: EspecieService,
    private tipoVeiculoService: TipoVeiculoService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private vistoria: VistoriaService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private tipoCombustivelService: TipoCombustivelService,
    private corService: CorService,
    private modeloService: ModeloService
  ) {
    this.visualizacao = false;
    this.tiposVeiculo = [];
    this.tiposCombustivel = [];
    this.carrocerias = [];
    this.especies = [];
    this.cores = [];
    this.marca = [];
    this.modelo = [];
    this.dadosVeiculoDetran;
    this.placaVeiculo = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges - VistoriaInformacoesVeiculoComponent');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      tipoVeiculo: [{ value: '', disabled: this.visualizacao }],
      placa: [{ value: '', disabled: this.visualizacao }],
      renavam: [{ value: '', disabled: this.visualizacao }, [NgBrazilValidators.renavam]],
      // marca: [{value: '', disabled: this.visualizacao }],
      modelo: [{ value: '', disabled: this.visualizacao }],
      cor: [{ value: null, disabled: this.visualizacao }],
      anoFabricacao: [{ value: '', disabled: this.visualizacao }],
      anoModelo: [{ value: '', disabled: this.visualizacao }],
      carroceria: [{ value: null, disabled: this.visualizacao }],
      especie: [{ value: '', disabled: this.visualizacao }],
      idTipoCombustivel: [{ value: null, disabled: this.visualizacao }],
      capacidadePassageiros: [{ value: '0', disabled: this.visualizacao }],
      potencia: [{ value: '0', disabled: this.visualizacao }],
      cilindrada: [{ value: '0', disabled: this.visualizacao }],
      capacidadeCarga: [{ value: '0', disabled: this.visualizacao }],
      chassi: [{ value: '', disabled: this.visualizacao }],
      etaVis: [{ value: '', disabled: this.visualizacao }],
      numeroMotor: [{ value: '', disabled: this.visualizacao }],
      hodometro: [{ value: '0', disabled: false }],
      cmt: [{ value: '0', disabled: this.visualizacao }],
      pbt: [{ value: '0', disabled: this.visualizacao }],
      cpfCnpj: [{ value: '', disabled: this.visualizacao }],
      nome: [{ value: '', disabled: this.visualizacao }],
      primeiroEmplacamento: [{ value: false, disabled: this.visualizacao }],
      numeroEixos:[{value:'0', disabled: this.visualizacao}]
    });

    // this.cmt = new FormControl({value: '', disabled: this.visualizacao}, Validators.required);
    // this.pbt = new FormControl({value: '', disabled: this.visualizacao}, Validators.required);

    this.tipoVeiculoService.list().subscribe((data) => (this.tiposVeiculo = data));

    // this.modeloService
    //   .modelosVeiculo()
    //   .subscribe((data) => (this.modelo = data));

    this.especiesAutocomplete();
    this.tipoCombustivelAutocomplete();
    this.tiposVeiculoAutocomplete();
    this.carroceriaAutocomplete();
    this.corAutocomplete();
    this.modeloAutocomplete();
  }

  puxarPlaca() {
    this.habAllFields();
    try {
      setTimeout(() => {
        this.isLoading = false;
      }, 10000);
      this.isLoading = true;
      if (!this.form.value.placa || !this.form.value.renavam) {
        this.toastr.warning('É necessário preencher o campo PLACA e RENAVAM', 'Erro', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.vistoria
        .getVeiculoDetranPorPlaca(this.form.value.placa, this.form.value.renavam)
        .subscribe((data) => {
          if (data.codigoRetorno == 0) {
            this.setForms(data);
            console.log(data);
            setTimeout(() => {
              this.clickFieldsSelect();
            }, 500);
            setTimeout(() => {
              this.disabledAllFields();
            }, 1000);
            this.toastr.success('Veiculo encontrado com sucesso.', 'Sucesso', {
              positionClass: 'toast-top-center'
            });
          }
          if (data.codigoRetorno != 0 && data.codigoRetorno != null) {
            // this.form.reset();
            this.toastr.warning(`${data.messageRetorno}`, 'Erro', {
              positionClass: 'toast-top-center'
            });
          }
          this.form.reset;
          if (data.codigoRetorno == null) {
            this.toastr.warning('Acesso indisponível, verifique com suporte', 'Erro', {
              positionClass: 'toast-top-center'
            });
          }
        });
    } catch (error) {
      this.toastr.warning('Erro inesperado.', 'Erro', {
        positionClass: 'toast-top-center'
      });
    }
  }

  setForms(dadosVeiculoDetran: VeiculoDetran) {
    this.form.reset;
    localStorage.removeItem('prop');
    this.form.patchValue({
      tipoVeiculo: dadosVeiculoDetran.tpveic,
      renavam: dadosVeiculoDetran.renavam,
      chassi: dadosVeiculoDetran.chassi,
      etaVis: dadosVeiculoDetran.etaVis,
      modelo: dadosVeiculoDetran.marcamod,
      anoFabricacao: dadosVeiculoDetran.anofab,
      anoModelo: dadosVeiculoDetran.anomod,
      especie: dadosVeiculoDetran.espveic,
      potencia: dadosVeiculoDetran.potencia,
      cilindrada: dadosVeiculoDetran.cilindrada,
      capacidadeCarga: dadosVeiculoDetran.capCarga,
      cmt: dadosVeiculoDetran.cmt,
      pbt: dadosVeiculoDetran.pbt,
      cpfCnpj: dadosVeiculoDetran.numdoc,
      nome: dadosVeiculoDetran.nmprop,
      carroceria: dadosVeiculoDetran.carroc,
      cor: dadosVeiculoDetran.cor,
      numeroMotor: dadosVeiculoDetran.nmotor,
      idTipoCombustivel: dadosVeiculoDetran.combVeic,
      capacidadePassageiros: dadosVeiculoDetran.capPass,
      numeroEixos: dadosVeiculoDetran.eixo
    });
    localStorage.setItem(
      'prop',
      JSON.stringify({
        cpfCnpj: validaCpfCnpj(dadosVeiculoDetran.numdoc),
        nome: dadosVeiculoDetran.nmprop,
        load: true
      })
    );
  }

  disabledAllFields() {
    this.desabTipoVeiculo();
    // this.desabRenavam();
    // this.desabChassi();
    this.desabEtaVis();
    this.desabModelo();
    this.desabAnoDeFabricacao();
    this.desabModeloDeFabricacao();
    this.desabEspecie();
    this.desabPotencia();
    this.desabCilindrada();
    this.desabCapacidadeDeCarga();
    this.desabCmt();
    this.desabPbt();
    this.desabCarroceria();
    this.desabCor();
    this.desabNumeroMotor();
    this.desabCombustivel();
    this.desabCapacidadePassageiros();
    this.desabNumeroEixos();
  }

  habAllFields() {
    this.habTipoVeiculo();
    // this.habRenavam()
    this.habChassi();
    this.habEtaVis();
    this.habModelo();
    this.habAnoDeFabricacao();
    this.habModeloDeFabricacao();
    this.habModeloDeFabricacao();
    this.habEspecie();
    this.habPotencia();
    this.habCilindrada();
    this.habCapacidadeDeCarga();
    this.habCmt();
    this.habPbt();
    this.habCarroceria();
    this.habCor();
    this.habNumeroMotor();
    this.habCombustivel();
    this.habCapacidadePassageiros();
    this.habNumeroEixos();
  }

  clickFieldsSelect() {
    this.habAllFields();
    setTimeout(() => {
      const inputTipoVeiculo = document.querySelector('.inputTipoVeiculo') as HTMLElement;
      inputTipoVeiculo.click();
      const inputModelo = document.querySelector('.inputModelo') as HTMLElement;
      inputModelo.click();
      const inputEspecie = document.querySelector('.inputEspecie') as HTMLElement;
      inputEspecie.click();

      const inputCarroceria = document.querySelector('.inputCarroceria') as HTMLElement;
      inputCarroceria.click();

      const inputCor = document.querySelector('.inputCor') as HTMLElement;
      inputCor.click();

      const inputCombustivel = document.querySelector('.inputCombustivel') as HTMLElement;
      inputCombustivel.click();
      console.log('executou p matformfield');
    }, 400);

    setTimeout(() => {
      this.optionCampoMenorTipoVeiculo!.select();
      this.optionCampoMenorModelo!.select();
      this.optionCampoMenorEspecie!.select();
      this.optionCampoMenorCarroceria!.select();
      this.optionCampoMenorCor!.select();
      this.optionCampoMenorCombustivel!.select();
      console.log('executou o campo menor');
    }, 400);
  }

  desabTipoVeiculo() {
    var inputipoVeiculo = document.querySelector('#tipoVeiculo') as HTMLInputElement;
    inputipoVeiculo!.disabled = true;
  }

  desabRenavam() {
    var inputRenavam = document.querySelector('#renavam') as HTMLInputElement;
    inputRenavam!.disabled = true;
  }

  desabChassi() {
    var inputChassi = document.querySelector('#chassi') as HTMLInputElement;
    inputChassi!.disabled = true;
  }

  desabEtaVis() {
    var inputEtaVis = document.querySelector('#etaVis') as HTMLInputElement;
    inputEtaVis!.disabled = true;
  }

  desabModelo() {
    var inputModelo = document.querySelector('#modelo') as HTMLInputElement;
    inputModelo!.disabled = true;
  }

  desabAnoDeFabricacao() {
    var inputAnoDeFabricacao = document.querySelector('#anoDeFabricacao') as HTMLInputElement;
    inputAnoDeFabricacao!.disabled = true;
  }

  desabModeloDeFabricacao() {
    var inputModeloDeFabricacao = document.querySelector('#modeloDeFabricacao') as HTMLInputElement;
    inputModeloDeFabricacao!.disabled = true;
  }

  desabEspecie() {
    var inputEspecie = document.querySelector('#especie') as HTMLInputElement;
    inputEspecie!.disabled = true;
  }

  desabPotencia() {
    var inputPotencia = document.querySelector('#potencia') as HTMLInputElement;
    inputPotencia!.disabled = true;
  }

  desabCilindrada() {
    var inputCilindrada = document.querySelector('#cilindrada') as HTMLInputElement;
    inputCilindrada!.disabled = true;
  }

  desabCapacidadeDeCarga() {
    var inputCapacidadeDeCarga = document.querySelector('#capacidadeDeCarga') as HTMLInputElement;
    inputCapacidadeDeCarga!.disabled = true;
  }

  desabCmt() {
    var inputCmt = document.querySelector('#cmt') as HTMLInputElement;
    inputCmt!.disabled = true;
  }

  desabPbt() {
    var inputPbt = document.querySelector('#pbt') as HTMLInputElement;
    inputPbt!.disabled = true;
  }

  desabCarroceria() {
    var inputCarroceria = document.querySelector('#carroceria') as HTMLInputElement;
    inputCarroceria!.disabled = true;
  }

  desabCor() {
    var inputCor = document.querySelector('#cor') as HTMLInputElement;
    inputCor!.disabled = true;
  }

  desabNumeroMotor() {
    var inputNumeroMotor = document.querySelector('#numeroMotor') as HTMLInputElement;
    inputNumeroMotor!.disabled = true;
  }

  desabCombustivel() {
    var inputCombustivel = document.querySelector('#combustivel') as HTMLInputElement;
    inputCombustivel!.disabled = true;
  }

  desabCapacidadePassageiros() {
    var inputCapacidadePassageiros = document.querySelector('#capacidadePassageiros') as HTMLInputElement;
    inputCapacidadePassageiros!.disabled = true;
  }

  desabNumeroEixos() {
    var inputNumeroEixos = document.querySelector('#numeroEixos') as HTMLInputElement;
    inputNumeroEixos!.disabled = true;
  }

  habTipoVeiculo() {
    var inputipoVeiculo = document.querySelector('#tipoVeiculo') as HTMLInputElement;
    inputipoVeiculo!.disabled = false;
  }

  habRenavam() {
    var inputRenavam = document.querySelector('#renavam') as HTMLInputElement;
    inputRenavam!.disabled = false;
  }

  habChassi() {
    var inputChassi = document.querySelector('#chassi') as HTMLInputElement;
    inputChassi!.disabled = false;
  }

  habEtaVis() {
    var inputEtaVis = document.querySelector('#etaVis') as HTMLInputElement;
    inputEtaVis!.disabled = false;
  }

  habModelo() {
    var inputModelo = document.querySelector('#modelo') as HTMLInputElement;
    inputModelo!.disabled = false;
  }

  habCarroceria() {
    var inputCarroceria = document.querySelector('#carroceria') as HTMLInputElement;
    inputCarroceria!.disabled = false;
  }

  habCor() {
    var inputCor = document.querySelector('#cor') as HTMLInputElement;
    inputCor!.disabled = false;
  }

  habNumeroMotor() {
    var inputNumeroMotor = document.querySelector('#numeroMotor') as HTMLInputElement;
    inputNumeroMotor!.disabled = false;
  }

  habCombustivel() {
    var inputCombustivel = document.querySelector('#combustivel') as HTMLInputElement;
    inputCombustivel!.disabled = false;
  }

  habCapacidadePassageiros() {
    var inputCapacidadePassageiros = document.querySelector('#capacidadePassageiros') as HTMLInputElement;
    inputCapacidadePassageiros!.disabled = false;
  }

  habNumeroEixos() {
    var inputNumeroEixos = document.querySelector('#numeroEixos') as HTMLInputElement;
    inputNumeroEixos!.disabled = false;
  }

  habAnoDeFabricacao() {
    var inputAnoDeFabricacao = document.querySelector('#anoDeFabricacao') as HTMLInputElement;
    inputAnoDeFabricacao!.disabled = false;
  }

  habModeloDeFabricacao() {
    var inputModeloDeFabricacao = document.querySelector('#modeloDeFabricacao') as HTMLInputElement;
    inputModeloDeFabricacao!.disabled = false;
  }

  habEspecie() {
    var inputEspecie = document.querySelector('#especie') as HTMLInputElement;
    inputEspecie!.disabled = false;
  }

  habPotencia() {
    var inputPotencia = document.querySelector('#potencia') as HTMLInputElement;
    inputPotencia!.disabled = false;
  }

  habCilindrada() {
    var inputCilindrada = document.querySelector('#cilindrada') as HTMLInputElement;
    inputCilindrada!.disabled = false;
  }

  habCapacidadeDeCarga() {
    var inputCapacidadeDeCarga = document.querySelector('#capacidadeDeCarga') as HTMLInputElement;
    inputCapacidadeDeCarga!.disabled = false;
  }

  habCmt() {
    var inputCmt = document.querySelector('#cmt') as HTMLInputElement;
    inputCmt!.disabled = false;
  }

  habPbt() {
    var inputPbt = document.querySelector('#pbt') as HTMLInputElement;
    inputPbt!.disabled = false;
  }

  habOrDesabTipoVeiculo() {
    var inputipoVeiculo = document.querySelector('#tipoVeiculo') as HTMLInputElement;
    if (inputipoVeiculo!.disabled == true) {
      inputipoVeiculo!.disabled = false;
    } else {
      inputipoVeiculo!.disabled = true;
    }
  }

  habOrDesabRenavam() {
    var inputRenavam = document.querySelector('#renavam') as HTMLInputElement;
    if (inputRenavam!.disabled == true) {
      inputRenavam!.disabled = false;
    } else {
      inputRenavam!.disabled = true;
    }
  }

  habOrDesabChassi() {
    var inputChassi = document.querySelector('#chassi') as HTMLInputElement;
    if (inputChassi!.disabled == true) {
      inputChassi!.disabled = false;
    } else {
      inputChassi!.disabled = true;
    }
  }

  habOrDesabEtaVis() {
    var inputEtaVis = document.querySelector('#etaVis') as HTMLInputElement;
    if (inputEtaVis!.disabled == true) {
      inputEtaVis!.disabled = false;
    } else {
      inputEtaVis!.disabled = true;
    }
  }

  habOrDesabModelo() {
    var inputModelo = document.querySelector('#modelo') as HTMLInputElement;
    if (inputModelo!.disabled == true) {
      inputModelo!.disabled = false;
    } else {
      inputModelo!.disabled = true;
    }
  }

  habOrDesabAnoDeFabricacao() {
    var inputAnoDeFabricacao = document.querySelector('#anoDeFabricacao') as HTMLInputElement;
    if (inputAnoDeFabricacao!.disabled == true) {
      inputAnoDeFabricacao!.disabled = false;
    } else {
      inputAnoDeFabricacao!.disabled = true;
    }
  }

  habOrDesabModeloDeFabricacao() {
    var inputModeloDeFabricacao = document.querySelector('#modeloDeFabricacao') as HTMLInputElement;
    if (inputModeloDeFabricacao!.disabled == true) {
      inputModeloDeFabricacao!.disabled = false;
    } else {
      inputModeloDeFabricacao!.disabled = true;
    }
  }

  habOrDesabEspecie() {
    var inputEspecie = document.querySelector('#especie') as HTMLInputElement;
    if (inputEspecie!.disabled == true) {
      inputEspecie!.disabled = false;
    } else {
      inputEspecie!.disabled = true;
    }
  }

  habOrDesabPotencia() {
    var inputPotencia = document.querySelector('#potencia') as HTMLInputElement;
    if (inputPotencia!.disabled == true) {
      inputPotencia!.disabled = false;
    } else {
      inputPotencia!.disabled = true;
    }
  }

  habOrDesabCilindrada() {
    var inputCilindrada = document.querySelector('#cilindrada') as HTMLInputElement;
    if (inputCilindrada!.disabled == true) {
      inputCilindrada!.disabled = false;
    } else {
      inputCilindrada!.disabled = true;
    }
  }

  habOrDesabCapacidadeDeCarga() {
    var inputCapacidadeDeCarga = document.querySelector('#capacidadeDeCarga') as HTMLInputElement;
    if (inputCapacidadeDeCarga!.disabled == true) {
      inputCapacidadeDeCarga!.disabled = false;
    } else {
      inputCapacidadeDeCarga!.disabled = true;
    }
  }

  habOrDesabCmt() {
    var inputCmt = document.querySelector('#cmt') as HTMLInputElement;
    if (inputCmt!.disabled == true) {
      inputCmt!.disabled = false;
    } else {
      inputCmt!.disabled = true;
    }
  }

  habOrDesabPbt() {
    var inputPbt = document.querySelector('#pbt') as HTMLInputElement;
    if (inputPbt!.disabled == true) {
      inputPbt!.disabled = false;
    } else {
      inputPbt!.disabled = true;
    }
  }

  habOrDesabCarroceria() {
    var inputCarroceria = document.querySelector('#carroceria') as HTMLInputElement;
    if (inputCarroceria!.disabled == true) {
      inputCarroceria!.disabled = false;
    } else {
      inputCarroceria!.disabled = true;
    }
  }

  habOrDesabCor() {
    var inputCor = document.querySelector('#cor') as HTMLInputElement;
    if (inputCor!.disabled == true) {
      inputCor!.disabled = false;
    } else {
      inputCor!.disabled = true;
    }
  }

  habOrDesabNumeroMotor() {
    var inputNumeroMotor = document.querySelector('#numeroMotor') as HTMLInputElement;
    if (inputNumeroMotor!.disabled == true) {
      inputNumeroMotor!.disabled = false;
    } else {
      inputNumeroMotor!.disabled = true;
    }
  }

  habOrDesabCombustivel() {
    var inputCombustivel = document.querySelector('#combustivel') as HTMLInputElement;
    if (inputCombustivel!.disabled == true) {
      inputCombustivel!.disabled = false;
    } else {
      inputCombustivel!.disabled = true;
    }
  }

  habOrDesabCapacidadePassageiros() {
    var inputCapacidadePassageiros = document.querySelector('#capacidadePassageiros') as HTMLInputElement;
    if (inputCapacidadePassageiros!.disabled == true) {
      inputCapacidadePassageiros!.disabled = false;
    } else {
      inputCapacidadePassageiros!.disabled = true;
    }
  }

  habOrDesabNumeroEixos() {
    var inputNumeroEixos = document.querySelector('#numeroEixos') as HTMLInputElement;
    if (inputNumeroEixos!.disabled == true) {
      inputNumeroEixos!.disabled = false;
    } else {
      inputNumeroEixos!.disabled = true;
    }
  }

  // consultaVeiculo(): void {
  //   if(this.form.value.placa && this.form.value.renavam && this.visualizacao === false) {
  //     this.integracao.emit();
  //   }
  // }

  selectCarroceria(event: MatAutocompleteSelectedEvent): void {
    this.carroceriaSelecionada = event.option.value;
  }

  selectEspecie(event: MatAutocompleteSelectedEvent): void {
    this.especieSelecionada = event.option.value;
  }

  selectTipoCombustivel(event: MatAutocompleteSelectedEvent): void {
    this.selectTiposCombustivelSelecionada = event.option.value;
  }

  selectTipoVeiculo(event: MatAutocompleteSelectedEvent): void {
    this.selectTiposVeiculoSelecionada = event.option.value;
  }

  selectCor(event: MatAutocompleteSelectedEvent): void {
    this.selectCorSelecionada = event.option.value;
  }

  selectMarca(event: MatAutocompleteSelectedEvent): void {
    this.selectMarcaSelecionada = event.option.value;
  }

  selectModelo(event: MatAutocompleteSelectedEvent): void {
    this.selectModeloSelecionada = event.option.value;
  }

  optionText(option: Dominio): string {
    return option ? option.descricao : '';
  }

  optionTextNomeComprimido(option: DominioComprimido): string {
    return option ? option.nomeComprimido : '';
  }

  getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  compareTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 && tipo1 == tipo2;
  }

  // abaixo precisa ser alterado.

  private carroceriaAutocomplete(): void {
    const carroceriaAutocomplete = this.form.get(VISTORIA_FORM_NAMES.CARROCERIA);
    if (carroceriaAutocomplete) {
      this.carroceriaService.list().subscribe((data) => {
        this.carrocerias = data;
        this.carroceriasFiltradas = carroceriaAutocomplete.valueChanges.pipe(
          startWith(''),
          map((value: string) =>
            this.carrocerias.filter((carroceria) =>
              carroceria.nomeComprimido.toLowerCase().includes(value.toString().toLowerCase())
            )
          )
        );
      });
    }
  }

  // closedCarroceriaAutoComplete(event: any) {
  //   if(!this.carroceriaSelecionada ||
  //     (typeof this.form.value.carroceria !== 'object' && this.form.value.carroceria !== null)) {
  //     this.form.patchValue({
  //       carroceria: ''
  //     });
  //   }
  // }

  private especiesAutocomplete(): void {
    const especiesAutocomplete = this.form.get(VISTORIA_FORM_NAMES.ESPECIE);
    if (especiesAutocomplete) {
      this.especieService.list().subscribe((data) => {
        this.especies = data;
        this.especiesFiltradas = especiesAutocomplete.valueChanges.pipe(
          startWith(''),
          map((value: string) =>
            this.especies.filter((especie) =>
              especie.descricao.toLowerCase().includes(value.toString().toLowerCase())
            )
          )
        );
      });
    }
  }

  private corAutocomplete(): void {
    const corAutocomplete = this.form.get(VISTORIA_FORM_NAMES.COR);
    if (corAutocomplete) {
      this.corService.list().subscribe((data) => {
        this.cores = data;
        this.corFiltradas = corAutocomplete.valueChanges.pipe(
          startWith(''),
          map((value: string) =>
            this.cores.filter((cor) => cor.descricao.toLowerCase().includes(value.toString().toLowerCase()))
          )
        );
      });
    }
  }

  private tipoCombustivelAutocomplete(): void {
    const tipoCombustivelAutocomplete = this.form.get(VISTORIA_FORM_NAMES.TIPO_COMBUSTIVEL);
    if (tipoCombustivelAutocomplete) {
      this.tipoCombustivelService.list().subscribe((data) => {
        this.tiposCombustivel = data;
        this.tipoCombustivelFiltradas = tipoCombustivelAutocomplete.valueChanges.pipe(
          startWith(''),
          map((value: string) =>
            this.tiposCombustivel.filter((tiposCombustivel) =>
              tiposCombustivel.nomeComprimido.toLowerCase().includes(value.toString().toLowerCase())
            )
          )
        );
      });
    }
  }

  private tiposVeiculoAutocomplete(): void {
    const tiposVeiculoAutocomplete = this.form.get(VISTORIA_FORM_NAMES.TIPO_VEICULO);

    if (tiposVeiculoAutocomplete) {
      this.tipoVeiculoService.list().subscribe((data) => {
        this.tiposVeiculo = data;
        this.tipoVeiculoFiltradas = tiposVeiculoAutocomplete.valueChanges.pipe(
          startWith(''),
          map((value: string) =>
            this.tiposVeiculo.filter((tiposVeiculo) =>
              tiposVeiculo.nomeComprimido.toLowerCase().includes(value.toString().toLowerCase())
            )
          )
        );
      });
    }
  }

  // onPesquisaApontamentoTyped(keyEvent: any): void {
  //   const tiposVeiculoAutocomplete = this.form.get(VISTORIA_FORM_NAMES.TIPO_VEICULO);
  //   if (this.tiposVeiculoAutocomplete.value.length + 1 < 3) {
  //     return;
  //   }

  //   if (this.searchTimer != null) {
  //     clearTimeout(this.searchTimer);
  //   }

  //   this.searchTimer = setTimeout(() => {
  //     this.apontamentoService.arvore(`${this.termoPesquisa.value}`).subscribe(data => {
  //       this.dataSource.data = data;
  //     });
  //   }, 800);
  // }

  private modeloAutocomplete(): void {
    const modeloAutocomplete = this.form.get(VISTORIA_FORM_NAMES.MODELO);
    if (modeloAutocomplete) {
      this.modeloService.modelosVeiculo().subscribe((data) => {
        this.modelo = data;
        this.modeloFiltradas = modeloAutocomplete.valueChanges.pipe(
          startWith(''),
          map((value: string) =>
            this.modelo.filter((modelo) =>
              modelo.descricao.toLowerCase().includes(value.toString().toLowerCase())
            )
          )
        );
      });
    }
  }

  // closedEspeciesAutoComplete(event: any) {
  //   if(!this.especieSelecionada ||
  //     (typeof this.form.value.especie !== 'object' && this.form.value.especie !== null)) {
  //     this.form.patchValue({
  //       especie: ''
  //     });
  //   }
  // }

  // ^ é aqui que precisa ser alterado ^

  omit_special_char(event: any) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    //  return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    return k != 123 && k != 135 && k != 34 && k != 39;
    // return( (k != 128 || k != 135) && (k != 34) && (k != 39))  ;
  }

  omit_special_char_cmt_pbt(event: any) {
    var k;
    k = event.charCode;
    return k != 56 || k != 54;
  }

  handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  }

  onlynumber(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    //var regex = /^[0-9.,]+$/;
    var regex = /^[0-9.]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  puxarChassi() {
    this.habAllFields();
    try {
      setTimeout(() => {
        this.isLoading = false;
      }, 10000);
      this.isLoading = true;
      if (!this.form.value.chassi) {
        this.toastr.warning('É necessário preencher o campo CHASSI', 'Erro', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.vistoria.getVeiculoDetranPorChassi(this.form.value.chassi).subscribe((data) => {
        if (data.codigoRetorno == 0) {
          this.setForms(data);
          console.log(data);
          setTimeout(() => {
            this.clickFieldsSelect();
          }, 500);
          setTimeout(() => {
            this.disabledAllFields();
          }, 1000);
          this.toastr.success('Veiculo encontrado com sucesso.', 'Sucesso', {
            positionClass: 'toast-top-center'
          });
        }
        if (data.codigoRetorno != 0 && data.codigoRetorno != null) {
          // this.form.reset();
          this.toastr.warning(`${data.messageRetorno}`, 'Erro', {
            positionClass: 'toast-top-center'
          });
        }
        this.form.reset;
        // checkpoint checar código retorno
        if (data.codigoRetorno == null) {
          this.toastr.warning('Acesso indisponível, verifique com suporte', 'Erro', {
            positionClass: 'toast-top-center'
          });
        }
      });
    } catch (error) {
      this.toastr.warning('Erro inesperado.', 'Erro', {
        positionClass: 'toast-top-center'
      });
    }
  }

  limparCampos() {
    this.form.patchValue({
      placa: null,
      tipoVeiculo: "",
      renavam: null,
      chassi: null,
      etaVis: null,
      modelo: "",
      anoFabricacao: null,
      anoModelo: null,
      especie: "",
      potencia: null,
      cilindrada: null,
      capacidadeCarga: null,
      cmt: null,
      pbt: null,
      cpfCnpj: null,
      nome: null,
      carroceria: "",
      cor: "",
      numeroMotor: null,
      idTipoCombustivel: "",
      capacidadePassageiros: null,
      numeroEixos: null
    });
  }
  limpaCampo(checked:any){
    if(checked){
      checked.value = '';
    }
  }
}
