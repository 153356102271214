import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  SecurityContext,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CameraType } from '../decisao-camera/decisao-camera.component';
import { DomSanitizer } from '@angular/platform-browser';

type PopUpCameraProps = { cameraType: CameraType };

@Component({
  selector: 'app-pop-up-boroscopio',
  templateUrl: './pop-up-boroscopio.component.html',
  styleUrls: ['./pop-up-boroscopio.component.css']
})
export class PopUpBoroscopioComponent implements OnInit, AfterViewInit {
  @ViewChild('boroscopioPreview')
  boroscopioPreview?: ElementRef<HTMLImageElement>;
  @ViewChild('canvasVideo') canvasVideo?: ElementRef<HTMLCanvasElement>;
  @ViewChild('photoPreview') photoPreview?: ElementRef<HTMLImageElement>;
  streamCamera?: MediaStream;
  isPhotoPreviewActive = false;

  photo: any = null;
  urlBoroscopio: string = 'http://192.168.1.1:8080/?action=stream';
  // urlBoroscopio: string = 'https://images-ext-2.discordapp.net/external/bCtkFNUZxz7oRdbaG8v9pJfW4GFK_s_JTKZ5V_ZU7sM/https/dummyimage.com/800x600';

  constructor(
    private dialogRef: MatDialogRef<PopUpBoroscopioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopUpCameraProps,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.isPhotoPreviewActive = false;
  }

  ngAfterViewInit() {
    console.log('Values on ngAfterViewInit():');
    // this.startBoroscopio();
  }

  takePhoto() {
    if (!this.canvasVideo?.nativeElement) return;
    if (!this.boroscopioPreview?.nativeElement) return;
    if (!this.photoPreview?.nativeElement) return;

    console.log('opa fion');

    this.isPhotoPreviewActive = true;
    const previewElem = this.boroscopioPreview.nativeElement;

    this.canvasVideo.nativeElement.width = previewElem.naturalWidth;
    this.canvasVideo.nativeElement.height = previewElem.naturalHeight;

    const context = this.canvasVideo.nativeElement.getContext('2d');
    if (context != null) {
      context.drawImage(previewElem, 0, 0);
      this.canvasVideo.nativeElement.toBlob(
        (blob) => {
          if (!blob) return;

          this.photo = blob;
          const urlPhoto = URL.createObjectURL(blob);

          if (this.photoPreview?.nativeElement) {
            this.photoPreview.nativeElement.src = urlPhoto;
          }
        },
        'image/jpeg',
        1
      );
    }
  }

  close() {
    this.dialogRef.close({ confirm: false });
  }

  repeatPhoto() {
    if (this.photoPreview?.nativeElement) {
      this.photoPreview.nativeElement.src = '';
    }
    this.isPhotoPreviewActive = false;
  }

  savePhoto() {
    this.dialogRef.close({ confirm: true, photo: this.photo });
  }

  isBoroscopio() {
    return this.data.cameraType == 'boroscopio';
  }
}
