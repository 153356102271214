import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface DialogPopUpResponseProps {
  confirm: boolean;
}

const ONE_MINUTE_IN_SECONDS = 60;

@Component({
  selector: 'app-pop-up-video',
  templateUrl: './pop-up-video.component.html',
  styleUrls: ['./pop-up-video.component.css']
})
@Injectable()
export class PopUpVideoComponent implements OnInit, OnDestroy {
  @ViewChild('videoPreview') videoPreview?: ElementRef<HTMLVideoElement>;
  @ViewChild('videoGravado') videoGravado?: ElementRef<HTMLVideoElement>;

  @ViewChild('seconds') secondsElem?: ElementRef<HTMLVideoElement>;

  isPreviewActive = false;
  urlVideoGravado?: SafeUrl;
  mediaRecorder: MediaRecorder | null;
  blobVideoGravado?: Blob;
  DEFAULT_TIME = ONE_MINUTE_IN_SECONDS * 5;
  secondsLeft = this.DEFAULT_TIME;
  streamCamera?: MediaStream;
  armazenaChunks: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<PopUpVideoComponent>,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.mediaRecorder = null;
  }

  ngOnInit(): void {
    this.startCamera();
    const subs = this.dialogRef.afterClosed().subscribe(() => {
      this.stopCamera();
      subs.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.stopCamera();
  }

  isRecording() {
    return this.mediaRecorder != null;
  }

  startCamera() {
    if (navigator.mediaDevices) {
      this.initCamera()
        .then((stream) => {
          this.streamCamera = stream;

          if (this.videoPreview) {
            this.videoPreview.nativeElement.srcObject = stream;
          }
        })
        .catch((err) => {
          alert('Erro ao iniciar a camera:' + (err as Error).toString());
        });
    } else {
      alert('Seu navegador não suporta camera');
    }
  }

  close() {
    this.stopCamera();
    this.dialogRef.close({ confirm: false });
  }

  formatTime(seconds: number) {
    if (typeof seconds !== 'number' || seconds < 0) return seconds;

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  initCamera() {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        facingMode: 'environment',
        aspectRatio: 3 / 4,
        width: {
          ideal: 768
        },
        height: {
          ideal: 1024
        }
      }
    });
  }

  stopCamera() {
    this.streamCamera?.getTracks().forEach((track) => track.stop());
    this.mediaRecorder?.stop();
    this.ref.detectChanges();
  }

  repeatVideo() {
    this.isPreviewActive = false;
    if (this.videoGravado) {
      this.videoGravado.nativeElement.src = '';
    }

    this.startCamera();
  }

  gravar() {
    const mimeType = 'video/webm;codecs=vp8';
    if (!this.mediaRecorder && this.streamCamera) {
      this.secondsLeft = this.DEFAULT_TIME;
      this.mediaRecorder = new MediaRecorder(this.streamCamera, { mimeType: mimeType });
      this.mediaRecorder.start(1000);

      this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
        this.armazenaChunks.push(event.data);
        this.secondsLeft -= 1;
        this.ref.detectChanges();
        if (this.secondsLeft == 0) {
          this.stopCamera();
        }
      };

      this.mediaRecorder.onstop = async (event: Event) => {
        const blob = new Blob(this.armazenaChunks, { type: 'video/mp4' });
        this.blobVideoGravado = blob;

        // const urlVideo = this.sanitizer.bypassSecurityTrustUrl(
        //   URL.createObjectURL(blob)
        // );

        this.isPreviewActive = true;
        const urlVideo = URL.createObjectURL(blob);

        if (this.videoGravado) {
          this.videoGravado.nativeElement.src = urlVideo;
        }

        this.mediaRecorder = null;
        this.ref.detectChanges();
      };
    } else {
      this.mediaRecorder?.stop();
      this.stopCamera();
    }
  }

  saveVideo() {
    this.stopCamera();
    const timeInSeconds = this.DEFAULT_TIME - this.secondsLeft - 1;
    console.log(timeInSeconds + 'aqui está a duração com a função');
    this.dialogRef.close({ confirm: true, arquivo: this.blobVideoGravado, duration: timeInSeconds });
  }
}
