import { ItemVistoriadoRestrito } from './../../../../models/dto/item-vistoriado-restrito.dto';
import { ItemVistoriadoEnum } from 'src/models/enums/ItemVistoriadoEnum';
import { AfterViewInit, Component, Input, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VistoriaService } from 'src/app/services/vistoria.service';
import { DadosApontamento } from 'src/models/dados-apontamento.model';
import { DadosArquivo } from 'src/models/dados-arquivo.model';
import { DadosVistoria } from 'src/models/dados-vistoria.model';
import InativarVistoria from 'src/models/inativar-vistoria'
import { TipoArquivo } from 'src/models/enums/tipo-arquivo.enum';
import { VistoriaInformacoesVeiculoComponent } from '../informacoes-veiculo/vistoria-informacoes-veiculo.component';
import { VistoriaInfo } from 'src/models/vistoria-info.model';
import { ToastrService } from 'ngx-toastr';
import { SITUACAO_VISTORIA_BACK_OFFICE } from 'src/models/enums/situacao-back-office';
import { ApontamentoVistoriadorArvoreComponent } from 'src/app/apontamento-vistoriador-arvore/apontamento-vistoriador-arvore.component';
import UpdateVistoria from 'src/models/dto/update-vistoria.dto';
import unmask from 'src/utils/unmask';
import { VistoriaMidiaViewComponent } from '../midia-view/vistoria-midia-view.component';
import { AuthService } from 'src/app/services/auth.service';
import { VistoriaInformacoesProprietarioComponent } from '../informacoes-proprietario/vistoria-informacoes-proprietario.component';
import { threadId } from 'worker_threads';


@Component({
  selector: 'app-vistoria-view',
  templateUrl: './vistoria-view.component.html',
  styleUrls: ['./vistoria-view.component.css']
})
export class VistoriaViewComponent implements OnInit, AfterViewInit {
  @Input() ocultarBotao: boolean = false;
  
  vistorias: VistoriaInfo[];
  vistoriaId: number;
  form!: FormGroup;
  paraRepeticao = false;
  formVeiculo!: FormGroup;
  formProprietario!: FormGroup;
  nomeSelecao: string;
  apontamentos: DadosApontamento[];
  imagensVeiculo: DadosArquivo[];
  itensAgregados: DadosArquivo[];
  videos: DadosArquivo[];
  itensVistoriadosRestrito: ItemVistoriadoRestrito[];
  itensVistoriadosEnum: ItemVistoriadoEnum[];
  idSituacaoBackOffice?: SITUACAO_VISTORIA_BACK_OFFICE;
  inativar?: boolean;
  justificativaInativa?: string;
  isLoading = false;
  isSubmitting: boolean;
  
  @ViewChild('informacoesVeiculo')
  informacoesVeiculo!: VistoriaInformacoesVeiculoComponent;
  
  @ViewChild('refModalInativar') refModalInativar!: ElementRef<HTMLDialogElement>;
  mensagemJustificativaValue: string = '';
  
  @ViewChild('arvoreApontamentos')
  arvoreApontamentos!: ApontamentoVistoriadorArvoreComponent;
  
  @ViewChild('midiaView')
  midias!: VistoriaMidiaViewComponent;

  @ViewChild('informacoesProprietario')
  informacoesProprietario!: VistoriaInformacoesProprietarioComponent;

  constructor(
    private fb: FormBuilder, 
    private vistoriaService: VistoriaService,
    private router: ActivatedRoute,
    private routerNavigate: Router,
    private toastr: ToastrService,
    private authService: AuthService,) {
      
    this.vistoriaId = this.router.snapshot.params['id'];
    this.apontamentos = [];
    this.imagensVeiculo = [];
    this.itensAgregados = [];
    this.videos = [];
    this.itensVistoriadosRestrito = [];
    this.itensVistoriadosEnum = []
    this.nomeSelecao = '';
    this.vistorias = [];
    this.isSubmitting = false;
  }

  step: any = 6;
  stepOk = false;

  ngOnInit(): void {
    this.form = this.fb.group({
      nome: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      tipoVistoria: [],
      idVeiculo: [''],
      usuarioLogin: [this.authService.getUserAuthenticated()],
      situacao: [],
      dataHora: [],
      numeroLaudo: [],
      validadeLaudo: [],
      idEmplacamento: [],
      observacao: []
    });

    this.formProprietario = this.fb.group({
      cpfCnpj: [{ value: '', disabled: false }],
      nome: [{ value: '', disabled: false }]
    });

    this.vistoriaService.getById(this.vistoriaId).subscribe(data => {
      this.setForms(data);
      this.apontamentos = data.apontamentos;
      // this.itensVistoriados = data.itensVistoriados;
      this.itensVistoriadosRestrito = data.itensVistoriadosRestrito;
      this.itensVistoriadosEnum = data.itensVistoriadosEnum;
      this.idSituacaoBackOffice = data.idSituacaoBackOffice;
      this.inativar = data.inativa;
      this.justificativaInativa = data.justificativaInativa;

    });

    this.vistoriaService.getArquivosMidia(this.vistoriaId).subscribe(data => {
      this.imagensVeiculo = data.filter(i => i.tipo === TipoArquivo.FOTO_VEICULO);
      this.itensAgregados = data.filter(i => i.tipo === TipoArquivo.ITEM_AGREGADO);
      this.videos = data.filter(i => i.tipo === TipoArquivo.VIDEO360);
    });

    this.printar();

    // const justificativa = document.getElementById("justificativa") as HTMLHtmlElement || undefined;
    // if (justificativa) {

    //   let texto: any = justificativa.innerText

    //     const textoQuebrado = texto.match(/.{1,30}/g).join("<br>")

    //   justificativa.innerHTML = textoQuebrado;
    // }


  }

  ngAfterViewInit(): void {
    this.formVeiculo = this.informacoesVeiculo.form;
    this.form.addControl('veiculo', this.formVeiculo);
    this.formVeiculo.setParent(this.form);
  }

  getControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  setForms(vistoria: DadosVistoria) {
    this.form.patchValue({
      tipoVistoria: vistoria.idTipoVistoria,
      idEmplacamento: vistoria.idEmplacamento,
      observacao: vistoria.observacao,
      situacao: vistoria.situacao,
      dataHora: vistoria.dataHora,
      numeroLaudo: vistoria.numeroLaudo,
      validadeLaudo: vistoria.validadeLaudo
    });

    this.formVeiculo.patchValue({
      tipoVeiculo: vistoria.informacoesVeiculoVistoriador.idTipoVeiculo,
      placa: vistoria.informacoesVeiculoVistoriador.placa,
      renavam: vistoria.informacoesVeiculoVistoriador.renavam,
      // marca: vistoria.informacoesVeiculoVistoriador.marca,
      modelo: vistoria.informacoesVeiculoVistoriador.modelo,
      cor: vistoria.informacoesVeiculoVistoriador.cor,
      anoFabricacao: vistoria.informacoesVeiculoVistoriador.anoFabricacao,
      anoModelo: vistoria.informacoesVeiculoVistoriador.anoModelo,
      carroceria: vistoria.informacoesVeiculoVistoriador.carroceria,
      especie: vistoria.informacoesVeiculoVistoriador.especie,
      idTipoCombustivel: vistoria.informacoesVeiculoVistoriador.idTipoCombustivel,
      combustivel: vistoria.informacoesVeiculoVistoriador.combustivel,
      capacidadePassageiros: vistoria.informacoesVeiculoVistoriador.capacidadePassageiros,
      potencia: vistoria.informacoesVeiculoVistoriador.potencia,
      cilindrada: vistoria.informacoesVeiculoVistoriador.cilindrada,
      capacidadeCarga: vistoria.informacoesVeiculoVistoriador.capacidadeCarga,
      cmt: vistoria.informacoesVeiculoVistoriador.cmt,
      pbt: vistoria.informacoesVeiculoVistoriador.pbt,
      chassi: vistoria.informacoesVeiculoVistoriador.chassi,
      numeroMotor: vistoria.informacoesVeiculoVistoriador.numeroMotor,
      hodometro: vistoria.informacoesVeiculoVistoriador.hodometro,
      numeroEixos: vistoria.informacoesVeiculoVistoriador.numeroEixos
    });

    this.formProprietario.patchValue({
      cpfCnpj: vistoria.informacoesProprietario.cpfCnpj,
      nome: vistoria.informacoesProprietario.nome
    });
  }

  printLaudo(vistoriaId: number): void {
    this.vistoriaService.printLaudo(vistoriaId)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "Laudo.pdf";
        link.click();
      }, (errorResponse) => {
        this.toastr.error(errorResponse.errors[0], 'Erro ao gerar relatório', {
          positionClass: 'toast-top-center'
        });
      });
  }

  getColorSituacao(): string {
    switch (this.form.value.situacao) {
      case 'Aprovada': {
        return 'tarja-aprovado';
      }
      case 'Reprovada': {
        return 'tarja-reprovado';
      }
      case 'Aprovado com apontamento': {
        return 'tarja-apontamento';

      }
      default: {
        return 'tarja-padrao';
      }
    }
  }

  defineTarja(itemVistoriado?: ItemVistoriadoEnum): string {
    let classeTarja = 'tarja tarja-small tarja-';
    switch (itemVistoriado) {
      case ItemVistoriadoEnum.APROVADO: {
        classeTarja += 'aprovado';
        this.nomeSelecao = 'Aprovado';
      } break;
      case ItemVistoriadoEnum.REPROVADO: {
        classeTarja += 'reprovado';
        this.nomeSelecao = 'Reprovado';
      } break;
      case ItemVistoriadoEnum.APROVADO_COM_RESTRICAO: {
        classeTarja += 'apontamento';
        this.nomeSelecao = "Aprovado com Apontamento";
      } break;
      case ItemVistoriadoEnum.NAO_APLICAVEL: {
        classeTarja += 'naoAplicavel';
        this.nomeSelecao = "Não Aplicável";
      } break;
    }
    return classeTarja;
  }

  voltarDadosVistoria() {
    this.step = 1;
  }

  voltarVeiculo() {
    this.step = 2
  }

  voltarMidia() {
    this.step = 3;
  }

  voltarItensVistoriados() {
    this.step = 4;
  }

  voltarApVistoriador() {
    this.step = 5;
  }

  returnAbstract() {
    this.step = 0;
    this.step = 6;
  }

  printar(){
    console.log(this.mensagemJustificativaValue)
    console.log(this.idSituacaoBackOffice)
    console.log(this.inativar)
  }

  showButtonInativarVistoria() {
    return this.idSituacaoBackOffice == SITUACAO_VISTORIA_BACK_OFFICE.PENDENTE_DE_APROVACAO && this.inativar != true;
  }

  inativarVistoria() {
    this.mensagemJustificativaValue = this.mensagemJustificativaValue.toLocaleUpperCase()
    if(this.mensagemJustificativaValue == '' || this.mensagemJustificativaValue == undefined){
      if(this.refModalInativar){
        this.refModalInativar.nativeElement.close();
      }
      this.toastr.error('Erro ao inativar', 'Campo justificativa obrigatório! ', {
        positionClass: 'toast-top-center'
      });
    }else{
    const montaBody: InativarVistoria = { id: this.vistoriaId, inativa: true, justificativaInativa: this.mensagemJustificativaValue }
    this.vistoriaService.inativarVistoria(montaBody).subscribe(data => {
      console.log('deu bom')

      this.toastr.success('Sucesso', 'Vistoria inativada com sucesso', {
        positionClass: 'toast-top-center'
      });

      if(this.refModalInativar){
        this.refModalInativar.nativeElement.close();
      }

    }, (err) => {
      if(this.refModalInativar){
        this.refModalInativar.nativeElement.close();
      }
      this.toastr.error('Erro ao inativar', 'Ocorreu um erro interno, tente novamente mais tarde ', {
        positionClass: 'toast-top-center'
      });

      console.log('deu ruim', JSON.stringify(err));

    });
  }

    // this.refModalInativar.close()

    // this.closeDialog();
  }

  private getVistoriaDTO(vistoria: UpdateVistoria): UpdateVistoria {
    localStorage.removeItem('prop');
    
    if (vistoria.veiculo) {
      // vistoria.veiculo.placa = unmask(vistoria.veiculo.placa);
      vistoria.veiculo.chassi = unmask(vistoria.veiculo.chassi).replace(' ','');
      vistoria.veiculo.renavam = unmask(vistoria.veiculo.renavam);
    }

    vistoria.nome = vistoria.nome?.toLocaleUpperCase();
    vistoria.cpfCnpj = unmask(vistoria.cpfCnpj);

    vistoria.apontamentos = this.arvoreApontamentos.selected;

    vistoria.imagensVeiculo = this.midias.informacaoFotos;
    vistoria.itensAgregados = this.midias.informacaoAgregados;
    vistoria.videos = this.midias.informacaoVideos;

    return vistoria;
  }

  validaCamposProprietario() {
    if (
      this.getVistoriaDTO(this.form.value).nome != '' &&
      this.getVistoriaDTO(this.form.value).cpfCnpj != '' &&
      this.getVistoriaDTO(this.form.value).tipoVistoria != 0
    ) {

      const validaInfoResumo = document.getElementById(
        'validaInformacoesProprietario'
      );
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = 'white';
      }
    } else {
      const validaInfoResumo = document.getElementById(
        'validaInformacoesProprietario'
      );
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = '#FF6347';
      }
    }
  }

  validaCamposVeiculo() {
    let puxarVeiculos = this.getVistoriaDTO(this.form.value).veiculo!;
    let validarCamposVeiculo = Object.values(puxarVeiculos);

    this.paraRepeticao = false;
    for (
      var i = 0;
      i < validarCamposVeiculo.length && !this.paraRepeticao;
      i++
    ) {
      var nomePropriedade = validarCamposVeiculo[i];
      // console.log(nomePropriedade);
      if (nomePropriedade && nomePropriedade.valueOf() == '') {
        this.paraRepeticao = true;
        // console.log(validarCamposVeiculo);
        const validaInfoResumo = document.getElementById('validaInformacoes');
        if (validaInfoResumo != undefined) {
          validaInfoResumo.style.backgroundColor = '#FF6347';
        }
      } else {
        const validaInfoResumo = document.getElementById('validaInformacoes');
        if (validaInfoResumo != undefined) {
          validaInfoResumo.style.backgroundColor = 'white';
        }
        this.paraRepeticao = false;
      }
    }
  }

  validaCamposMidia() {
    if (
      this.getVistoriaDTO(this.form.value).imagensVeiculo.length == 4
      //  && this.getVistoriaDTO(this.form.value).videos.length == 2
    ) {
      const validaInfoResumo = document.getElementById('validaMidia');
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = 'white';
      }
    } else {
      const validaInfoResumo = document.getElementById('validaMidia');
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = '#FF6347';
      }
    }
  }

  async atualizarVistoria(): Promise<void>{
    setTimeout(() => {
      this.isLoading  = false;
    }, 10000);
    this.isLoading = true;

    console.log('Formulário da vistoria');
    console.log(this.getVistoriaDTO(this.form.value));

    this.validaCamposProprietario();
    this.validaCamposVeiculo();
    this.validaCamposMidia();

    this.isSubmitting = true;

    console.log(this.getVistoriaDTO(this.form.value));

    this.vistoriaService
      .update(this.getVistoriaDTO(this.form.value), this.midias.arquivos, this.vistoriaId)
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.toastr.success('Vistoria atualizada com sucesso', 'Sucesso', {
            positionClass: 'toast-top-center',
          });
          this.routerNavigate.navigate(['/vistoria']);
        },
        (e) => {
          this.isSubmitting = false;
          if (e && e.error && Array.isArray(e.error.errors)) {
            this.toastr.error(e.error.errors[0], 'Falha', {
              positionClass: 'toast-top-center',
            });
          } else {
            this.toastr.error(
              'Erro no processo de atualização da vistoria.',
              'Falha',
              {
                positionClass: 'toast-top-center',
              }
            );
          }
        }
      );
    }

  // closeDialog() {
  //   const modal = document.getElementById('dialog') as HTMLDialogElement;
  //   console.log(modal);
  //   modal.show();
  //   modal.close()
  // }


}
