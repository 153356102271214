import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Dominio from 'src/models/dominio.model';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DominioService {
  constructor(private http: HttpClient) { }

  private readonly BASE_URL = `${environment.apiBaseUrl}/dominio/`;
  private readonly SITUACAO_VISTORIA_BACK_OFFICE = `${this.BASE_URL}situacao-vistoria-back-office`;
  private readonly EMPLACAMENTO = `${this.BASE_URL}emplacamento`;


  tiposVistoria(): Observable<Dominio[]> {
    return this.http.get<Dominio[]>('/dominio/tipo-vistoria').pipe(take(1)).pipe(take(1));
  }

  situacoesVistoria(): Observable<Dominio[]> {
    return this.http.get<Dominio[]>('/dominio/situacao-vistoria').pipe(take(1));
  }

  situacoesVistoriaBackOffice(): Observable<Dominio[]> {
    return this.http.get<Dominio[]>('/dominio/situacao-vistoria-back-office').pipe(take(1));
  }

  listarSituacoesVistoriaBackOffice(): Observable<Dominio[]> {
    return this.http.get<Dominio[]>(this.SITUACAO_VISTORIA_BACK_OFFICE).pipe(take(1));
  }

  listarEmplacamento(): Observable<Dominio[]> {
    return this.http.get<Dominio[]>('/dominio/emplacamento').pipe(take(1));
  }
}
