import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import Carroceria from "src/models/carroceria.model";

@Injectable({
  providedIn: 'root'
})
export class CarroceriaService {
  private readonly RESOURCE = '/carroceria';

  constructor(private http: HttpClient) { }

  list(): Observable<Carroceria[]> {
    return this.http.get<Carroceria[]>(this.RESOURCE).pipe(take(1));
  }
}
