import { Profile } from 'src/models/dto/profile.dto';

import { ItemVistoriadoEnum } from 'src/models/enums/ItemVistoriadoEnum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApontamentoVistoriadorArvoreComponent } from 'src/app/apontamento-vistoriador-arvore/apontamento-vistoriador-arvore.component';
import { IntegracaoService } from 'src/app/services/integracao.service';
import { ItemVistoriaService } from 'src/app/services/item-vistoria.service';
import { VistoriaService } from 'src/app/services/vistoria.service';
import ItemVistoriado from 'src/models/dto/item-vistoriado.dto';
import NovaVistoria from 'src/models/dto/nova-vistoria.dto';
import SITUACAO_VISTORIA from 'src/models/enums/situacoesVistoria.enum';
import TIPO_APONTAMENTO from 'src/models/enums/TipoApontamento.enum';
import { VISTORIA_FORM_NAMES } from 'src/models/enums/vistoria-form-names.enum';
import getCurrentLocation from 'src/utils/getCurrentLocation';
import { VistoriaInformacoesProprietarioComponent } from './../informacoes-proprietario/vistoria-informacoes-proprietario.component';
import { VistoriaInformacoesVeiculoComponent } from '../informacoes-veiculo/vistoria-informacoes-veiculo.component';

// import { VistoriaItensVistoriadosComponent } from "../itens-vistoriados/vistoria-itens-vistoriados.component";
import { VistoriaMidiaComponent } from '../midia/vistoria-midia.component';
import unmask from 'src/utils/unmask';
import { AuthService } from 'src/app/services/auth.service';
import { ReturnHomeComponent } from 'src/app/return-home/return-home.component';
import { SITUACAO_VISTORIA_BACK_OFFICE } from 'src/models/enums/situacao-back-office';
import Dominio from 'src/models/dominio.model';
import { DominioService } from 'src/app/services/dominio.service';
import EMPLACAMENTO from 'src/models/enums/emplacamento.enum';
import { Location } from '@angular/common';
import { NOMEM } from 'dns';
import validaCpfCnpj from 'src/utils/validaCpfCnpj';

@Component({
  selector: 'app-vistoria-criar',
  templateUrl: './vistoria-criar.component.html',
  styleUrls: ['./vistoria-criar.component.css'],
})
export class VistoriaCriarComponent implements OnInit, AfterViewInit, OnChanges {
  form!: FormGroup;
  step: any = 1;
  percent = 0;
  stepOk = false;
  paraRepeticao = false;
  private initialPageUrl!: string;
  isLoading = false;
  localVistoriador!: string;
  idLocalVistoriador!: number;
  canLeavePage: boolean;
  showed!: boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.form.dirty) {
      // Verifica se o formulário foi alterado
      $event.returnValue = true; // Define a mensagem padrão de confirmação
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (
      confirm(
        'Você tem certeza que deseja sair? Se você sair, seu formulário não será salvo.'
      )
    ) {
      history.go(-1);
    } else {
      history.pushState(null, 'null', window.location.href);
    }
  }

  // @HostListener('window:popstate', ['$event'])
  // unloadNotificationBack(event: any) {
  //   if (!this.canLeavePage) {
  //     const confirmationMessage = 'Deseja realmente sair do aplicativo? O formulário será perdido.';
  //     const confirmation = confirm(confirmationMessage);
  //     if (confirmation) {
  //       history.pushState(null, '', '/home');
  //       window.location.reload();
  //       // history.forward()
  //     } else {
  //        // history.pushState(null, '', '/vistoria/criar');
  //       // event.preventDefault();
  //       history.pushState(null, '', '/home');
  //       console.log('clicou no false')
  //     }
  //   }
  //   return null;
  // }

  finalizarBtnColor: string = 'btn-aprovado';
  finalizarBtnName: string = 'Aprovar Vistoria';
  situacaoSelecionada: number = SITUACAO_VISTORIA.APROVADA;
  situacaoBackOffice: number =
    SITUACAO_VISTORIA_BACK_OFFICE.PENDENTE_DE_APROVACAO;
  isSubmitting: boolean;

  itensVistoriados: ItemVistoriado[];
  fotosObrigatorias: ItemVistoriado[];
  videosObrigatorios: ItemVistoriado[];
  itemVistoriadoEnum: ItemVistoriadoEnum[];
  idEmplacamento!: Dominio[];
  perfilUsuario!: Profile;

  FormNames = VISTORIA_FORM_NAMES;
  SituacaoVistoria = SITUACAO_VISTORIA;

  @ViewChild('informacoesVeiculo')
  informacoesVeiculo!: VistoriaInformacoesVeiculoComponent;

  @ViewChild('informacoesProprietario')
  informacoesProprietario!: VistoriaInformacoesProprietarioComponent;

  @ViewChild('arvoreApontamentos')
  arvoreApontamentos!: ApontamentoVistoriadorArvoreComponent;

  @ViewChild('midia')
  midias!: VistoriaMidiaComponent;

  @ViewChild('vistoriados')
  // vistoriados!: VistoriaItensVistoriadosComponent;
  puxarVeiculos: string;
  validarCamposVeiculo: string;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private vistoriaService: VistoriaService,
    private dominioService: DominioService,
    private itemVistoriaService: ItemVistoriaService,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private integracaoService: IntegracaoService,
    private location: Location
  ) {
    this.itensVistoriados = [];
    this.fotosObrigatorias = [];
    this.videosObrigatorios = [];
    this.isSubmitting = false;
    this.itemVistoriadoEnum = [];
    this.puxarVeiculos = '';
    this.validarCamposVeiculo = '';
    this.paraRepeticao = false;
    this.canLeavePage = false;
    this.initialPageUrl = this.location.path();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges - VistoriaCriarComponent');
    
  }

  ngOnInit(): void {
    localStorage.removeItem('prop');
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, 'null');

    this.initialPageUrl = window.location.href;
    this.form = this.fb.group({
      tipoVistoria: ['', Validators.required],
      idEmplacamento: ['', Validators.required],
      idSituacaoVistoria: [''],
      idSituacaoVistoriaBackOffice: [''],
      latitude: [''],
      longitude: [''],
      local: [null],
      intervalms: [0],
      dataHoraInicio: [new Date()],
      usuarioLogin: [this.authService.getUserAuthenticated()],
      idUnidade: [null],
      observacao: [''],
      nome: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
    });

    this.authService.loadProfileInfo().subscribe((data) => {
      this.form.patchValue({
        local: data.unidade.nome,
        idUnidade: data.unidade.id,
      });
    });

    this.itemVistoriaService.list().subscribe((data) => {
      for (const itemVistoria of data) {
        const item: ItemVistoriado = {
          selecionado: 3,
          idItemVistoria: itemVistoria.id,
          nome: itemVistoria.nome,
          ativoFoto: itemVistoria.ativoFoto,
          ativoVideo: itemVistoria.ativoVideo,
          mediaSizeOriginalBytes: itemVistoria.mediaSizeOriginalBytes,
          duration: itemVistoria.duration
        };

        if (itemVistoria.obrigatorioFoto) {
          item.selecionado = 0;
          this.fotosObrigatorias.push(item);
        }
        if (itemVistoria.obrigatorioVideo) {
          item.selecionado = 0;
          this.videosObrigatorios.push(item);
        }
        if (itemVistoria.ativoRoteiroVistoria) {
          this.itensVistoriados.push(item);
        }
        if (itemVistoria.ativoFoto) {
          item.selecionado = 10;
          this.fotosObrigatorias.push(item);
        }
        if (itemVistoria.ativoVideo) {
          item.selecionado = 10;
          this.videosObrigatorios.push(item);
        }
        // if(itemVistoria.ativoVideo){
        //   item.selecionado = 0;
        //   this.videosObrigatorios.push(item);
        // }
      }
    });

    this.dominioService.listarEmplacamento().subscribe((data) => {
      this.idEmplacamento = data;
    });
  }

  ngAfterViewInit(): void {
    // this.form.addControl('pbt', this.informacoesVeiculo.pbt);
    // this.form.addControl('cmt', this.informacoesVeiculo.cmt);
    this.form.addControl('veiculo', this.informacoesVeiculo.form);
    this.informacoesVeiculo.form.setParent(this.form);
    let pegarProp = localStorage.getItem('prop');

    // this.form.patchValue({
    //   nome: localStorage.getItem()
    // })
  }

  validaCamposProprietario() {
    if (
      this.getVistoriaDTO(this.form.value).nome != '' &&
      this.getVistoriaDTO(this.form.value).cpfCnpj != '' &&
      this.getVistoriaDTO(this.form.value).idTipoVistoria != 0
    ) {

      const validaInfoResumo = document.getElementById(
        'validaInformacoesProprietario'
      );
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = 'white';
      }
    } else {
      const validaInfoResumo = document.getElementById(
        'validaInformacoesProprietario'
      );
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = '#FF6347';
      }
    }
  }

  validaCamposVeiculo() {
    let puxarVeiculos = this.getVistoriaDTO(this.form.value).veiculo!;
    let validarCamposVeiculo = Object.values(puxarVeiculos);

    this.paraRepeticao = false;
    for (
      var i = 0;
      i < validarCamposVeiculo.length && !this.paraRepeticao;
      i++
    ) {
      var nomePropriedade = validarCamposVeiculo[i];
      // console.log(nomePropriedade);
      if (nomePropriedade && nomePropriedade.valueOf() == '') {
        this.paraRepeticao = true;
        // console.log(validarCamposVeiculo);
        const validaInfoResumo = document.getElementById('validaInformacoes');
        if (validaInfoResumo != undefined) {
          validaInfoResumo.style.backgroundColor = '#FF6347';
        }
      } else {
        const validaInfoResumo = document.getElementById('validaInformacoes');
        if (validaInfoResumo != undefined) {
          validaInfoResumo.style.backgroundColor = 'white';
        }
        this.paraRepeticao = false;
      }
    }
  }

  validaCamposMidia() {
    if (
      this.getVistoriaDTO(this.form.value).imagensVeiculo.length >= 4
      //  && this.getVistoriaDTO(this.form.value).videos.length == 2
    ) {
      const validaInfoResumo = document.getElementById('validaMidia');
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = 'white';
      }
    } else {
      const validaInfoResumo = document.getElementById('validaMidia');
      if (validaInfoResumo != undefined) {
        validaInfoResumo.style.backgroundColor = '#FF6347';
      }
    }
  }

  async submit(): Promise<void> {
    setTimeout(() => {
      this.isLoading = false;
    }, 10000);
    this.isLoading = true;

    console.log('Formulário da vistoria');
    console.log(this.getVistoriaDTO(this.form.value));

    this.validaCamposProprietario();
    this.validaCamposVeiculo();
    this.validaCamposMidia();

    try {
      this.isSubmitting = true;
      const { latitude, longitude } = await getCurrentLocation();
      this.form.patchValue({
        latitude: latitude,
        longitude: longitude,
      });
    } catch (error) {
      this.toastr.error('Não foi possível encontrar sua localização.', 'Erro', {
        positionClass: 'toast-top-center',
      });
    }

    // if (
    //   this.midias.fotosObrigatorias.findIndex((f) => f.selecionado === 0) !==
    //     -1 ||
    //   this.midias.videosObrigatorios.findIndex((f) => f.selecionado === 0) !==
    //     -1
    // ) {
    //   this.focusFirstInvalidControl();

    //   return;
    // }
    // console.log(this.fotosObrigatorias.length)

    console.log(this.getVistoriaDTO(this.form.value));

    this.vistoriaService
      .create(this.getVistoriaDTO(this.form.value), this.midias.arquivos)
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.toastr.success('Vistoria registrada com sucesso', 'Sucesso', {
            positionClass: 'toast-top-center',
          });
          this.router.navigate(['/vistoria']);
        },
        (e) => {
          this.isSubmitting = false;
          if (e && e.error && Array.isArray(e.error.errors)) {
            this.toastr.error(e.error.errors[0], 'Falha', {
              positionClass: 'toast-top-center',
            });
          } else {
            this.toastr.error(
              'Erro no processo de registro da vistoria.',
              'Falha',
              {
                positionClass: 'toast-top-center',
                timeOut: 5000,
              }
            );
          }
        }
      );
  }

  arvoreHasChanged() {
    if (
      this.arvoreApontamentos.selected.find(
        (ap) =>
          ap.idDominioTipo === TIPO_APONTAMENTO.REPROVADO ||
          ap.idDominioTipo === TIPO_APONTAMENTO.SUSPEITA_DE_ADULTERACAO
      )
    ) {
      this.situacaoSelecionada = SITUACAO_VISTORIA.REPROVADA;
    } else if (
      this.arvoreApontamentos.selected.find(
        (ap) => ap.idDominioTipo === TIPO_APONTAMENTO.COM_APONTAMENTOS
      )
    ) {
      this.situacaoSelecionada = SITUACAO_VISTORIA.APROVADA_COM_RESTRICAO;
    } else {
      this.situacaoSelecionada = SITUACAO_VISTORIA.APROVADA;
    }

    this.atualizarBotaoFinalizar();
  }

  // focusFirstInvalidControl() {
  //   const invalidElement = document.querySelector('.ng-invalid');
  //   if (invalidElement) {
  //     invalidElement.scrollIntoView({ behavior: 'smooth' });
  //   }

  //   this.toastr.warning('Preencha todas as mídias!', 'Atenção', {
  //     positionClass: 'toast-top-center',
  //   });
  // }

  atualizarBotaoFinalizar() {
    if (this.situacaoSelecionada == SITUACAO_VISTORIA.REPROVADA) {
      this.finalizarBtnName = 'Reprovar Vistoria';
      this.finalizarBtnColor = 'btn-reprovado';
    } else if (
      this.situacaoSelecionada == SITUACAO_VISTORIA.APROVADA_COM_RESTRICAO
    ) {
      this.finalizarBtnName = 'Aprovar Vistoria com Restrição';
      this.finalizarBtnColor = 'btn-apontamentos';
    } else {
      this.finalizarBtnName = 'Aprovar Vistoria';
      this.finalizarBtnColor = 'btn-aprovado';
    }
  }

  getControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  consultaVeiculo() {
    this.integracaoService
      .veiculo(
        this.form.value.veiculo.placa,
        unmask(this.form.value.veiculo.renavam)
      )
      .subscribe((result) => {
        if (result?.idConsulta !== -1) {
          this.form.patchValue({
            nome: result.nome,
            cpfCnpj: result.cpfCnpj,
          });

          this.form.patchValue({
            veiculo: {
              marca: result.marcaModelo.split('/')[0],
              modelo: result.marcaModelo.split('/')[1],
              cor: result.cor,
              anoFabricacao: result.anoFabricacao,
              anoModelo: result.anoModelo,
              capacidadePassageiros: result.capacidade,
              chassi: result.chassi,
              numeroMotor: result.numeroMotor,
              numeroEixos: result.nEixos,
            },
          });
        }
      });
  }

  private getVistoriaDTO(vistoria: NovaVistoria): NovaVistoria {
    localStorage.removeItem('prop');
    if (vistoria.veiculo) {
      // vistoria.veiculo.placa = unmask(vistoria.veiculo.placa);
      vistoria.veiculo.chassi = unmask(vistoria.veiculo.chassi).replace(' ','');
      vistoria.veiculo.renavam = unmask(vistoria.veiculo.renavam);
    }

    vistoria.nome = vistoria.nome?.toLocaleUpperCase();
    vistoria.observacao = vistoria.observacao?.toLocaleUpperCase();
    vistoria.cpfCnpj = unmask(vistoria.cpfCnpj);
    vistoria.intervalms = Number(new Date()) - Number(vistoria.dataHoraInicio);
    vistoria.idSituacaoVistoria = this.situacaoSelecionada;
    vistoria.idSituacaoVistoriaBackOffice = this.situacaoBackOffice;
    vistoria.idEmplacamento;

    vistoria.apontamentos = this.arvoreApontamentos.selected;

    vistoria.imagensVeiculo = this.midias.informacaoFotos;
    vistoria.itensAgregados = this.midias.informacaoAgregados;
    vistoria.videos = this.midias.informacaoVideos;
    vistoria.isMobile = false;

    return vistoria;
  }

  // nextApontamento() {
  //   try {
  //     this.toastr.warning('Verifique se os itens vistoriados estão batendo com os apontamentos.', 'Atenção', {
  //       positionClass: 'toast-top-center'
  //     });
  //     this.next()
  //     console.log('entrou no try')

  //   }
  //   catch (error) {
  //     console.log('entrou no catch')
  //     this.toastr.error('Escolha uma opção com base nos itens vistoriados e apontados.', 'Erro', {
  //       positionClass: 'toast-top-center'
  //     });
  //   }
  // }

  previous() {
    this.step = this.step - 1;
    this.percent = this.percent - 25;
  }

  next() {
    let strProp = localStorage.getItem('prop');
    if (strProp) {
      let proprietario = JSON.parse(strProp);
      if (
        this.form.value.cpfCnpj == undefined ||
        this.form.value.cpfCnpj == '' ||
        proprietario.load
      ) {
        this.form.patchValue({
          cpfCnpj: proprietario.cpfCnpj,
        });
      }

      if (
        this.form.value.nome == undefined ||
        this.form.value.nome == '' ||
        proprietario.load
      ) {
        this.form.patchValue({
          nome: proprietario.nome,
        });
      }
      proprietario.load = false;
      localStorage.setItem('prop', JSON.stringify(proprietario))
    } else {
      console.log('deu erro aqui', strProp);
    }

    this.step = this.step + 1;
    this.percent = this.percent + 25;
  }

  nextResume() {
    this.step = this.step + 1;
    this.percent = this.percent + 25;
    this.stepOk = true;
  }

  voltarProprietario() {
    this.step = this.step - 4;
    this.percent = this.percent - 100;
  }

  voltarVeiculo() {
    this.step = this.step - 5;
    this.percent = this.percent - 125;
  }

  voltarMidia() {
    this.step = this.step - 3;
    this.percent = this.percent - 75;
  }

  // voltarItensVistoriados(){
  //   this.step = this.step -3;
  //   this.percent = this.percent -60;
  // }

  voltarApVistoriador() {
    this.step = this.step - 2;
    this.percent = this.percent - 50;
  }

  voltarApObservacao() {
    this.step = this.step - 1;
    this.percent = this.percent - 25;
  }

  returnHome() {
    const dialogRef = this.dialog.open(ReturnHomeComponent, {
      width: '80%',
      maxWidth: '400px',
    });
  }

  returnAbstract() {
    this.step = 0;
    this.percent = 0;
    this.step = this.step = 6;
    this.percent = this.percent = 125;
  }
}
