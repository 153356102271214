<div class="camera-container">

  <div class="close-container">
    <mat-icon class="icon icon-close" (click)="close()">close</mat-icon>
  </div>

  <div *ngIf="isRecording()" class="counter-container">
    <span class="counter">{{formatTime(secondsLeft)}}</span>
  </div>

  <div class="video-container" [hidden]="isPreviewActive">
    <video class="video-preview" autoplay [muted]="true" #videoPreview></video>

    <div class="container-actions">
      <div class="actions">
        <mat-icon class="icon icon-camera" [style.color]="isRecording() ? 'red' : 'white'"
          (click)="gravar()">circle</mat-icon>
      </div>
    </div>
  </div>

  <div class="preview-container" [style.display]="isPreviewActive ? 'block' : 'none'">

    <div class="image-preview-container">
      <video class="image-preview" #videoGravado controls></video>
    </div>

    <div class="container-actions-preview">
      <div class="actions-preview">
        <button (click)="repeatVideo()">Repetir</button>
        <button (click)="saveVideo()">OK</button>
      </div>
    </div>
  </div>

</div>