<mat-toolbar class="white">
  <h2>Mídias</h2>
</mat-toolbar>

  <!-- Fotos -->
  <app-file-input
    *ngFor="let foto of fotosObrigatorias; let indice = index"
    [hasError]="isSubmitting && !foto.selecionado"
    [isVideo]="false"
    [id]="foto.idItemVistoria"
    [title]="foto.nome"
    [ativoFoto]="foto.ativoFoto"
    (newFile)="novoArquivoObrigatorio($event)"
    (deleteFile)="removerArquivoObrigatorio($event, false)">
  </app-file-input>


  <!-- Foto Agregado -->
  <app-file-input
      *ngFor="let agregado of informacaoAgregados; let indice = index"
      [isVideo]="false" [id]="agregado.id"
      [midia]="getArquivoAgregado(agregado.id)"
      (deleteFile)="removerItemAgregado($event)"
      [title]="agregado.descricao" [subtitle]="agregado.dataHora">
  </app-file-input>

  <!-- Adicionar Item Agregado -->
  <app-custom-input-file
      (newFile)="novoItemAgregado($event)"
      [arquivos]="arquivos"
      [deleteButton]="false">
  </app-custom-input-file>

   <!-- Videos -->
  <app-file-input
    *ngFor="let video of videosObrigatorios; let indice = index"
    [hasError]="isSubmitting && !video.selecionado"
    [isVideo]="true" [id]="video.idItemVistoria"[title]="video.nome"
    (newFile)="novoArquivoObrigatorio($event)"
    (deleteFile)="removerArquivoObrigatorio($event, true)">
  </app-file-input>
