import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Cor } from 'src/models/cor-model';

@Injectable({
  providedIn: 'root'
})
export class CorService {
  private readonly RESOURCE = '/cores';

  constructor(private http: HttpClient) {}

  list(): Observable<Cor[]> {
    return this.http.get<Cor[]>(this.RESOURCE).pipe(take(1));
  }
  
}
