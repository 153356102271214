<h2 mat-dialog-title>Descrição</h2>
<div mat-dialog-content>
  <p>Coloque uma descrição para a imagem que acabou de tirar</p>
  <mat-form-field appearance="outline">
    <input matInput [(ngModel)]="fieldValue" required>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button type="button" mat-raised-button (click)="onNoClick()" color="warn">Cancelar</button>
  <button type="button" mat-raised-button (click)="onOkClick()" color="primary">Ok</button>
</div>
