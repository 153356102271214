import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router,
    private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!req.url.endsWith('auth/token') && !req.url.endsWith('api/auth/logout')) {
      const apiReq = req.clone({ url: `${environment.apiBaseUrl}${req.url}` });
      return next.handle(apiReq).pipe(catchError(error => {
        if(error instanceof HttpErrorResponse) {
          if(error.status === 401) {
            this.toastr.info('Por favor faça o login novamente', 'Sua sessão expirou!', {
              positionClass: 'toast-top-center'
            });
            this.authService.logout();
            this.router.navigate(['/login']);
          }
        }

        return throwError(error);
      }));
    }

    return next.handle(req);
  }
}
